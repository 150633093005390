import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ErrorPage from '../ErrorPage';
import HrNavigation from '../HrNavigation';
import Loading from '../Loading';

export const withHrLayout = (WrappedComponent) => {
  const mapStateToProps = ({ dataSet, departmentHierarchy }) => ({
    dataSet,
    departmentHierarchy,
  });

  return connect(mapStateToProps)(
    class withHrLayout extends Component {
      render() {
        const { dataSet, departmentHierarchy } = this.props;

        if (departmentHierarchy === null) {
          return <Loading />;
        } else {
          if (dataSet.length > 0) {
            return (
              <Fragment>
                <HrNavigation />

                <WrappedComponent {...this.props} />
              </Fragment>
            );
          } else {
            return <ErrorPage statusCode={401} />;
          }
        }
      }
    }
  );
};

import React, { useMemo } from 'react';
import { smallChevronRight } from '../Icons';
import { DepartmentPickerCheckbox } from './DepartmentPickerCheckbox';

const HitAncestor = ({ department, onClick }) => {
  return (
    <span className="search-hit__ancestor">
      <span className="search-hit__ancestor-name" onClick={onClick}>
        {department.name}
      </span>
      {smallChevronRight}
    </span>
  );
};

const HitDepartment = ({ department, onSelect, onClick }) => {
  return (
    <span
      className={
        'search-hit__department search-hit-department' +
        (department.isLeaf ? ' search-hit-department--leaf' : '')
      }
    >
      <DepartmentPickerCheckbox department={department} onSelect={onSelect} />
      <span onClick={onClick}>{department.name}</span>
    </span>
  );
};

const computeAncestors = (department) => {
  const ancestors = [];
  let parent = department.parent || null;
  while (parent !== null) {
    ancestors.unshift(parent);
    parent = parent.parent || null;
  }
  return ancestors;
};

export const DepartmentPickerSearchHit = ({
  department,
  onSelect,
  onNavigation,
}) => {
  const ancestors = useMemo(() => computeAncestors(department), [department]);

  const handleAncestorClick = (ancestor) => {
    onNavigation && onNavigation(ancestor);
  };

  const handleDepartmentClick = (department) => {
    onNavigation && onNavigation(department);
  };

  return (
    <div className="search-hit">
      {ancestors.map((ancestor) => (
        <HitAncestor
          department={ancestor}
          key={ancestor.id}
          onClick={() => handleAncestorClick(ancestor)}
        />
      ))}
      <HitDepartment
        department={department}
        onSelect={onSelect}
        onClick={() => handleDepartmentClick(department)}
      />
    </div>
  );
};

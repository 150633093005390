import React, { useEffect, useState } from 'react';

import { api } from '../api';
import { isUserActive } from '../common/utils/userUtils';
import { features } from '../services/features';
import { bemClasses } from './ui/utils/bem-classes';

const TokenCountdown = () => {
  if (features.enabled('token-countdown')) {
    return <TokenCountdownComponent />;
  }
  return null;
};

const TokenCountdownComponent = () => {
  const calculateTimeLeft = () =>
    Math.round((api.token.expirationTimeInMs - Date.now()) / 1000);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [modalOpensAt, setModalOpensAt] = useState('');
  const [clock, setClock] = useState('');

  useEffect(() => {
    setModalOpensAt(sweTime(Date.now() + api.token.lifespanInMs * 0.75));
  }, [api.token.lifespanInMs]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft <= 0) {
        setTimeLeft(0);
        clearInterval(interval);
      } else {
        setTimeLeft(newTimeLeft);
      }
      setClock(sweTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTimeLeft = (seconds) => {
    if (seconds <= 60) {
      return `${seconds} sec`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min ${remainingSeconds} sec`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} hr ${minutes} min ${remainingSeconds} sec`;
    }
  };

  const sweTime = (timeInMs) =>
    (timeInMs ? new Date(timeInMs) : new Date()).toLocaleTimeString('sv-SE');

  const bem = bemClasses('token-countdown');

  return (
    <div className={bem.block()}>
      <span>Time left in session: {formatTimeLeft(timeLeft)}</span>
      <span>
        Last request time: {sweTime(api.metaData.lastRequestTime.getTime())}
      </span>
      <span>Modal opens at: {modalOpensAt}</span>
      <span>Current time: {clock}</span>
      <span>
        User is active:{' '}
        {isUserActive(api.metaData.lastRequestTime, api.token.lifespanInMs)
          ? '✔️'
          : '❌'}
      </span>
    </div>
  );
};

export default TokenCountdown;

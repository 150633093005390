import { Modifiers } from '../components/DepartmentPicker2/DepartmentPickerThreeStateCheckbox';

const cleanInvalidWhitespaces = (str) => (str || '').replace(/\u00A0/g, ' ');

export class Department {
  constructor(departmentData, departments, parent) {
    /** @type {string} */
    this.id = departmentData.abstract_id;
    /** @type {string} */
    this.name = cleanInvalidWhitespaces(departmentData.name);
    this.isAuthorized = departmentData.authorized;
    /** @type {Department[]} */
    this.departments = (departments[this.id] || []).map(
      (d) => new Department(d, departments, this)
    );
    this.selected = false;
    /** @type {Department} */
    this.parent = parent || null;
  }

  toggleSelected(modifiers) {
    if (this.isLeaf || Modifiers.match(Modifiers.CTRL, modifiers)) {
      this.selected = !this.selected;
    } else if (Modifiers.match(Modifiers.ALT, modifiers)) {
      this.deselectTree();
    } else {
      if (this.isSelected && this._isSubtreeSelected()) {
        this.deselectSubtree();
      } else if (this.isSelected && !this._isSubtreeSelected()) {
        this.selected = false;
      } else {
        this.selectTree();
      }
    }
  }

  select(deselectChildren = false) {
    if (this.isAuthorized) {
      this.selected = true;
    }
    if (deselectChildren) {
      this.deselectSubtree();
    }
  }

  deselect() {
    this.selected = false;
  }

  selectSubtree(deselectCurrent = false) {
    if (deselectCurrent) {
      this.selected = false;
    }
    this.departments.forEach((department) => department.selectTree());
  }

  selectTree() {
    this.select();
    this.departments.forEach((department) => department.selectTree());
  }

  selectTreeIf(predicate) {
    if (predicate(this.id)) {
      this.select();
    }
    this.departments.forEach((department) =>
      department.selectTreeIf(predicate)
    );
  }

  deselectSubtree() {
    this.departments.forEach((department) => department.deselectTree());
  }

  deselectTree() {
    this.selected = false;
    this.departments.forEach((department) => department.deselectTree());
  }

  refreshTree(ids) {
    this.selected = ids.includes(this.id);
    this.departments.forEach((department) => department.refreshTree(ids));
  }

  _isSubtreeSelected() {
    return this.departments.every((department) => department.isTreeSelected);
  }

  get isLeaf() {
    return this.departments.length === 0;
  }

  get isRoot() {
    return this.parent === null;
  }

  get isSelected() {
    return this.selected;
  }

  get isTreeSelected() {
    return (
      this.selected &&
      this.departments.every((department) => department.isTreeSelected)
    );
  }

  get isSubtreeSelected() {
    return this.departments.every((department) => department.isSubtreeSelected);
  }

  get subSelectCount() {
    return this.departments.reduce((sum, department) => {
      let result = sum;
      result += department.subSelectCount;
      if (department.selected) {
        result += 1;
      }
      return result;
    }, 0);
  }

  get selectedDepartmentIds() {
    let result = [];
    if (this.selected) {
      result.push(this.id);
    }
    for (const department of this.departments) {
      result = result.concat(department.selectedDepartmentIds);
    }

    return result;
  }

  get children() {
    const result = {};
    for (const method of Object.getOwnPropertyNames(
      Object.getPrototypeOf(this)
    )) {
      result[method] = (...args) => {
        this.departments.forEach((department) => {
          department[method](...args);
        });
      };
    }
    return result;
  }
}

import React from 'react';
import { isArray } from 'lodash';
import format from 'date-fns/format';
import { cleanHiddenCharacters } from '../../../utils/conditions';

import { createAndDownloadWorkbook } from './excel';
import { RolesAuthorizedFor, hasAccessTo } from '@/common/roles-authorization';
import MaskedPersonLink from '../../authorization/MaskedPersonLink';

export const exportSpreadsheet = (props, t) => {
  const { periodStart, periodEnd, absenceEvents, role } = props;

  const exportFileName = `${t(
    'AbsenceEvents.title.repeatShortTermAbsence'
  )} ${format(periodStart, 'YYYY-MM-DD')} - ${format(periodEnd, 'YYYY-MM-DD')}`;
  const headers = [
    t('common.list.headers.name'),
    cleanHiddenCharacters(t('common.list.headers.numberOfOccasions')),
    t('AbsenceEvents.headers.department'),
  ];

  const capitalize = (str) =>
    str
      .toLowerCase()
      .split(' ')
      .map(
        (namePart) =>
          namePart.substring(0, 1).toUpperCase() + namePart.substring(1)
      )
      .join(' ');

  const personName = (event) => {
    if (hasAccessTo(RolesAuthorizedFor.DataField.Person.Name, role)) {
      if (event.person) {
        const firstName = event.person.firstName || event.person.first_name;
        const lastName = event.person.lastName || event.person.last_name;
        return `${capitalize(firstName + ' ' + lastName)}`;
      } else {
        return t('common.previousEmployee');
      }
    } else {
      return t('common.masked');
    }
  };

  createAndDownloadWorkbook(
    exportFileName,
    t('StandardTable.exportTabName'),
    null,
    headers,
    absenceEvents
      .map((event) => [
        personName(event),
        event.short_term_absence_count,
        event.departments && event.departments.length > 0
          ? event.departments[0].name || ''
          : '',
      ])
      .sort((a, b) => a[0].localeCompare(b[0]))
  );
};

export const asArray = (...items) => {
  return items.filter((item) => item != null);
};

export const reactChildrenText = (item) => {
  if (isArray(item)) {
    return item.map(reactChildrenText).join(' ');
  }
  if (typeof item === 'string') {
    return item;
  }
  if (item.type === MaskedPersonLink) {
    const { first_name = '', last_name = '' } = item.props.person || {};
    return `${first_name} ${last_name}`;
  }
  if (!item.props.children) {
    return '';
  }
  return React.Children.map(item.props.children, (child) => {
    if (typeof child === 'string') {
      return child;
    } else if (child.props && child.props.children) {
      return reactChildrenText(child);
    }
    return '';
  }).join(' ');
};

export const linkedEmployeeName = (absenceEvent, t) => {
  if (!absenceEvent || !absenceEvent.person) {
    return t('common.previousEmployee');
  }
  return <MaskedPersonLink person={absenceEvent.person} />;
};

export const linkedDepartmentName = (absenceEvent) => {
  if (!absenceEvent.departments || !absenceEvent.departments.length) {
    return null;
  }
  return absenceEvent.departments.map((department) => (
    <div key={department.abstract_id}>{department.name}</div>
  ));
};

import {
  ACCOUNT_MANAGER,
  ACTING_MANAGER_ROLE,
  HR_ADMIN_ROLE,
  MANAGER_ROLE,
  PERSONNEL_ADMIN,
  PERSONNEL_ADMINV2,
  REHAB_COORDINATOR_ROLE,
  STATISTICS_SUPPORT_ROLE,
} from '../roles';

/**
 * Represents access to an entire page
 */
export const $Page = Symbol.for('Access_PageAccess');
/**
 * Represents access to an entire object
 */
export const $Object = Symbol.for('Access_ObjectAccess');

export const RolesAuthorizedFor = {
  //Endpoint access
  Query: {
    SearchEmployees: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
    ],
    AbsenceEvents: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    CompanyStatistics: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    DepartmentHierarchy: [
      MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      ACTING_MANAGER_ROLE,
      ACCOUNT_MANAGER,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
    ],
    HrReport: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    HrStatisticsNotifications: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    HrStatistics: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    HrToday: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    Person: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    PersonAbsences: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    RehabPlans: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      REHAB_COORDINATOR_ROLE,
    ],
    RehabPlanDocument: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      REHAB_COORDINATOR_ROLE,
    ],
    Navigation: [
      ACCOUNT_MANAGER,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      REHAB_COORDINATOR_ROLE,
      STATISTICS_SUPPORT_ROLE,
    ],
    HrTodayNotifications: [
      MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      ACTING_MANAGER_ROLE,
      ACCOUNT_MANAGER,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
    ],
    AbsenceHistory: [
      HR_ADMIN_ROLE,
      REHAB_COORDINATOR_ROLE,
      STATISTICS_SUPPORT_ROLE,
      ACTING_MANAGER_ROLE,
      MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
    ReturnThisWeek: [
      HR_ADMIN_ROLE,
      REHAB_COORDINATOR_ROLE,
      STATISTICS_SUPPORT_ROLE,
      ACTING_MANAGER_ROLE,
      MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
      ACCOUNT_MANAGER,
    ],
  },
  Feature: {
    PersonLink: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      PERSONNEL_ADMIN,
      PERSONNEL_ADMINV2,
    ],
    WorkRelatedAbsences: [
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      ACCOUNT_MANAGER,
    ],
    RehabPlans: [
      MANAGER_ROLE,
      HR_ADMIN_ROLE,
      REHAB_COORDINATOR_ROLE,
      ACTING_MANAGER_ROLE,
    ],
  },
  //Page access
  Area: {
    DepartmentPicker: {
      HrViewShortcuts: [
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACTING_MANAGER_ROLE,
        MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
        ACCOUNT_MANAGER,
      ],
    },
    HrToday: {
      RehabChainBarChart: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
      ],
      WorkRelatedAbsences: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
      Notifications: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
      AbsenceHistory: [
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACTING_MANAGER_ROLE,
        MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
        ACCOUNT_MANAGER,
      ],
      ReturnThisWeek: [
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACTING_MANAGER_ROLE,
        MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
        ACCOUNT_MANAGER,
      ],
    },
    Person: {
      $Page: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
      ],
      AbsenceHistoryChart: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
      RehabPlans: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
      ],
      Notifications: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
      HistoryTable: {
        WorkRelatedColumn: [
          MANAGER_ROLE,
          HR_ADMIN_ROLE,
          REHAB_COORDINATOR_ROLE,
          ACTING_MANAGER_ROLE,
          STATISTICS_SUPPORT_ROLE,
          ACCOUNT_MANAGER,
        ],
      },
    },
    Report: {
      ConditionNameColumn: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
      WorkRelatedColumn: [
        MANAGER_ROLE,
        HR_ADMIN_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACTING_MANAGER_ROLE,
        STATISTICS_SUPPORT_ROLE,
        ACCOUNT_MANAGER,
      ],
    },
    Analysis: {
      WorkRelatedAbsences: [
        MANAGER_ROLE,
        ACTING_MANAGER_ROLE,
        HR_ADMIN_ROLE,
        STATISTICS_SUPPORT_ROLE,
        REHAB_COORDINATOR_ROLE,
        ACCOUNT_MANAGER,
      ],
    },
  },
  //Property access
  DataField: {
    Condition: [
      HR_ADMIN_ROLE,
      STATISTICS_SUPPORT_ROLE,
      REHAB_COORDINATOR_ROLE,
      MANAGER_ROLE,
      ACTING_MANAGER_ROLE,
      ACCOUNT_MANAGER,
    ],
    NeedForSupport: [
      ACCOUNT_MANAGER,
      ACTING_MANAGER_ROLE,
      HR_ADMIN_ROLE,
      MANAGER_ROLE,
      REHAB_COORDINATOR_ROLE,
      STATISTICS_SUPPORT_ROLE,
    ],
    Person: {
      $Object: [
        HR_ADMIN_ROLE,
        STATISTICS_SUPPORT_ROLE,
        REHAB_COORDINATOR_ROLE,
        MANAGER_ROLE,
        ACTING_MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
        ACCOUNT_MANAGER,
      ],
      Name: [
        HR_ADMIN_ROLE,
        STATISTICS_SUPPORT_ROLE,
        REHAB_COORDINATOR_ROLE,
        MANAGER_ROLE,
        ACTING_MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
      ],
      EmploymentNumber: [
        HR_ADMIN_ROLE,
        STATISTICS_SUPPORT_ROLE,
        REHAB_COORDINATOR_ROLE,
        MANAGER_ROLE,
        ACTING_MANAGER_ROLE,
        PERSONNEL_ADMIN,
        PERSONNEL_ADMINV2,
      ],
    },
  },
};

export const hasAccessTo = (thing, role) => thing.includes(role);

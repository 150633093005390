import classNames from 'classnames';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import config from '../../../config';
import { RadioGroupContext } from '../../../contexts/radio-group-context';
import { getKey } from '../../../utils';
import { CheckboxButtonIcon, RadioButtonIcon } from '../../Icons';
import { bemClasses } from '../utils/bem-classes';

/**
 * @enum {Symbol}
 */
export const IconPosition = {
  Left: Symbol.for('IconPosition.Left'),
  Right: Symbol.for('IconPosition.Right'),
};

/**
 * @enum {Symbol}
 */
export const IconSizes = {
  Normal: Symbol.for('IconSizes.Medium'),
  Large: Symbol.for('IconSizes.Large'),
};

export const ToolbarActionIcons = {
  ArrowDown: (
    <svg viewBox="0 0 12 14" fill="currentColor">
      <path
        d="M10.875 6.28125C11.0312 6.125 11.0312 5.875 10.875 5.75L10.25 5.125C10.125 4.96875 9.875 4.96875 9.71875 5.125L6.78125 8.15625V0.375C6.78125 0.1875 6.625 0 6.40625 0H5.53125C5.34375 0 5.15625 0.1875 5.15625 0.375V8.15625L2.25 5.125C2.09375 4.96875 1.84375 4.96875 1.71875 5.125L1.09375 5.75C0.9375 5.875 0.9375 6.125 1.09375 6.28125L5.71875 10.9062C5.875 11.0625 6.09375 11.0625 6.25 10.9062L10.875 6.28125ZM11.625 12.375H0.375C0.15625 12.375 0 12.5625 0 12.75V13.625C0 13.8438 0.15625 14 0.375 14H11.625C11.8125 14 12 13.8438 12 13.625V12.75C12 12.5625 11.8125 12.375 11.625 12.375Z"
        fill="#464646"
      />
    </svg>
  ),
  ChevronDown: (
    <svg viewBox="0 0 14 9">
      <path
        d="M13 1.036L7.018 7 1 1"
        stroke="currentColor"
        strokeWidth="2"
        fill="currentColor"
      />
    </svg>
  ),
  Calender: (
    <svg viewBox="0 0 14 17" fill="currentColor">
      <path
        d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H10.375C10.1562 0 10 0.1875 10 0.375V2H4V0.375C4 0.1875 3.8125 0 3.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM1.5 3H12.5C12.75 3 13 3.25 13 3.5V5H1V3.5C1 3.25 1.21875 3 1.5 3ZM12.5 15H1.5C1.21875 15 1 14.7812 1 14.5V6H13V14.5C13 14.7812 12.75 15 12.5 15Z"
        fill="#464646"
      />
    </svg>
  ),
  Gear: (
    <svg viewBox="0 0 300.112 300.112" fill="currentColor">
      <path
        d="M150.057,105.1c-24.789,0-44.955,20.167-44.955,44.955s20.166,44.955,44.955,44.955
        c24.789,0,44.955-20.167,44.955-44.955S174.845,105.1,150.057,105.1z M150.057,178.36c-15.607,0-28.305-12.697-28.305-28.305
        s12.697-28.305,28.305-28.305c15.608,0,28.305,12.697,28.305,28.305S165.663,178.36,150.057,178.36z"
      />
      <path
        d="M297.365,183.342l-25.458-22.983v-20.608l25.457-22.981c2.614-2.361,3.461-6.112,2.112-9.366l-13.605-32.846
        c-1.348-3.253-4.588-5.305-8.115-5.128l-34.252,1.749l-14.571-14.571l1.749-34.251c0.18-3.518-1.874-6.769-5.128-8.116
        L192.707,0.635c-3.253-1.35-7.005-0.501-9.365,2.111l-22.984,25.458h-20.606L116.77,2.746c-2.361-2.613-6.112-3.458-9.365-2.111
        L74.559,14.24c-3.255,1.348-5.308,4.599-5.128,8.116l1.75,34.251L56.609,71.178l-34.252-1.749
        c-3.506-0.188-6.768,1.874-8.115,5.128L0.635,107.403c-1.348,3.255-0.502,7.005,2.112,9.366l25.457,22.981v20.608L2.749,183.341
        c-2.614,2.361-3.461,6.112-2.112,9.366l13.605,32.846c1.348,3.255,4.603,5.321,8.115,5.128l34.252-1.749l14.572,14.571
        l-1.75,34.251c-0.18,3.518,1.874,6.769,5.128,8.116l32.846,13.606c3.255,1.352,7.005,0.502,9.365-2.111l22.984-25.458h20.606
        l22.984,25.458c1.613,1.785,3.873,2.746,6.182,2.746c1.071,0,2.152-0.208,3.183-0.634l32.846-13.606
        c3.255-1.348,5.308-4.599,5.128-8.116l-1.749-34.251l14.571-14.571l34.252,1.749c3.506,0.178,6.768-1.874,8.115-5.128
        l13.605-32.846C300.825,189.453,299.979,185.703,297.365,183.342z M272.737,213.754l-32.079-1.639
        c-2.351-0.127-4.646,0.764-6.311,2.428l-19.804,19.804c-1.666,1.666-2.547,3.958-2.428,6.311l1.638,32.079l-21.99,9.109
        l-21.525-23.843c-1.578-1.747-3.824-2.746-6.179-2.746h-28.006c-2.355,0-4.601,0.998-6.179,2.746l-21.525,23.843l-21.99-9.109
        l1.639-32.079c0.12-2.353-0.763-4.646-2.429-6.311l-19.803-19.804c-1.665-1.665-3.955-2.55-6.311-2.428l-32.079,1.639
        l-9.109-21.99l23.842-21.525c1.748-1.58,2.746-3.824,2.746-6.179v-28.008c0-2.355-0.998-4.601-2.746-6.179l-23.842-21.525
        l9.109-21.99l32.079,1.639c2.354,0.124,4.646-0.763,6.311-2.428l19.803-19.803c1.666-1.666,2.549-3.958,2.429-6.313
        l-1.639-32.079l21.99-9.109l21.525,23.842c1.578,1.747,3.824,2.746,6.179,2.746h28.006c2.355,0,4.601-0.998,6.179-2.746
        l21.525-23.842l21.99,9.109l-1.638,32.079c-0.12,2.353,0.761,4.645,2.428,6.313l19.804,19.803
        c1.666,1.665,3.959,2.564,6.311,2.428l32.079-1.639l9.109,21.99l-23.843,21.525c-1.748,1.58-2.746,3.824-2.746,6.179v28.008
        c0,2.355,0.998,4.601,2.746,6.179l23.843,21.525L272.737,213.754z"
      />
      <path
        d="M150.057,71.357c-43.394,0-78.698,35.305-78.698,78.698c0,43.394,35.304,78.698,78.698,78.698
        c43.394,0,78.698-35.305,78.698-78.698C228.754,106.661,193.45,71.357,150.057,71.357z M150.057,212.103
        c-34.214,0-62.048-27.834-62.048-62.048c0-34.214,27.834-62.048,62.048-62.048c34.214,0,62.048,27.834,62.048,62.048
        C212.105,184.269,184.269,212.103,150.057,212.103z"
      />
    </svg>
  ),
  Spinner: (
    <svg viewBox="0 0 20 20">
      <g className="spinning">
        <circle cx="10" cy="5" r="3" fill="currentColor" />
      </g>
    </svg>
  ),
  Funnel: (
    <svg viewBox="0 0 20 20">
      <path d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z"></path>
    </svg>
  ),
  Male: (
    <svg viewBox="0 0 512 512" fill="currentColor">
      <path
        d="M480.093,164.996c17.621,0,31.907-14.286,31.907-31.907V32.36v-0.013c0-1.064-0.057-2.114-0.157-3.15
	c-0.045-0.457-0.134-0.913-0.2-1.37c-0.081-0.572-0.149-1.151-0.262-1.714c-0.104-0.53-0.253-1.053-0.385-1.578
	c-0.123-0.483-0.228-0.972-0.372-1.449c-0.157-0.517-0.353-1.023-0.538-1.536c-0.168-0.47-0.323-0.949-0.515-1.408
	c-0.2-0.481-0.436-0.949-0.659-1.421c-0.223-0.47-0.432-0.949-0.676-1.406c-0.247-0.459-0.532-0.904-0.802-1.355
	c-0.266-0.44-0.515-0.891-0.802-1.319c-0.345-0.517-0.734-1.013-1.112-1.512c-0.249-0.33-0.479-0.674-0.74-0.991
	c-0.666-0.808-1.372-1.593-2.125-2.348c-0.755-0.755-1.544-1.461-2.355-2.127c-0.304-0.249-0.63-0.468-0.942-0.706
	c-0.515-0.394-1.03-0.793-1.566-1.151c-0.402-0.27-0.832-0.508-1.251-0.759c-0.474-0.285-0.942-0.585-1.427-0.842
	c-0.43-0.23-0.879-0.428-1.323-0.638c-0.498-0.238-0.998-0.489-1.508-0.7c-0.423-0.177-0.864-0.317-1.295-0.476
	c-0.549-0.198-1.095-0.408-1.651-0.576c-0.421-0.128-0.855-0.221-1.287-0.33c-0.576-0.149-1.155-0.311-1.74-0.425
	c-0.476-0.094-0.964-0.147-1.446-0.221c-0.547-0.083-1.091-0.183-1.64-0.236c-0.8-0.079-1.615-0.104-2.431-0.123
	c-0.234-0.004-0.462-0.036-0.698-0.036H379.354c-17.621,0-31.907,14.286-31.907,31.907s14.286,31.907,31.907,31.907h23.713
	l-48.073,48.071C317.888,84.403,271.776,67.83,221.865,67.83C99.529,67.83,0,167.359,0,289.695S99.529,511.56,221.867,511.56
	c122.336,0,221.863-99.529,221.863-221.865c0-49.458-16.273-95.185-43.738-132.119l48.195-48.197v23.709
	C448.186,150.712,462.472,164.996,480.093,164.996z M221.867,447.746c-87.151,0-158.053-70.9-158.053-158.051
	s70.902-158.053,158.053-158.053c87.149,0,158.048,70.902,158.048,158.053S309.016,447.746,221.867,447.746z"
      />
      <path
        d="M221.867,511.56c122.336,0,221.863-99.529,221.863-221.865c0-0.6-0.032-1.191-0.036-1.789h-63.823
	c0.006,0.598,0.045,1.189,0.045,1.789c0,87.149-70.9,158.051-158.048,158.051c-87.151,0-158.053-70.9-158.053-158.051
	c0-0.6,0.038-1.191,0.045-1.789H0.023C0.017,288.504,0,289.097,0,289.695C0,412.033,99.529,511.56,221.867,511.56z"
      />
    </svg>
  ),
  Female: (
    <svg viewBox="0 0 512 512" fill="currentColor">
      <path
        d="M439.825,183.827C439.825,82.464,357.36,0,255.999,0
	S72.175,82.464,72.175,183.827c0,92.385,68.509,169.058,157.389,181.917v34.362h-32.582c-14.6,0-26.437,11.837-26.437,26.437
	s11.837,26.437,26.437,26.437h32.582l0.002,32.586c0,14.6,11.837,26.435,26.437,26.435c14.6,0,26.437-11.837,26.437-26.437
	l-0.002-32.584h32.582c14.6,0,26.437-11.837,26.437-26.437s-11.837-26.437-26.437-26.437h-32.582v-34.362
	C371.315,352.887,439.825,276.212,439.825,183.827z M255.999,314.78c-72.207,0-130.953-58.746-130.953-130.953
	c0.002-72.209,58.747-130.954,130.953-130.954s130.953,58.746,130.953,130.954C386.952,256.034,328.206,314.78,255.999,314.78z"
      />
      <g>
        <path
          d="M439.825,183.827C439.825,82.464,357.36,0,255.999,0
		S72.175,82.464,72.175,183.827c0,92.385,68.509,169.058,157.389,181.917v34.362h-32.582c-14.6,0-26.437,11.837-26.437,26.437
		s11.837,26.437,26.437,26.437h32.582l0.002,32.586c0,14.6,11.837,26.435,26.437,26.435c14.6,0,26.437-11.837,26.437-26.437
		l-0.002-32.584h32.582c14.6,0,26.437-11.837,26.437-26.437s-11.837-26.437-26.437-26.437h-32.582v-34.362
		C371.315,352.887,439.825,276.212,439.825,183.827z M255.999,314.78c-72.207,0-130.953-58.746-130.953-130.953
		c0.002-72.209,58.747-130.954,130.953-130.954s130.953,58.746,130.953,130.954C386.952,256.034,328.206,314.78,255.999,314.78z"
        />
      </g>
      <path
        d="M255.999,0C154.64,0,72.175,82.464,72.175,183.827c0,0.878,0.018,1.75,0.03,2.624h52.91
	c-0.018-0.876-0.067-1.745-0.067-2.624c0-72.209,58.746-130.954,130.951-130.954s130.953,58.746,130.953,130.954
	c0,0.879-0.049,1.748-0.067,2.624h52.91c0.012-0.874,0.03-1.748,0.03-2.624C439.825,82.464,357.36,0,255.999,0z"
      />
    </svg>
  ),
  BothGenders: (
    <svg viewBox="0 0 512 512" className="both-genders">
      <path
        className="both-genders__female-lower-path"
        d="M323.651,217.042c0-43.926-17.6-83.814-46.109-113.001c-17.16,3.463-32.946,10.747-46.44,20.924
	c27.918,21.059,46.002,54.492,46.002,92.078c0,37.584-18.084,71.018-46.002,92.076c-13.494,10.178-29.282,17.461-46.441,20.924
	c-7.384,1.489-15.02,2.281-22.837,2.281c-63.566,0-115.281-51.715-115.281-115.281c0-63.567,51.715-115.282,115.281-115.282
	c7.817,0,15.453,0.79,22.837,2.281c13.324-13.641,29.029-24.944,46.441-33.227c-21.014-9.995-44.504-15.599-69.277-15.599
	C72.595,55.215,0,127.811,0,217.042c0,81.327,60.309,148.826,138.55,160.146l0.002,30.25h-28.686
	c-12.853,0-23.273,10.42-23.273,23.273s10.42,23.273,23.273,23.273h28.684v28.684c0,12.853,10.42,23.273,23.273,23.271
	c12.853,0,23.273-10.42,23.273-23.273v-28.683h28.684c12.853,0,23.273-10.42,23.273-23.273s-10.42-23.273-23.273-23.273h-28.684
	l-0.002-30.25c16.235-2.349,31.696-7.112,46.007-13.92c17.411-8.282,33.116-19.586,46.44-33.227
	C306.052,300.855,323.651,260.969,323.651,217.042z"
      />
      <path
        className="both-genders__male-lower-path"
        d="M511.736,26.023c-0.057-0.413-0.105-0.827-0.188-1.232c-0.078-0.393-0.186-0.78-0.285-1.17
	c-0.088-0.346-0.163-0.697-0.267-1.035c-0.115-0.385-0.262-0.762-0.4-1.142c-0.121-0.335-0.23-0.676-0.366-1.005
	c-0.149-0.358-0.326-0.709-0.493-1.063c-0.157-0.334-0.306-0.675-0.481-0.999c-0.186-0.348-0.4-0.683-0.605-1.022
	c-0.188-0.31-0.363-0.627-0.563-0.928c-0.264-0.394-0.557-0.773-0.849-1.153c-0.169-0.223-0.326-0.458-0.503-0.675
	c-0.486-0.591-1.001-1.165-1.553-1.718c-0.552-0.552-1.13-1.071-1.722-1.556c-0.197-0.161-0.41-0.303-0.611-0.458
	c-0.402-0.309-0.802-0.619-1.219-0.897c-0.275-0.183-0.563-0.343-0.846-0.515c-0.369-0.223-0.734-0.455-1.111-0.655
	c-0.292-0.157-0.597-0.289-0.895-0.431c-0.388-0.186-0.774-0.38-1.17-0.543c-0.285-0.118-0.582-0.213-0.874-0.32
	c-0.424-0.155-0.849-0.318-1.278-0.448c-0.278-0.084-0.563-0.143-0.844-0.217c-0.453-0.118-0.908-0.244-1.365-0.334
	c-0.298-0.059-0.604-0.092-0.906-0.138c-0.447-0.07-0.894-0.15-1.345-0.194c-0.451-0.043-0.914-0.051-1.373-0.07
	c-0.299-0.011-0.594-0.045-0.897-0.045h-73.478c-12.853,0-23.273,10.42-23.273,23.273s10.42,23.273,23.273,23.273h17.296
	l-35.061,35.064c-27.066-20.368-60.698-32.456-97.105-32.456c-24.776,0-48.263,5.604-69.275,15.599
	c-17.413,8.282-33.117,19.586-46.441,33.227c-28.508,29.189-46.109,69.075-46.109,113.002s17.602,83.813,46.109,113
	c17.16-3.463,32.946-10.747,46.441-20.924c-27.918-21.059-46.004-54.492-46.004-92.076c0-37.585,18.086-71.019,46.004-92.078
	c13.492-10.178,29.28-17.461,46.44-20.924c7.385-1.489,15.019-2.281,22.837-2.281c63.566,0,115.281,51.715,115.281,115.282
	c0,63.566-51.715,115.281-115.281,115.281c-7.818,0-15.452-0.79-22.837-2.281c-13.324,13.641-29.029,24.944-46.44,33.227
	c21.012,9.995,44.501,15.599,69.275,15.599c89.231,0,161.826-72.595,161.826-161.826c0-36.076-11.869-69.427-31.901-96.366
	l35.151-35.153v17.293c0,12.853,10.42,23.273,23.273,23.273S512,115.669,512,102.817v-73.47v-0.009c0-0.776-0.042-1.541-0.115-2.296
	C511.85,26.699,511.784,26.361,511.736,26.023z"
      />
      <g>
        <path
          className="both-genders__female-upper-left-path"
          d="M161.826,101.76c7.817,0,15.453,0.79,22.837,2.281c13.324-13.641,29.029-24.944,46.442-33.227
		c-21.014-9.995-44.504-15.599-69.277-15.599c-89.142,0-161.679,72.453-161.823,161.562h46.549
		C46.696,153.332,98.349,101.76,161.826,101.76z"
        />
        <path
          className="both-genders__female-upper-right-path"
          d="M231.103,124.964c27.853,21.009,45.914,54.336,45.999,91.812h46.545
		c-0.071-43.823-17.655-83.608-46.106-112.736C260.383,107.504,244.595,114.788,231.103,124.964z"
        />
      </g>
      <path
        className="both-genders__male-upper-path"
        d="M465.455,85.523v17.293c0,12.853,10.42,23.273,23.273,23.273S512,115.669,512,102.817v-73.47v-0.009
	c0-0.776-0.042-1.541-0.115-2.296c-0.034-0.34-0.099-0.678-0.149-1.016c-0.057-0.413-0.105-0.827-0.188-1.232
	c-0.078-0.393-0.186-0.78-0.285-1.17c-0.088-0.346-0.163-0.697-0.267-1.035c-0.115-0.385-0.262-0.762-0.4-1.142
	c-0.121-0.335-0.23-0.676-0.366-1.005c-0.149-0.358-0.326-0.709-0.493-1.063c-0.158-0.334-0.306-0.675-0.481-0.999
	c-0.186-0.348-0.4-0.683-0.605-1.022c-0.188-0.31-0.363-0.627-0.563-0.928c-0.264-0.394-0.557-0.773-0.849-1.153
	c-0.169-0.223-0.326-0.458-0.503-0.675c-0.486-0.591-1.001-1.165-1.553-1.718c-0.552-0.552-1.13-1.071-1.722-1.556
	c-0.197-0.161-0.41-0.303-0.611-0.458c-0.402-0.309-0.802-0.619-1.219-0.897c-0.275-0.183-0.563-0.343-0.846-0.515
	c-0.369-0.223-0.734-0.455-1.111-0.655c-0.292-0.157-0.597-0.289-0.895-0.431c-0.388-0.186-0.774-0.38-1.17-0.543
	c-0.285-0.118-0.582-0.213-0.874-0.32c-0.424-0.155-0.849-0.318-1.278-0.448c-0.278-0.084-0.563-0.143-0.844-0.217
	c-0.453-0.118-0.908-0.244-1.365-0.334c-0.298-0.059-0.604-0.092-0.906-0.138c-0.447-0.07-0.894-0.15-1.345-0.194
	c-0.451-0.043-0.914-0.051-1.373-0.068c-0.299-0.011-0.594-0.045-0.897-0.045h-73.478c-12.853,0-23.273,10.42-23.273,23.273
	s10.42,23.273,23.273,23.273h17.296l-35.061,35.061c-27.066-20.367-60.698-32.455-97.105-32.455
	c-24.776,0-48.263,5.604-69.275,15.599c-17.413,8.282-33.117,19.586-46.441,33.227c-28.508,29.189-46.109,69.075-46.109,113.002
	h46.545c0-37.584,18.086-71.019,46.004-92.076c13.492-10.178,29.28-17.461,46.44-20.924c7.385-1.489,15.019-2.281,22.837-2.281
	c63.566,0,115.281,51.715,115.281,115.281h46.545c0-36.074-11.869-69.427-31.902-96.366L465.455,85.523z"
      />
    </svg>
  ),
  EmploymentTypes: function EmploymentTypes({ count }) {
    return (
      <svg
        fill="currentColor"
        viewBox="0 0 32 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="8" r="6" />
        <path
          d="M16 14
    m0 2.215c-6.703 0-11 3.699-11 5.5
    v3.363
    h22
    v-3.363
    c0-2.178-4.068-5.5-11-5.5
    z"
        />
        <circle
          cx="16"
          cy="34"
          r="14"
          fill="white"
          stroke="currentColor"
          strokeWidth={2}
        />
        <text
          x="16"
          y="34"
          textAnchor="middle"
          alignmentBaseline="central"
          fill="currentColor"
          fontSize="18"
          fontFamily="arial"
        >
          {count}
        </text>
      </svg>
    );
  },
};

/**
 * @typedef ToolbarActionButtonProps
 * @property {string|undefined} [text]
 * @property {string|undefined} [title]
 * @property {JSX.Element?} [icon]
 * @property {IconPosition?} [iconPosition]
 * @property {IconSizes?} [iconSize]
 * @property {React.ReactNode} [children]
 * @property {string} [className]
 * @property {React.MouseEventHandler} [onClick]
 */

/**
 *
 * @param {ToolbarActionButtonProps} param0
 * @returns
 */
const ToolbarActionButton = ({
  text = undefined,
  icon = null,
  title = undefined,
  iconPosition = IconPosition.Right,
  iconSize = IconSizes.Normal,
  onClick = () => { },
  className = '',
  ...otherProps
}) => {
  const buttonClasses = classNames(
    'block-header-actions__action toolbar-action',
    className,
    {
      'toolbar-action--icon-left': iconPosition === IconPosition.Left,
      'toolbar-action--with-icon': !!icon,
      'toolbar-action--with-text': !!text,
    },
    {
      'toolbar-action--icon-large': iconSize === IconSizes.Large,
    }
  );
  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      {...otherProps}
      title={title}
    >
      {text && <span className="toolbar-action__text">{text}</span>}
      {icon && <span className="toolbar-action__icon">{icon}</span>}
    </button>
  );
};

export const ToolbarActionView = ({ ...otherProps }) => {
  return (
    <div className="block-header-actions__action-wrapper">
      <ToolbarActionButton {...otherProps} />
    </div>
  );
};

const ToolbarActionFormLoading = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
};
export const ToolbarActionForm = ({ url, values, ...otherProps }) => {
  const [loading, dispatchLoading] = useReducer(
    (state, action) => {
      /* eslint-disable no-case-declarations */
      switch (action) {
        case ToolbarActionFormLoading.START_LOADING:
          if (state.isLoading || state._timeout !== null) {
            return;
          }

          const timeoutId = setTimeout(() => {
            dispatchLoading(ToolbarActionFormLoading.STOP_LOADING);
          }, 1500);

          return {
            isLoading: true,
            _timeout: timeoutId,
          };
        case ToolbarActionFormLoading.STOP_LOADING:
          clearTimeout(state._timeout);
          return {
            isLoading: false,
            _timeout: null,
          };
        default:
          throw new Error(`Unknown action: ${action}`);
      }
      /* eslint-enable */
    },
    { isLoading: false, _timeout: null }
  );

  const setTokenCookie = () => {
    const token = getKey(config.localStorage.tokenKey);
    const role = getKey(config.localStorage.role);
    window.document.cookie = `token=${token};max-age=1;path=/`;
    window.document.cookie = `selected-role=${role};max-age=1;path=/`;

    dispatchLoading(ToolbarActionFormLoading.START_LOADING);
  };

  return (
    <form
      className="block-header-actions__action-wrapper"
      onSubmit={setTokenCookie}
      action={url}
      method="POST"
    >
      {values &&
        values.map(({ name, value }) => (
          <input type="hidden" name={name} value={value} key={name} />
        ))}
      <ToolbarActionButton
        {...otherProps}
        icon={loading.isLoading ? ToolbarActionIcons.Spinner : otherProps.icon}
        iconSize={
          loading.isLoading
            ? IconSizes.Large
            : otherProps.iconSize || IconSizes.Normal
        }
        disabled={loading.isLoading}
      />
    </form>
  );
};

const DropdownMenu = ({ data, isOpen, onClick }) => {
  const bem = bemClasses('toolbar-dropdown');

  return (
    <div
      className={classNames(bem.block(), isOpen && bem.modifier('open'))}
      hidden={!isOpen}
    >
      {data.map((entry) => (
        <span
          key={entry.key.toString()}
          className={bem.element('value')}
          title={entry.label}
          onClick={() => onClick(entry.key)}
        >
          {entry.label}
        </span>
      ))}
    </div>
  );
};

/**
 * @typedef PopoutProps
 * @property {React.ReactNode} children
 * @property {boolean} isOpen
 */

/**
 *
 * @param {PopoutProps} param0
 */
const Popout = ({ children = null, isOpen }) => {
  const bem = bemClasses('toolbar-dropdown');

  return (
    <div
      className={classNames(
        bem.block(),
        isOpen && bem.modifier('open'),
        bem.modifier('fluid-width')
      )}
      hidden={!isOpen}
    >
      {children}
    </div>
  );
};

export const ToolbarDropdownActionView = ({
  icon = null,
  iconPosition = IconPosition.Right,
  onChange = () => { },
  data,
  currentKey,
  className = '',
  ...otherProps
}) => {
  const bem = bemClasses('toolbar-dropdown-action');
  const [showDropdown, setShowDropdown] = useState(false);
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const blockRef = useRef(null);

  const blockClasses = classNames(
    bem.block(),
    'block-header-actions__action-wrapper',
    className,
    {
      [bem.modifier('open')]: showDropdown,
    }
  );

  const handleActionClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleValueClick = (newKey) => {
    setShowDropdown(false);
    onChange(newKey);
  };

  useEffect(() => {
    if (showDropdown) {
      const handleOutsideClick = (event) => {
        if (blockRef.current) {
          if (!blockRef.current.contains(event.target)) {
            setShowDropdown(false);
          }
        }
      };

      document.body.addEventListener('click', handleOutsideClick);
      return () => {
        document.body.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [showDropdown]);

  const actionLabel = useMemo(() => {
    if (currentKey) {
      for (const entry of data) {
        if (entry.key === currentKey) {
          return entry.label;
        }
      }
    }

    return '';
  }, [(currentKey || '').toString(), data]);

  return (
    <div ref={blockRef} className={blockClasses} {...otherProps}>
      <ToolbarActionButton
        text={actionLabel}
        icon={icon}
        iconPosition={iconPosition}
        onClick={handleActionClick}
      />
      <DropdownMenu
        data={data}
        isOpen={showDropdown}
        onClick={handleValueClick}
      />
    </div>
  );
};

/**
 * @typedef ToolbarPopoutActionViewProps
 * @property {JSX.Element?} [icon]
 * @property {IconPosition?} [iconPosition]
 * @property {React.ReactNode} [children]
 * @property {string} [className]
 * @property {string|undefined} [buttonTitle]
 * @property {string|undefined} [buttonClassName]
 */

/**
 *
 * @param {ToolbarPopoutActionViewProps & { [key: string]: any }} param0
 */
export const ToolbarPopoutActionView = ({
  icon = null,
  iconPosition = IconPosition.Right,
  children = null,
  className = '',
  buttonClassName = undefined,
  text = undefined,
  buttonTitle = undefined,
  onDropdownHide = () => { },
  ...otherProps
}) => {
  const bem = bemClasses('toolbar-popout-action');
  const [showDropdown, setShowDropdown] = useState(false);
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const blockRef = useRef(null);

  const blockClasses = classNames(
    bem.block(),
    'block-header-actions__action-wrapper',
    className,
    {
      [bem.modifier('open')]: showDropdown,
    }
  );

  const handleActionClick = () => {
    if (showDropdown) {
      onDropdownHide();
    }
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (showDropdown) {
      const handleOutsideClick = (event) => {
        if (blockRef.current) {
          if (!blockRef.current.contains(event.target)) {
            setShowDropdown(false);
            onDropdownHide();
          }
        }
      };

      document.body.addEventListener('click', handleOutsideClick);
      return () => {
        document.body.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [showDropdown]);

  return (
    <div ref={blockRef} className={blockClasses} {...otherProps}>
      <ToolbarActionButton
        icon={icon}
        iconPosition={iconPosition}
        iconSize={IconSizes.Large}
        onClick={handleActionClick}
        text={text}
        className={buttonClassName}
        title={buttonTitle}
      />
      <Popout isOpen={showDropdown}>{children}</Popout>
    </div>
  );
};

const checkBoxClasses = bemClasses('toolbar-checkbox');
export const ToolbarCheckbox = ({ value, text, onChange }) => {
  return (
    <label className={checkBoxClasses.block()}>
      <input
        type="checkbox"
        className={checkBoxClasses.element('input')}
        defaultChecked={value}
        onClick={onChange}
      />
      <CheckboxButtonIcon className={checkBoxClasses.element('visual-input')} />
      <span className={checkBoxClasses.element('text')}>{text}</span>
    </label>
  );
};

export const ToolbarRadioGroup = ({ children, currentValue, onChange }) => {
  const generatedName = useMemo(
    () => `radio-group-${Math.random().toString(36).substring(2, 9)}`,
    []
  );

  const handleOnChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <RadioGroupContext.Provider
      value={{
        currentValue,
        groupName: generatedName,
        onChange: handleOnChange,
      }}
    >
      {children}
    </RadioGroupContext.Provider>
  );
};

export const ToolbarRadio = ({ optionValue, text }) => {
  const context = useContext(RadioGroupContext);
  if (context === undefined) {
    throw new Error('A ToolbarRadio must be wrapped in a ToolbarRadioGroup');
  }

  const handleClick = () => {
    context.onChange(optionValue);
  };

  return (
    <label className="toolbar-radio">
      <input
        type="radio"
        className="toolbar-radio__input"
        name={context.groupName}
        checked={context.currentValue === optionValue}
        onChange={handleClick}
      />
      <RadioButtonIcon className="toolbar-radio__visual-input" />
      <span className="toolbar-radio__text">{text}</span>
    </label>
  );
};

import dateFormat from 'date-fns/format';
import enLocale from 'date-fns/locale/en';
import svLocale from 'date-fns/locale/sv';
import i18next, { FormatFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from '../locales/en';
import { sv } from '../locales/sv';
import { isDevelopmentEnvironment } from './app';
import { capitalize, verbalizeNumber } from './text-transforms';

export type Lang = 'en' | 'sv';
export const isLang = (value: string): value is Lang => {
  return value === 'en' || value === 'sv';
};

const convertToLang = (input?: string, defaultValue: Lang = 'sv'): Lang => {
  if (input && isLang(input)) {
    return input;
  }
  return defaultValue;
};

const trim = (value: string) => value.trim();
const nonEmpty = (value: string) => value !== '';

const applyFormatterFactory = (lng: Lang) => (value: any, format: string) => {
  const locale = lng === 'en' ? enLocale : svLocale;
  if (format === 'day-month-year') {
    return dateFormat(value, 'DD MMM YYYY', { locale });
  }
  if (format === 'iso-date') {
    return dateFormat(value, 'YYYY-MM-DD', { locale });
  }
  if (format === 'iso-date-time') {
    return dateFormat(value, 'YYYY-MM-DD HH:mm', { locale });
  }
  if (format === 'month-year') {
    return dateFormat(value, 'MMM YYYY', { locale });
  }
  if (format === 'month') {
    return dateFormat(value, 'MMMM', { locale });
  }
  if (format === 'capitalize') {
    if (typeof value === 'string') {
      return capitalize(value);
    }
  }
  if (format === 'verbalize') {
    if (Number.isInteger(value)) {
      return verbalizeNumber(value, lng);
    }
  }
  return value;
};

const formatters: FormatFunction = (
  value: any,
  format?: string,
  lng?: string
): string => {
  const formatterList = (format || '').split(',').map(trim).filter(nonEmpty);

  if (formatterList.length == 0) {
    console.warn('Got unknown formatters', format);

    return value;
  }

  const lang = convertToLang(lng);
  return formatterList.reduce(applyFormatterFactory(lang), value);
};

i18next.use(initReactI18next).init({
  lng: 'sv',
  fallbackLng: ['sv', 'en'],
  whitelist: ['sv', 'en'],
  resources: {
    sv: {
      translation: sv,
    },
    en: {
      translation: en,
    },
  },
  saveMissing: true,
  missingKeyHandler(lng, ns, key) {
    if (isDevelopmentEnvironment) {
      console.error('missing translation key:', lng[0], key);
    }
    return key;
  },
  interpolation: {
    format: formatters,
  },
});

export default i18next;

import {
  abstractIdsToDepartmentId,
  ApiService,
  DepartmentRequest,
} from './api-service';
import { AbsenceType, AbstractId, BasicPerson, Condition } from './types';
import { RehabPlanDocumentResponse, RehabPlanResponse } from './rehabplan';
import {
  CreateRehabPlanDocumentFileRequest,
  CreateRehabPlanDocumentRequest,
  CreateRehabPlanWithDocumentFileRequest,
  CreateRehabPlanWithDocumentRequest,
  DeleteRehabPlanDocumentRequest,
  DeleteRehabPlanRequest,
  RehabPlanDocumentByDocumentIdRequest,
  RehabPlanDocumentUpdateRequest,
  RehabPlansByPersonIdRequest,
  ToggleRehabPlanArchiveRequest,
} from './rehabplan';
import { CachedHttp, departmentRequestRoute } from './http/cachedHttp';
import { Http } from './http';

export type ReturnOnDate = {
  date: string;
  persons: BasicPerson[];
};

export type PersonRequest = {
  personId: string;
};

export type DepartmentFeatures = {
  rehabplaneraren: boolean;
  foretagshalsan: boolean;
};

export enum PersonState {
  healthy = 0,
  absent = 1,
}

export type PersonConditionCount = {
  conditionId: string;
  count: number;
};

export type PersonCurrentCondition = {
  conditionId: string;
  percentage: number;
  type: AbsenceType;
};

export type AbsenceCountInMonth = {
  month: string;
  count: number;
};

export type PersonInfo = {
  person: BasicPerson;
  departmentName: string;
  employedFrom: Date;
  departmentFeatures: DepartmentFeatures;
  personState: PersonState;
  currentConditions: PersonCurrentCondition[];
  numberOfAbsences: number;
  numberOfAbsenceDays: number;
  conditionCount: PersonConditionCount[] | null;
  absenceCountPerMonths: AbsenceCountInMonth[];
  absenceCountPerMonthsCompare: AbsenceCountInMonth[];
};

export type ReturnThisWeekReponse = {
  returnThisWeek: ReturnOnDate[];
  estimatedTodaysReturns: BasicPerson[];
};

export type ConditionCount = Condition & {
  count: number;
};

export type ConditionsSummaryResponse = {
  activeEmployeeCount: number;
  currentAbsentEmployeeCount: number;
  newTodayAbsentEmployeeCount: number;
  workRelatedAbsenceCount: number;
  conditions: ConditionCount[];
};

export type PersonAbsenceEntry = {
  id: string;
  fromDate: Date;
  toDate: Date | null;
  calendarDays: number;
  type: AbsenceType;
  conditionId: string | null;
  status: number;
  chained: boolean;
  workRelated: boolean | null;
};

export type PersonCountPerAbsenceInterval = {
  range: number;
  personCount: number;
};

export type EmployeesPerIntervalResponse = {
  personCountPerAbsenceInterval: PersonCountPerAbsenceInterval[];
  personCountPerAbsenceIntervalWithRehab: PersonCountPerAbsenceInterval[];
};

export type EmploymentType = string;

const employmentTypesRequestRoute = departmentRequestRoute(
  '/person/employmentTypes'
);

export class Person extends ApiService {
  private _cachedHttp: CachedHttp;

  constructor(http: Http) {
    super(http);
    this._cachedHttp = new CachedHttp(http, [employmentTypesRequestRoute]);
  }

  public returnThisWeek = async (
    list: AbstractId[]
  ): Promise<ReturnThisWeekReponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);
    const returnThisWeek = await this._http.post<
      DepartmentRequest,
      ReturnThisWeekReponse
    >('/person/returnThisWeek', { departmentIds });

    return returnThisWeek;
  };

  public conditionsSummary = async (
    list: AbstractId[]
  ): Promise<ConditionsSummaryResponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);
    return await this._http.post<DepartmentRequest, ConditionsSummaryResponse>(
      '/person/conditionsSummary',
      { departmentIds }
    );
  };

  public getRehabPlansByPersonId = async (
    personId: string
  ): Promise<RehabPlanResponse> => {
    const request = { personId: personId } as RehabPlansByPersonIdRequest;
    const rehabPlans = await this._http.post<
      RehabPlansByPersonIdRequest,
      RehabPlanResponse
    >('/rehabplan/personid', request);

    return rehabPlans;
  };

  public getRehabPlanDocumentByDocumentId = async (
    documentId: string
  ): Promise<RehabPlanDocumentResponse> => {
    const request = {
      documentId: documentId,
    } as RehabPlanDocumentByDocumentIdRequest;
    const document = await this._http.post<
      RehabPlanDocumentByDocumentIdRequest,
      RehabPlanDocumentResponse
    >('/rehabplan/document/get', request);

    return document;
  };

  public createRehabPlanWithDocument = async (
    personId: string,
    documentType: string
  ): Promise<void> => {
    const request = {
      documentType: documentType,
      personId: personId,
    } as CreateRehabPlanWithDocumentRequest;
    await this._http.post<CreateRehabPlanWithDocumentRequest, void>(
      '/rehabplan',
      request
    );
    return;
  };

  public createRehabPlanWithDocumentFile = async (
    personId: string,
    rehabPlanDocumentFile: File
  ): Promise<void> => {
    const request = {
      rehabPlanDocumentFile,
    } as CreateRehabPlanWithDocumentFileRequest;
    await this._http.file<CreateRehabPlanWithDocumentFileRequest>(
      `/rehabplan/${personId}/withfile/create`,
      request
    );
    return;
  };

  public deleteRehabPlan = async (rehabPlanId: string): Promise<void> => {
    const request = { rehabPlanId: rehabPlanId } as DeleteRehabPlanRequest;
    await this._http.delete<DeleteRehabPlanRequest, void>(
      '/rehabplan',
      request
    );
    return;
  };

  public updateRehabPlanDocument = async (
    documentId: string,
    content: string
  ): Promise<void> => {
    const request = {
      documentId: documentId,
      content: content,
    } as RehabPlanDocumentUpdateRequest;
    await this._http.put<RehabPlanDocumentUpdateRequest, void>(
      '/rehabplan',
      request
    );
    return;
  };

  public toggleArchiveRehabPlan = async (
    rehabPlanId: string
  ): Promise<void> => {
    const request = {
      rehabPlanId: rehabPlanId,
    } as ToggleRehabPlanArchiveRequest;
    await this._http.put<ToggleRehabPlanArchiveRequest, void>(
      '/rehabplan/toggle-archive',
      request
    );
    return;
  };

  public createRehabPlanDocument = async (
    rehabPlanId: string,
    type: string
  ): Promise<void> => {
    const request = {
      rehabPlanId: rehabPlanId,
      type: type,
    } as CreateRehabPlanDocumentRequest;
    await this._http.post<CreateRehabPlanDocumentRequest, void>(
      '/rehabplan/document/create',
      request
    );
    return;
  };

  public createRehabPlanDocumentFile = async (
    file: File,
    rehabPlanId: string
  ): Promise<void> => {
    const request = {
      rehabPlanDocumentFile: file,
    } as CreateRehabPlanDocumentFileRequest;
    await this._http.file<CreateRehabPlanDocumentFileRequest>(
      `/rehabplan/${rehabPlanId}/document/createfile`,
      request
    );
    return;
  };

  public deleteRehabPlanDocument = async (
    rehabPlanDocumentId: string
  ): Promise<void> => {
    const request = {
      documentId: rehabPlanDocumentId,
    } as DeleteRehabPlanDocumentRequest;
    await this._http.delete<DeleteRehabPlanDocumentRequest, void>(
      '/rehabplan/document',
      request
    );
    return;
  };

  public info = async (personId: string): Promise<PersonInfo> => {
    const request = { personId };
    return await this._http.post<PersonRequest, PersonInfo>('/person', request);
  };

  public absenceList = async (
    personId: string
  ): Promise<PersonAbsenceEntry[]> => {
    const request = { personId };
    return await this._http.post<PersonRequest, PersonAbsenceEntry[]>(
      '/person/absence-list',
      request
    );
  };

  public personsPerAbsenceInterval = async (
    list: AbstractId[]
  ): Promise<EmployeesPerIntervalResponse[]> => {
    const request = { departmentIds: abstractIdsToDepartmentId(list) };
    return await this._http.post<
      DepartmentRequest,
      EmployeesPerIntervalResponse[]
    >('/person/personsPerAbsenceInterval', request);
  };

  public employmentTypes = async (
    list: AbstractId[]
  ): Promise<EmploymentType[]> => {
    const request = { departmentIds: abstractIdsToDepartmentId(list) };
    const response = await this._cachedHttp.post<
      DepartmentRequest,
      EmploymentType[]
    >('/person/employmentTypes', request);
    return response;
  };
}

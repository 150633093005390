import { JWTToken, TokenWatcher } from '../token';

export type ApiConfig = {
  endpoint: string;
  token: JWTToken;
  role?: RoleName;
  accessErrorCallback: (error: Response) => boolean;
  jwtTokenChange?: (token: JWTToken) => void;
};

export type RoleName =
  | 'Manager'
  | 'ActingManager'
  | 'HRAdmin'
  | 'StatisticsSupport'
  | 'RehabCoordinator'
  | 'AccountManager'
  | 'PersonnelAdmin';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type RequestParams = {
  body?: string;
  withRole?: boolean;
};

const dateTimeMatcher =
  /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[01])[T\s](?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)(?:(?:[zZ]|[\+-](?:[01]\d|2[0-3]):?[0-5]\d))?$/;

const reviver = (key: string, value: any) => {
  if (typeof value === 'string' && dateTimeMatcher.test(value)) {
    return new Date(value);
  }

  return value;
};

export class Http {
  private _config: ApiConfig;
  private _lastRequestTime: Date;
  private _tokenWatchers: TokenWatcher[];

  constructor(config: ApiConfig) {
    this._config = config;
    this._lastRequestTime = new Date();
    this._tokenWatchers = [];
  }

  public get lastRequestTime() {
    return this._lastRequestTime;
  }

  public get token() {
    return this._config.token;
  }

  public addTokenWatcher(watcher: TokenWatcher): void {
    this._tokenWatchers.push(watcher);
  }

  public setToken = (token: JWTToken) => {
    this._config.token = token;
    this.notifyTokenWatchers(token);
  };

  public setRole = (role: RoleName) => {
    this._config.role = role;
  };

  public currentRole = (): RoleName | undefined => {
    return this._config.role;
  };

  public get = async <T>(endpoint: string, withRole: boolean = true) => {
    return (await this.request(endpoint, 'GET', { withRole })) as T;
  };

  public post = async <T, Q>(
    endpoint: string,
    data: T,
    withRole: boolean = true
  ) => {
    const body = JSON.stringify(data);
    return (await this.request(endpoint, 'POST', { withRole, body })) as Q;
  };

  public put = async <T, Q>(
    endpoint: string,
    data: T,
    withRole: boolean = true
  ) => {
    const body = JSON.stringify(data);
    return (await this.request(endpoint, 'PUT', { withRole, body })) as Q;
  };

  public delete = async <T, Q>(
    endpoint: string,
    data: T,
    withRole: boolean = true
  ) => {
    const body = JSON.stringify(data);
    return (await this.request(endpoint, 'DELETE', { withRole, body })) as Q;
  };

  public file = async <T extends { [key: string]: string | Blob }>(
    endpoint: string,
    data: T,
    withRole: boolean = true
  ) => {
    var form = new FormData();
    for (const [key, value] of Object.entries(data)) {
      form.append(key, value);
    }

    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${this._config.token.rawValue}`,
    };

    if (withRole) {
      const role = this._config.role as string;
      headers['X-Role'] = role === 'PersonalAdmin' ? 'PersonnelAdmin' : role;
    }

    const response = await fetch(`${this._config.endpoint}${endpoint}`, {
      method: 'POST',
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'same-origin',
      headers,
      body: form,
    });

    if (response.ok) {
      const data = await response.text();
      if (!data || data === '') {
        return null;
      }

      return JSON.parse(data, reviver);
    }

    const handled = this._config.accessErrorCallback(response);
    if (handled) {
      return null;
    }

    throw new Error('HTTP error');
  };

  private notifyTokenWatchers(data: any): void {
    for (const watcher of this._tokenWatchers) {
      watcher.update(data);
    }
  }

  private request = async (
    endpoint: string,
    method: HttpMethod,
    params: RequestParams = {}
  ) => {
    this._lastRequestTime = new Date();

    const { withRole = true, body = null } = params;

    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this._config.token.rawValue}`,
    };

    if (withRole) {
      const role = this._config.role as string;
      headers['X-Role'] = role === 'PersonalAdmin' ? 'PersonnelAdmin' : role;
    }

    const response = await fetch(`${this._config.endpoint}${endpoint}`, {
      method,
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'same-origin',
      headers,
      body,
    });

    if (response.ok) {
      const data = await response.text();
      if (!data || data === '') {
        return null;
      }

      return JSON.parse(data, reviver);
    }

    const handled = this._config.accessErrorCallback(response);
    if (handled) {
      return null;
    }

    throw new Error('HTTP error');
  };
}

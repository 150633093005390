import React, { useEffect, useState } from 'react';

import { api } from '@/api';

import Loading from '../Loading';

export const withNavigation = (BaseComponent) => {
  const withNavigationComponent = (props) => {
    const { dataSet } = props;

    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [activeEmployeeCount, setActiveEmployeeCount] = useState(null);
    const [activeCompaniesEmployeeCount, setActiveCompaniesEmployeeCount] =
      useState(null);
    const [companyNames, setCompanyNames] = useState([]);

    useEffect(() => {
      let unmounted = false;

      if (!dataSet) {
        return () => {
          unmounted = true;
        };
      }

      setLoading(true);
      api.navigation
        .execute(dataSet)
        .then((data) => {
          if (!unmounted) {
            setEmployees(data.employees);
            setActiveEmployeeCount(data.activeEmployeesCount);
            setActiveCompaniesEmployeeCount(data.activeEmployeesAtCompanyCount);
            setCompanyNames(data.companyNames);
          }
        })
        .catch((err) => {
          api.logger.log('error', 'Failed to fetch organization features', {
            exeptionMessage: err.message,
          });
        })
        .finally(() => {
          if (!unmounted) {
            setLoading(false);
          }
        });

      return () => {
        unmounted = true;
      };
    }, [dataSet]);

    if (loading) {
      return <Loading />;
    }

    return (
      <BaseComponent
        {...props}
        employees={employees}
        activeEmployeeCount={activeEmployeeCount ? activeEmployeeCount : 0}
        activeCompaniesEmployeeCount={
          activeCompaniesEmployeeCount ? activeCompaniesEmployeeCount : 0
        }
        companyNames={companyNames ? companyNames : undefined}
      />
    );
  };

  return withNavigationComponent;
};

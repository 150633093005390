import React from 'react';
import classNames from 'classnames';

/**
 * @typedef LoaderProps
 * @property {boolean} isVisible
 * @property {string?} size
 */

/**
 * @param {LoaderProps} param0
 * @returns
 */
export const Loader = ({ isVisible, size = null }) => (
  <div
    className={classNames('loader', size ? `loader--size-${size}` : null, {
      'loader--is-visible': isVisible,
    })}
  >
    <div className="loader__bar loader__bar--red" />
    <div className="loader__bar loader__bar--yellow" />
    <div className="loader__bar loader__bar--orange" />
    <div className="loader__bar loader__bar--light-green" />
  </div>
);

export const InlineLoading = ({ message = null }) => {
  return (
    <div className="grid__container">
      <div
        className={classNames('loader__container', 'loader__container--inline')}
      >
        <Loader isVisible={true} size="small" />
        {message && <span>{message}</span>}
      </div>
    </div>
  );
};

/**
 * @typedef LoadingProps
 * @property {boolean} [isVisible]
 * @property {string?} [size]
 * @property {boolean} [slim]
 * @property {React.ReactNode?} [children]
 */

/**
 * @param {LoadingProps} param0
 * @returns
 */
const Loading = ({
  isVisible = true,
  size = null,
  children = null,
  slim = false,
}) => (
  <div className="grid__container">
    <div
      className={classNames('loader__container', {
        'loader__container--slim': slim,
      })}
    >
      <Loader isVisible={isVisible} size={size} />
      {React.Children.count(children) > 0 ? (
        <span className="loader__text">{children}</span>
      ) : null}
    </div>
  </div>
);

export default Loading;

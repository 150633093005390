import React, { useContext } from 'react';
import { connect } from 'react-redux';

import {
  RolesAuthorizedFor,
  hasAccessTo,
} from '../../common/roles-authorization';
import { OrganisationFeaturesContext } from '../../contexts/organisation-features-context';
import { ORG_FEATURES, hasFeature } from '../../utils/organisationFeatures';
import ErrorPage from '../ErrorPage';
import { RehabPlanList } from './RehabPlanList';

const mapStateToPropsLinks = ({ role }) => ({ role });

export const RehabPlansLayout = connect(mapStateToPropsLinks)(({ role }) => {
  const { loading, features } = useContext(OrganisationFeaturesContext);

  if (loading) {
    return null;
  }

  const hasFeatureRehabPlans = hasFeature(
    features,
    ORG_FEATURES.RehabPlaneraren
  );
  const hasAccessRehabPlans = hasAccessTo(
    RolesAuthorizedFor.Feature.RehabPlans,
    role
  );

  if (!hasFeatureRehabPlans || !hasAccessRehabPlans) {
    return <ErrorPage statusCode={403} />;
  }

  return (
    <>
      <RehabPlanList />
    </>
  );
});

import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
const ERROR_STATUS_CODE_THEMES = {
  503: 'info',
  204: 'info',
};

const ErrorMessage = ({ children, statusCode }) => {
  const theme = ERROR_STATUS_CODE_THEMES[statusCode] || 'error';
  const { t } = useTranslation();

  return (
    <div
      className={classNames('error-message', `error-message--theme-${theme}`)}
    >
      {children}

      <p className="error-message__code typography--font-weight-medium">
        {t('common.error', { statusCode: statusCode })}
      </p>
    </div>
  );
};

export default ErrorMessage;

// http://www.prefix.nu/siffror-och-tal.html
import { Lang } from './i18n';

type NumberTranslation = { [key: number]: string };
type NumberTranslations = {
  [key in Lang]: NumberTranslation;
};

const VERBALIZABLE_NUMBERS: NumberTranslations = {
  sv: {
    1: 'en', // OPTIMIZE: Need to handle case for ett here?
    2: 'två',
    3: 'tre',
    4: 'fyra',
    5: 'fem',
    6: 'sex',
    7: 'sju',
    8: 'åtta',
    9: 'nio',
    10: 'tio',
    11: 'elva',
    12: 'tolv',
  },
  en: {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
  },
};

export const verbalizeNumber = (number: number, lang: Lang = 'sv') => {
  return VERBALIZABLE_NUMBERS[lang][number] || String(number);
};

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

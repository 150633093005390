import { Http } from './http';
import { store } from '../store';
import { openTokenModal } from '../actions';
import { isUserActive } from '../common/utils/userUtils';

export type TokenPayload = {
  personno: string;
  orgno: string;
  name: string;
  company: string;
  iat: number;
  exp: number;
  oct: number;
};

export interface TokenWatcher {
  update(data: any): void;
}

export class JWTToken {
  private _rawValue: string;
  private _payload: TokenPayload;
  private _lifespanInMs: number;

  constructor(rawValue: string) {
    this._rawValue = rawValue;
    this._payload = this.parsePayload();
    this._lifespanInMs = this.calculateLifespan();
  }

  get rawValue() {
    return this._rawValue;
  }

  get payload() {
    return this._payload;
  }

  get lifespanInMs() {
    return this._lifespanInMs;
  }

  private parsePayload(): TokenPayload {
    const payload = JSON.parse(
      Buffer.from(this._rawValue.split('.')[1], 'base64').toString()
    ) as TokenPayload;
    return payload;
  }

  private calculateLifespan() {
    return this._payload.exp * 1000 - Date.now();
  }
}

export class Token implements TokenWatcher {
  private _http: Http;
  private _tokenRefreshCallback: () => Promise<void>;

  constructor(http: Http, tokenRefreshCallback: () => Promise<void>) {
    this._http = http;
    this._tokenRefreshCallback = tokenRefreshCallback;
    this.startCountdown();
  }

  get expirationTimeInMs() {
    return this._http.token.payload.exp * 1000;
  }

  get lifespanInMs() {
    return this._http.token.lifespanInMs;
  }

  update(data: any) {
    if (data instanceof JWTToken) {
      this.startCountdown();
    }
  }

  startCountdown(): void {
    if (this.lifespanInMs <= 0) {
      return;
    }
    setTimeout(() => {
      this.shouldTokenModalOpen();
    }, this.lifespanInMs * 0.75);
  }

  private async shouldTokenModalOpen() {
    const isActive = isUserActive(
      this._http.lastRequestTime,
      this.lifespanInMs
    );

    if (isActive) {
      await this._tokenRefreshCallback();
      return;
    }

    store.dispatch(openTokenModal());
  }
}

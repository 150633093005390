import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Department } from '../../models/Department';
import { DepartmentItemView, States } from './DepartmentItemView';

const createFakeDepartments = (t) => {
  const selectedDepartment = new Department(
    {
      abstract_id: '-1',
      name: t('DepartmentPicker.help.clickSteps.oneTitle'),
      authorized: true,
    },
    {},
    null
  );
  selectedDepartment.select();

  const partiallySelectedDepartment = new Department(
    {
      abstract_id: '-2',
      name: t('DepartmentPicker.help.clickSteps.twoTitle'),
      authorized: true,
    },
    {
      '-2': [
        {
          id: '-4',
          name: 'Subdepartment',
          departments: [],
        },
      ],
    },
    null
  );
  partiallySelectedDepartment.select();

  const notSelectedDepartment = new Department(
    {
      abstract_id: '-3',
      name: t('DepartmentPicker.help.clickSteps.threeTitle'),
      authorized: true,
    },
    {},
    null
  );

  return {
    selectedDepartment,
    partiallySelectedDepartment,
    notSelectedDepartment,
  };
};

export const DepartmentPickerHelpSection = () => {
  const { t } = useTranslation();
  const departments = createFakeDepartments(t);

  return (
    <div className="help-section">
      <p className="help-section__text">{t('DepartmentPicker.help.intro')}</p>
      <div className="help-section__section">
        <div className="select-state-description">
          <span className="select-state-description__intro">
            <Trans i18nKey="DepartmentPicker.help.clickSteps.one">
              Klicka <em>en</em> gång för att välja:
            </Trans>
          </span>
          <span className="select-state-description__example">
            <DepartmentItemView
              department={departments.selectedDepartment}
              states={[States.Selected]}
              subSelectCount={0}
            />
          </span>
        </div>
        <div className="select-state-description">
          <span className="select-state-description__intro">
            <Trans i18nKey="DepartmentPicker.help.clickSteps.two">
              Klicka <em>två</em> gånger för att välja:
            </Trans>
          </span>
          <span className="select-state-description__example">
            <DepartmentItemView
              department={departments.partiallySelectedDepartment}
              states={[States.Selected]}
              subSelectCount={0}
            />
          </span>
        </div>
        <div className="select-state-description">
          <span className="select-state-description__intro">
            <Trans i18nKey="DepartmentPicker.help.clickSteps.three">
              Klicka <em>tre</em> gånger för att avmarkera:
            </Trans>
          </span>
          <span className="select-state-description__example">
            <DepartmentItemView
              department={departments.notSelectedDepartment}
              states={[]}
              subSelectCount={0}
            />
          </span>
        </div>
      </div>
      <div className="help-section__section">
        <div className="select-count-description">
          <span className="select-count-description__example">
            <span className="nav-item--expandable">
              <span className="nav-item__selected nav-item__selected--active">
                (10)
              </span>
            </span>
          </span>
          <span className="select-count-description__text">
            {t('DepartmentPicker.help.parenthesesDescription')}
          </span>
        </div>
      </div>
      <div className="help-section__section">
        <h3 className="help-section__subheader">
          {t('DepartmentPicker.help.shortcuts.header')}
        </h3>
        <div className="short-cut-example">
          <span className="short-cut-example__shortcut">
            <span className="short-cut-example__modifier">ctrl</span>
            <span className="short-cut-example__and"></span>
            <span className="short-cut-example__action">
              {t('DepartmentPicker.help.shortcuts.clickText')}
            </span>
          </span>
          <span className="short-cut-example__text">
            {t('DepartmentPicker.help.shortcuts.ctrlPlusClick')}
          </span>
        </div>
        <div className="short-cut-example">
          <span className="short-cut-example__shortcut">
            <span className="short-cut-example__modifier">alt</span>
            <span className="short-cut-example__and"></span>
            <span className="short-cut-example__action">
              {t('DepartmentPicker.help.shortcuts.clickText')}
            </span>
          </span>
          <span className="short-cut-example__text">
            {t('DepartmentPicker.help.shortcuts.altPlusClick')}
          </span>
        </div>
      </div>
    </div>
  );
};

import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months';
import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';
import isSameMonth from 'date-fns/is_same_month';

export const getDayNames = (
  startDate,
  length = 7,
  offsetDays = 0,
  lang = 'sv'
) => {
  // IE11 cannot create a date from our config.dateFormat so we run startDate
  // through date-fns format to get a ISO 8601 formatted string
  const date = new Date(format(startDate));

  if (offsetDays) {
    date.setDate(date.getDate() + offsetDays);
  }

  return new Array(length).fill('').map(() => {
    const day = format(date, 'ddd', {
      locale: lang === 'sv' ? svLocale : undefined,
    });

    date.setDate(date.getDate() + 1);

    return day;
  });
};

export const getMonths = (start, end) => {
  // IE11 cannot create a date from our config.dateFormat so we run startDate
  // through date-fns format to get a ISO 8601 formatted string
  const startDate = new Date(format(start));
  const endDate = new Date(format(end));
  const months = [];
  startDate.setDate(1);
  for (let m = startDate; m <= endDate; m.setMonth(m.getMonth() + 1)) {
    months.push(new Date(m));
  }
  return months;
};

export const getMonthNames = (start, end, lang = 'sv') => {
  return getMonths(start, end).map((date) =>
    format(date, 'MMM', { locale: lang === 'sv' ? svLocale : undefined })
  );
};

export const getYearMonthNames = (start, end, lang = 'sv') => {
  return getMonths(start, end).map((date) =>
    format(date, 'YYYY-MMM', { locale: lang === 'sv' ? svLocale : undefined })
  );
};

export const getNumberOfMonths = (start, end, now) =>
  differenceInCalendarMonths(end, start) + (isSameMonth(end, now) ? 0 : 1);

export const formatDate = (date) => format(date, 'YYYY-MM-DD HH:mm:ss');

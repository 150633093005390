import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { hasAccessTo } from '@/common/roles-authorization';
import { compose } from 'redux';

const Masked = ({ children, access, t, role }) => {
  return hasAccessTo(access, role) ? children : t('common.masked');
};

const mapStateToProps = ({ role }) => ({ role });

export default compose(withTranslation(), connect(mapStateToProps))(Masked);

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { search } from '../Icons';

export const DepartmentPickerTop = ({ setSearchTerm }) => {
  const searchInputRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div className="new-department-picker__top">
      <div className="search">
        <label className="search__label">
          <span className="search__icon">{search}</span>
          <input
            className="search__input"
            type="search"
            name="department-query"
            placeholder={t('DepartmentPicker.search.input.placeholder')}
            autoComplete="off"
            ref={searchInputRef}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          />
        </label>
      </div>
    </div>
  );
};

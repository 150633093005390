import React from 'react';

import config from '../../config';
import { getKey } from '../../utils';

export const RehabPlanFileDocumentLink = ({
  children,
  documentId,
  fileName,
}) => {
  const setTokenCookie = () => {
    const token = getKey(config.localStorage.tokenKey);
    const role = getKey(config.localStorage.role);
    window.document.cookie = `token=${token};max-age=1;path=/`;
    window.document.cookie = `selected-role=${role};max-age=1;path=/`;
  };

  return (
    <a
      href={`${config.file.endpoint}/${documentId}/${encodeURIComponent(
        fileName
      )}`}
      target="__blank"
      onClick={setTokenCookie}
    >
      {children}
    </a>
  );
};

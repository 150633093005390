import { buildStoreStateHook } from './stored-state-builder';

const api = {
  get: (key) => localStorage.getItem(key),
  save: (key, value) => localStorage.setItem(key, value),
};

export const useLocalStoredState = buildStoreStateHook(
  api,
  'local-stored-state'
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openDepartmentPicker } from '../../actions';
import { chevronDown } from '../Icons';
import { Trans } from 'react-i18next';

const mapDispatchToProps = (dispatch) => ({
  openDepartmentPicker: () => dispatch(openDepartmentPicker()),
});
const mapStateToProps = ({ dataSet, department }) => ({
  dataSet: dataSet,
  selectedDepartment: department,
});

export const DepartmentPickerToggle = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ openDepartmentPicker, dataSet }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    openDepartmentPicker();
  };
  const count = dataSet.length;

  return (
    <span className={'department-picker-toggle'}>
      <span className="department-picker-toggle__label">
        {t('DepartmentPickerToggle.label')}
      </span>
      <button
        className="department-picker-toggle__button"
        onClick={handleClick}
      >
        <span className="department-picker-toggle__text">
          <Trans
            i18nKey="DepartmentPickerToggle.chosenDepartments"
            count={count}
          >
            Visar statistik för
            <span className="department-picker-toggle__counter">
              {{ count }}
            </span>
          </Trans>
        </span>
        <span className="department-picker-toggle__arrow">{chevronDown}</span>
      </button>
    </span>
  );
});

import { features, PreviaFeaturesConfig } from ".";
import { FeatureName, RepositryItem } from "./repository";

interface PreviaFeatures {
  print: () => void
  toggle: (featureName: FeatureName) => void
}

declare global {
  interface Window {
    PreviaFeatures: PreviaFeatures;
    console: Console
  }
}

const print = (window: Window) => (): void => {
  window.console.table(features.list());
}

const toggle = (window: Window) => (featureName: FeatureName) => {
  const changedFeatures = features.toggle(featureName)
  window.console.table(changedFeatures);
};

export const install = (window: Window, config: PreviaFeaturesConfig = { template: [] }) => {
  console.group("Previa Features CLI");
  console.log("Start setup");

  const handleStateChange = (config: RepositryItem) => {
    const data = JSON.stringify(config);
    window.localStorage.setItem("previa.features", data);
  }
  features.onStateChange(handleStateChange);

  const featureData = window.localStorage.getItem("previa.features") || null;
  console.log(`Loading stored config. Is empty ${featureData === null}`);

  try {
    features.init(config.template, featureData);
  } catch (err) {
    console.error('Failed to load features', err);
  }

  window.PreviaFeatures = {
    print: print(window),
    toggle: toggle(window),
  };

  console.groupEnd();
}

// TOOD uninstall?

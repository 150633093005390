import { Http } from './http';
import { AbstractId, DateOnly, DepartmentId } from './types';

export interface DepartmentRequest {
  departmentIds: DepartmentId[];
}

export interface DateRangeDepartmentRequest extends DepartmentRequest {
  departmentIds: DepartmentId[];
  periodStart: DateOnly;
  periodEnd: DateOnly;
}

export interface DateRangeRequest {
  periodStart: DateOnly;
  periodEnd: DateOnly;
}

export type AnalysisFilters = AnalysisFilter[];

export abstract class AnalysisFilter {
  property: string;

  public constructor(property: string) {
    this.property = property;
  }
}

export class AnalysisAgeFilter extends AnalysisFilter {
  minAge: number;
  maxAge: number;
  disableMaxAgeLimit: boolean;

  public constructor(
    minAge: number,
    maxAge: number,
    disableMaxAgeLimit: boolean
  ) {
    super('age');
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.disableMaxAgeLimit = disableMaxAgeLimit;
  }
}

export class AnalysisEmploymentTypeFilter extends AnalysisFilter {
  employmentTypes: string[] | null;
  includePersonsWithoutEmploymentType: boolean;

  public constructor(
    employmentTypes: string[] | null,
    includePersonsWithoutEmploymentType: boolean
  ) {
    super('employmentType');
    this.employmentTypes = employmentTypes;
    this.includePersonsWithoutEmploymentType =
      includePersonsWithoutEmploymentType;
  }
}

export type GenderValues = 'all' | 'male' | 'female';

export class AnalysisGenderFilter extends AnalysisFilter {
  gender: GenderValues;

  public constructor(gender: GenderValues) {
    super('gender');
    this.gender = gender;
  }
}

export class ApiService {
  protected _http: Http;

  constructor(http: Http) {
    this._http = http;
  }

  protected dateToDateOnly = (date: Date | DateOnly) =>
    date instanceof Date ? new DateOnly(date) : date;
}

const abstractIdRemover = /d?([0-9]+)/;
const abstractIdToDepartmentId = (item: AbstractId): DepartmentId => {
  const result = abstractIdRemover.exec(item);
  if (result === null) {
    return item;
  } else {
    return result[1];
  }
};

export const abstractIdsToDepartmentId = (list: AbstractId[]): DepartmentId[] =>
  list.map(abstractIdToDepartmentId);

export const Genders = Object.freeze({
  Male: 'male',
  Female: 'female',
  All: 'Alla',
});

import { differenceInMonths, endOfMonth, isSameMonth, startOfMonth, subMonths } from "date-fns";

export interface AnalysisPeriod {
  start: Date,
  end: Date,
}

export const analysisPeriodFromDates = (start: Date, end: Date, now: Date = new Date()): AnalysisPeriod => {
  // Normalize values -- we only care about year and month
  start = startOfMonth(start);
  end = startOfMonth(end);

  if (isSameMonth(start, now) && isSameMonth(end, now)) {
    start = startOfMonth(now);
    end = now;
  } else if (isSameMonth(end, now)) {
    const monthCount = differenceInMonths(end, startOfMonth(start));
    start = subMonths(now, monthCount);
    end = now;
  } else {
    end = endOfMonth(end);
  }

  return {
    start,
    end,
  };
}
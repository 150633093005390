import classNames from 'classnames';
import React from 'react';

export const BlockHeader = ({ title = null, actions = [], className = '' }) => {
  return (
    <div className={classNames('block-header', className)}>
      {title && <h2 className="block-header__title">{title}</h2>}
      <span className="block-header__actions block-header-actions">
        {actions}
      </span>
    </div>
  );
};

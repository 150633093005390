import React from 'react';

export const Modifiers = {
  CTRL: 1,
  ALT: 2,
  SHIFT: 4,
  match: (value, mod) => (value & mod) > 0,
};

export const CheckboxStates = {
  NOT_SELECTED: Symbol.for('NOT_SELECTED'),
  PARTIALLY_SELECTED: Symbol.for('PARTIALLY_SELECTED'),
  SELECTED: Symbol.for('SELECTED'),
};

const computeModMap = (event) => {
  let mod = 0;
  if (event.ctrlKey) {
    mod = mod | Modifiers.CTRL;
  }
  if (event.altKey) {
    mod = mod | Modifiers.ALT;
  }
  if (event.shiftKey) {
    mod = mod | Modifiers.SHIFT;
  }
  return mod;
};

export const DepartmentPickerThreeStateCheckbox = (props) => {
  const {
    state = CheckboxStates.NOT_SELECTED,
    onSelect,
    className = '',
    ...otherProps
  } = props;

  const handleClick = (e) => {
    e.preventDefault();

    if (onSelect) {
      const mod = computeModMap(e);
      onSelect(mod);
    }
  };

  const stateClassNames = [className, 'three-state-checkbox'];
  if (state === CheckboxStates.PARTIALLY_SELECTED) {
    stateClassNames.push('three-state-checkbox--checked');
  } else if (state === CheckboxStates.SELECTED) {
    stateClassNames.push('three-state-checkbox--checked-with-subtree');
  }

  return (
    <span
      className={stateClassNames.join(' ')}
      onClick={handleClick}
      {...otherProps}
    ></span>
  );
};

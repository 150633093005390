import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { closeTokenModal } from '../actions';
import { api } from '../api';
import { logout } from '../utils';
import Button from './Button';
import Modal from './Modal';
import { bemClasses } from './ui/utils/bem-classes';

const TokenModal = ({ isOpen, close }) => {
  if (!isOpen) {
    return null;
  }

  return <TokenModalComponent close={close} />;
};

const TokenModalComponent = ({ close }) => {
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTokenExpired(Date.now() > api.token.expirationTimeInMs);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const tokenRefresh = async () => {
    await api.user.tokenRefresh();
    close();
  };

  const bem = bemClasses('token-modal');
  const { t } = useTranslation();

  return (
    <Modal className={bem.block()}>
      <p>
        {isTokenExpired
          ? t('common.token.expired')
          : t('common.token.expiresSoon')}
      </p>
      <div>
        {isTokenExpired ? null : (
          <Button onClick={tokenRefresh}>{t('common.token.continue')}</Button>
        )}
        <Button onClick={logout} theme={'secondary'}>
          {t('common.token.logOut')}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ isOpen: state.token.isModalOpen });

const mapDispatchToProps = {
  close: closeTokenModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenModal);

import { colorDarkRed, colorRed } from './colors';

export const conditionColors = (condition) => {
  switch (condition) {
    case 'VAB':
    case '26':
      return colorDarkRed;
    case 'action':
      return colorRed;
    default:
      return colorRed;
  }
};

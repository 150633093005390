export type DepartmentId = string;

export type AbstractId = string;

export enum AbsenceType {
  SickLeave = 0,
  CareOfChild = 1,
}

export interface BasicPerson {
  id: string;
  person_id: string;
  firstName: string;
  lastName: string;
}

export interface BasicDepartment {
  id: string;
  name: string;
}

export interface BasicAbsence {
  id: string;
  fromDate: Date;
  toDate: Date;
}

export interface RepeatedShortTermAbsence extends BasicAbsence {
  ChainId: string;
  CalendarDays: number;
}

export type Condition = {
  id: string;
  name: string;
  categoryName: string;
};

export type DepartmentHierachyEntry = {
  abstract_id: string;
  org_nr: string;
  root_org_nr: string;
  name: string;
  parent_id: string | null;
  authorized: boolean;
  active_contract: boolean;
  is_subsidiary: boolean;
};

export type ReportEntry = {
  person: BasicPerson;
  department: BasicDepartment;
  chains: RepeatedShortTermAbsence[];
};

export interface BasicRehabPlan {
  rehab_plan_id: string;
  is_archived: boolean;
  archived_datestamp: Date;
  created_timestamp: Date;
  created_by: string;
  documents: [BasicRehabPlanDocument];
}

export interface BasicRehabPlanDocument {
  rehab_plan_document_id: string;
  created_by_name: string;
  created_timestamp: Date;
  type: string;
  updated_by_name: string;
  updated_timestamp: Date;
}

export interface DocumentType {
  type: string;
}

export interface Note extends DocumentType {
  id: string;
  title: string;
  text: string;
}

export interface RehabPlanDocument extends BasicRehabPlanDocument {
  document: DocumentType;
  content: string;
}

export class DateOnly {
  private _year: number;
  private _month: number;
  private _date: number;

  constructor(input: Date) {
    this._year = input.getFullYear();
    this._month = input.getMonth() + 1;
    this._date = input.getDate();
  }

  toJSON() {
    return `${this._year}-${`${this._month}`.padStart(
      2,
      '0'
    )}-${`${this._date}`.padStart(2, '0')}`;
  }
}

export type Role = string;

import { useEffect, useState } from 'react';

/**
 * @typedef StorageApi
 * @property {any} get
 * @property {any} save
 */

/**
 * @callback StorageHook
 * @param {string} storageKey
 * @param {any} fallbackValue
 * @returns {any[]}
 */

/**
 * @param {StorageApi} api
 * @param {string} cachePrefix
 * @returns {StorageHook}
 */
export const buildStoreStateHook = (api, cachePrefix) => {
  const serializeValue = (value) => {
    if (typeof value === 'symbol') {
      return `symbol:${Symbol.keyFor(value)}`;
    }
    return JSON.stringify(value);
  };

  const deserializeValue = (value) => {
    if (value.indexOf('symbol:') === 0) {
      return Symbol.for(value.substring(7));
    }
    return JSON.parse(value);
  };

  const getStorageValue = (key, fallback) => {
    const fullKey = `${cachePrefix}_${key}`;
    const storageValue = api.get(fullKey);
    return storageValue === null ? fallback : deserializeValue(storageValue);
  };

  const setStorageValue = (key, value) => {
    const fullKey = `${cachePrefix}_${key}`;
    try {
      api.save(fullKey, serializeValue(value));
      return true;
    } catch (err) {
      return false;
    }
  };

  return (storageKey, fallbackValue) => {
    const [value, setValue] = useState(
      getStorageValue(storageKey, fallbackValue)
    );

    useEffect(() => {
      setStorageValue(storageKey, value);
    }, [value]);

    return [value, setValue];
  };
};

import store from 'store/dist/store.modern';
import expirePlugin from 'store/plugins/expire';

import { store as reduxStore } from '../store';

store.addPlugin(expirePlugin);

export const createUserScopedKey = (key: string) => {
  const { user } = reduxStore.getState();

  if (user) {
    const { user_hash } = user;

    return `${key}/${user_hash}`;
  }

  return false;
};

export const getKey = (key: string) => store.get(key);

export const getSessionKey = (key: string) => sessionStorage.getItem(key);

export const setKey = (key: string, value: string) => store.set(key, value);

export const setSessionKey = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const removeKey = (key: string) => store.remove(key);

export const removeSessionKey = (key: string) => sessionStorage.removeItem(key);

export const clearAll = () => store.clearAll();

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { deselectAll, questionMark, smallChevronRight } from '../Icons';
import { ButtonV2 } from '../ui/button/ButtonV2';
import { LinkButton } from '../ui/button/LinkButton';

export const DepartmentPickerFooter = ({
  selectedCount,
  onShowHelpSection,
  onShowCurrentSelection,
  onChoiceSelected,
  onDeselectAll,
}) => {
  const { t } = useTranslation();

  const handleHelpAction = () => {
    onShowHelpSection && onShowHelpSection();
  };

  const handleDeselectAll = () => {
    onDeselectAll && onDeselectAll();
  };

  const handleCurrentSelectionViewClick = () => {
    onShowCurrentSelection && onShowCurrentSelection();
  };

  const handleChoiceSelected = () => {
    onChoiceSelected && onChoiceSelected();
  };

  const showSelectionClasses = ['nav-controls__action', 'nav-action'];
  const disableSubmitButton = selectedCount < 1;
  if (disableSubmitButton) {
    showSelectionClasses.push('nav-action--disabled');
  }

  const count = selectedCount; // name variable "count" because i18next requires it

  return (
    <div className="new-department-picker__bottom nav-controls">
      <LinkButton
        type="tertiary"
        className="nav-controls__tertiary-action help-action"
        onClick={handleHelpAction}
        prefixElement={
          <span className="nav-controls__tertiary-action-icon">
            {questionMark}
          </span>
        }
      >
        <span className="nav-controls__action-text">
          {t('DepartmentPicker.footer.helpActionText')}
        </span>
      </LinkButton>

      <LinkButton
        type="tertiary"
        className="nav-controls__tertiary-action help-action"
        onClick={handleDeselectAll}
        prefixElement={
          <span className="nav-controls__tertiary-action-icon">
            {deselectAll}
          </span>
        }
      >
        <span className="nav-controls__action-text">
          {t('DepartmentPicker.footer.deselectAllActionText')}
        </span>
      </LinkButton>

      <span className="nav-controls__spacer"></span>
      <LinkButton
        onClick={handleCurrentSelectionViewClick}
        type="secondary"
        suffixElement={smallChevronRight}
      >
        <Trans
          i18nKey="DepartmentPicker.footer.selectedDepartmentsActionText"
          count={count}
        >
          <span data-js="selected-departments-count">{{ count }}</span>{' '}
          markerade avdelningar
        </Trans>
      </LinkButton>

      <ButtonV2
        disabled={disableSubmitButton}
        extraClassName={showSelectionClasses.join(' ')}
        onClick={handleChoiceSelected}
      >
        {t('DepartmentPicker.footer.showSelection')}
      </ButtonV2>
    </div>
  );
};

import React from 'react';

import {
  CheckboxStates,
  DepartmentPickerThreeStateCheckbox,
} from './DepartmentPickerThreeStateCheckbox';

export const DepartmentPickerCheckbox = ({ onSelect = null, department }) => {
  let state = CheckboxStates.NOT_SELECTED;
  if (department.isTreeSelected) {
    state = CheckboxStates.SELECTED;
  } else if (department.selected) {
    state = CheckboxStates.PARTIALLY_SELECTED;
  }

  const handleSelect = (mod) => onSelect && onSelect(mod);

  return (
    <DepartmentPickerThreeStateCheckbox
      className="nav-item__select"
      onSelect={handleSelect}
      state={state}
    />
  );
};

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocalStoredState } from '../../utils/hooks/local-stored-state';
import { DepartmentPickerColumn } from './DepartmentPickerColumn';
import { DepartmentPickerSearchResult } from './DepartmentPickerSearchResult';

const noop = () => {};

const menuObserver = {
  observers: [],
  add: function (observer) {
    this.observers.push(observer);
  },
  remove: function (observer) {
    const index = this.observers.indexOf(observer);
    if (index !== -1) {
      this.observers.splice(index, 1);
    }
  },
  onMenuOpen: function () {
    this.observers.forEach((observer) => {
      try {
        observer();
      } catch (err) {
        console.error('Unhandled error in menu observer', err);
      }
    });
  },
};

/**
 * @param {import('../../models/Department').Department} departmentTree
 * @param {[string]} pathIds
 * @returns {import('../../models/Department').Department[]}
 */
const buildDepartmentPath = (departmentTree, pathIds) => {
  /** @type {import('../../models/Department').Department[]} */
  const result = [departmentTree];
  let parent = departmentTree;
  for (let i = 1; i < pathIds.length; i++) {
    const pathId = pathIds[i];
    const newLevelDepartment = parent.departments.find((d) => d.id === pathId);
    if (newLevelDepartment === undefined) {
      break;
    }
    result.push(newLevelDepartment);
    parent = newLevelDepartment;
  }
  return result;
};

/**
 * @param {import('../../models/Department').Department} departmentTree
 * @returns {import('../../models/Department').Department[]}
 */
const computeSharedRootPath = (departmentTree) => {
  /**
   * Notice: this is a helper function designed to be called recursivly.
   *
   * Computes a candidate to use for the end point in the initial
   * department path expand. If there is such a candidate for the given
   * department that department is returned. If no candidate can be found
   * ie. there are multiple branches under this department with authorized
   * departments, null is returned.
   *
   * Notice that if a leaf department is identified as a candidate the
   * function will return that department. However a leaf cannot be used
   * as an endpoint for the department path. In this case it is the calling
   * codes responsible to change the candidate to the parent of the leaf
   * department.
   *
   * @param {import('../../models/Department').Department} department
   * @returns {import('../../models/Department').Department}
   */
  const findLowestAuthorizationBranchCandidate = (department) => {
    const candidates = [];
    for (const subDepartment of department.departments) {
      if (subDepartment.isAuthorized) {
        candidates.push(subDepartment);
        continue;
      }
      const subCandidate =
        findLowestAuthorizationBranchCandidate(subDepartment);
      if (subCandidate === null) {
        candidates.push(subDepartment);
      } else {
        candidates.push(subCandidate);
      }
    }
    if (candidates.length === 0) {
      throw new Error(
        `No authentication candidates. This should not be possible. Id: ${department.id}`
      );
    } else if (candidates.length > 1) {
      return null;
    } else {
      return candidates[0];
    }
  };

  const lowestBranchCandidate =
    findLowestAuthorizationBranchCandidate(departmentTree);
  const result = [];
  if (lowestBranchCandidate === null) {
    result.push(departmentTree);
  } else {
    let authorizationParent = lowestBranchCandidate;
    if (authorizationParent.isLeaf) {
      authorizationParent = authorizationParent.parent;
    }
    while (authorizationParent !== null) {
      result.unshift(authorizationParent);
      authorizationParent = authorizationParent.parent;
    }
  }

  return result;
};

const hasStoredPath = (pathIds) => pathIds && pathIds.length > 0;

/**
 * @typedef DepartmentPickerMainProps
 * @property {import('../../models/Department').Department} departmentTree
 * @property {string} searchTerm
 * @property {boolean} showSearchResult
 * @property {string} role
 * @property {(department: import('../../models/Department').Department, mod: import('./DepartmentPickerThreeStateCheckbox').CheckboxStates) => void} onDepartmentSelect
 * @property {() => void} onMenuAction
 * @property {() => void} onSearchResultDismiss
 */

const mapStateToProps = ({ role }) => ({ role });

export const DepartmentPickerMain = compose(connect(mapStateToProps))(
  (
    /** @type {DepartmentPickerMainProps} */ {
      departmentTree,
      searchTerm,
      showSearchResult,
      role,
      onDepartmentSelect = noop,
      onMenuAction = noop,
      onSearchResultDismiss = noop,
    }
  ) => {
    const [path, setPath] = useState([]);
    const [storedPathIds, setStoredPathIds] = useLocalStoredState(
      `department-picker-path_${role}`,
      []
    );
    if (path.length === 0) {
      let initialDepartmentPath;

      if (hasStoredPath(storedPathIds)) {
        initialDepartmentPath = buildDepartmentPath(
          departmentTree,
          storedPathIds
        );
      } else {
        initialDepartmentPath = computeSharedRootPath(departmentTree);
      }

      setPath(initialDepartmentPath);
    }
    const r = useRef();
    const pathLength = useRef(path.length);

    const handleDepartmentExpand = (newDepartment, level) => {
      const currentLastPart = path[level + 1] || null;
      const newPath = path.slice(0, level + 1);
      if (!currentLastPart || newDepartment.id !== currentLastPart.id) {
        newPath.push(newDepartment);
      }
      setPath(newPath);
      setStoredPathIds(newPath.map((d) => d.id));
    };

    useEffect(() => {
      if (path.length !== pathLength.current) {
        r.current.scrollTo({
          left: r.current.scrollWidth,
          top: 0,
          behavior: 'smooth',
        });
      }
      pathLength.current = path.length;
    }, [path]);

    const columnListItem = (column, level) => {
      return (
        <DepartmentPickerColumn
          key={column.id}
          department={column}
          selected={path.length > level + 1 ? path[level + 1].id : null}
          onDepartmentExpand={(expandedDepartment) =>
            handleDepartmentExpand(expandedDepartment, level)
          }
          onDepartmentSelect={onDepartmentSelect}
          onMenuAction={onMenuAction}
          menuObserver={menuObserver}
        />
      );
    };

    const handleNavigation = (department) => {
      onSearchResultDismiss && onSearchResultDismiss();
      const newPath = [];

      let item = department;
      while (item !== null) {
        newPath.unshift(item);
        item = item.parent;
      }
      setPath(newPath);
    };

    return (
      <div className="new-department-picker__main nav-content">
        <div className="nav-content__wrapper" data-js="columns" ref={r}>
          {path.map(columnListItem)}
        </div>
        {showSearchResult && (
          <div className="nav-content__search">
            <DepartmentPickerSearchResult
              departmentTree={departmentTree}
              searchTerm={searchTerm}
              onDepartmentSelect={onDepartmentSelect}
              onSearchResultDismiss={onSearchResultDismiss}
              onNavigation={handleNavigation}
            />
          </div>
        )}
      </div>
    );
  }
);

import { createContext, useEffect, useState } from 'react';

import { api } from '../api';
import { OrganisationFeatureSupport } from '../api/navigation';
import { AbstractId } from '../api/types';

export interface OrganisationFeaturesValue {
  loading: boolean;
  error: boolean;
  features: OrganisationFeatureSupport[];
}

const createOrganisationFeaturesValue = (
  loading: boolean,
  error: boolean,
  features: OrganisationFeatureSupport[]
) => ({
  loading,
  error,
  features,
});

export const OrganisationFeaturesContext =
  createContext<OrganisationFeaturesValue>(
    createOrganisationFeaturesValue(true, false, [])
  );

export const useOrganisationFeatures = (dataSet: AbstractId[]) => {
  const [state, setState] = useState<OrganisationFeaturesValue>({
    error: false,
    features: [],
    loading: true,
  });

  useEffect(() => {
    let unmounted = false;

    if (!dataSet || dataSet.length === 0) {
      return () => {
        unmounted = true;
      };
    }

    setState({ ...state, loading: true, error: false });
    api.navigation
      .today(dataSet)
      .then((data) => {
        if (!unmounted) {
          setState({ ...state, loading: false, features: data.features });
        }
      })
      .catch((err) => {
        api.logger.log('error', 'Failed to fetch organization features', {
          exeptionMessage: err.message,
        });
        if (!unmounted) {
          setState({ ...state, loading: false, error: true });
        }
      });

    return () => {
      unmounted = true;
    };
  }, [dataSet]);

  return state;
};

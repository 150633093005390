import classNames from 'classnames';
import React from 'react';

import { bemClasses } from '../utils/bem-classes';

export const LinkButton = ({
  children,
  className,
  type = 'primary',
  prefixElement = null,
  suffixElement = null,
  ...props
}) => {
  const bem = bemClasses('link-button');

  const typeClass = bem.modifier(type); // TODO validate input

  return (
    <button
      className={classNames(className, bem.block(), typeClass)}
      {...props}
    >
      {prefixElement}
      <span className={bem.element('content')}>{children}</span>
      {suffixElement}
    </button>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

export const DepartmentPickerColumnMenuActions = {
  SELECT_TREE: Symbol.for('SELECT_TREE'),
  SELECT_SUB_TREE: Symbol.for('SELECT_SUB_TREE'),
  SELECT: Symbol.for('SELECT'),
  DESELECT_TREE: Symbol.for('DESELECT_TREE'),
  DESELECT_SUB_TREE: Symbol.for('DESELECT_SUB_TREE'),
  DESELECT: Symbol.for('DESELECT'),
};

const MenuAction = ({ action }) => {
  return (
    <li className="column-menu-actions__items column-menu-action">
      <button
        className="column-menu-action__button"
        onClick={() => action.callback()}
      >
        {action.name}
      </button>
    </li>
  );
};

const MenuSection = ({ section }) => {
  return (
    <li className="column-menu-items_section column-menu-section">
      <span className="column-menu-section__title">{section.name}</span>
      <ul className="column-menu-section__actions column-menu-actions">
        {section.actions.map((action) => (
          <MenuAction key={action.name} action={action}></MenuAction>
        ))}
      </ul>
    </li>
  );
};

export const DepartmentPickerColumnMenu = ({ className = '', onAction }) => {
  const actionCallback = (actionName) => () => onAction && onAction(actionName);
  const { t } = useTranslation();

  const sections = [
    {
      name: t('DepartmentPicker.columnMenu.select.header'),
      actions: [
        {
          name: t(
            'DepartmentPicker.columnMenu.select.actions.selectIncludingUnderlying'
          ),
          callback: actionCallback(
            DepartmentPickerColumnMenuActions.SELECT_TREE
          ),
        },
        {
          name: t(
            'DepartmentPicker.columnMenu.select.actions.selectExcludingUnderlying'
          ),
          callback: actionCallback(DepartmentPickerColumnMenuActions.SELECT),
        },
        {
          name: t(
            'DepartmentPicker.columnMenu.select.actions.selectOnlyUnderlying'
          ),
          callback: actionCallback(
            DepartmentPickerColumnMenuActions.SELECT_SUB_TREE
          ),
        },
      ],
    },
    {
      name: t('DepartmentPicker.columnMenu.deselect.header'),
      actions: [
        {
          name: t(
            'DepartmentPicker.columnMenu.deselect.actions.deselectIncludingUnderlying'
          ),
          callback: actionCallback(
            DepartmentPickerColumnMenuActions.DESELECT_TREE
          ),
        },
        {
          name: t(
            'DepartmentPicker.columnMenu.deselect.actions.deselectExcludingUnderlying'
          ),
          callback: actionCallback(DepartmentPickerColumnMenuActions.DESELECT),
        },
        {
          name: t(
            'DepartmentPicker.columnMenu.deselect.actions.deselectOnlyUnderlying'
          ),
          callback: actionCallback(
            DepartmentPickerColumnMenuActions.DESELECT_SUB_TREE
          ),
        },
      ],
    },
  ];

  return (
    <div className={'column-menu ' + className}>
      <ul className="column-menu__items column-menu-items">
        {sections.map((section) => (
          <MenuSection key={section.name} section={section}></MenuSection>
        ))}
      </ul>
    </div>
  );
};

import { TFunction } from 'i18next';

import { TableColumnType, TableSort } from './types';

export type TableHeaderOptions = {
  asString?: (value: any) => string;
  rowClasses?: string;
  onClick?: (key: string) => void;
  columnType?: TableColumnType;
};

export type TableHeader = {
  columnType: TableColumnType;
  key: string;
  rowClasses: string;
  title: string;
  onClick?: (key: string) => void;
  asString?: (value: any) => string;
};

export const tableHeader = (
  key: string,
  title: string,
  rowClasses: string = '',
  columnType: TableColumnType = 'normal',
  asString?: (value: any) => string,
  onClick?: (key: string) => void
): TableHeader => ({
  key,
  title,
  columnType,
  rowClasses,
  asString,
  onClick,
});

export const tableHeaderLocalized = (
  localizationKey: string,
  options: TableHeaderOptions = {}
) => {
  const func = (t: TFunction) =>
    tableHeader(
      localizationKey,
      localizationKey === null ? '' : t(localizationKey),
      options.rowClasses,
      options.columnType,
      options.asString,
      options.onClick
    );
  func.key = localizationKey || '';
  return func;
};

export const updateTableSort = (
  current: TableSort,
  newKey: string
): TableSort => {
  if (current.key !== newKey) {
    return {
      key: newKey,
      ascending: true,
    };
  }

  return {
    key: current.key,
    ascending: !current.ascending,
  };
};

import React from 'react';

import { DepartmentItemView, States } from './DepartmentItemView';

export const DepartmentItem = ({
  department,
  onExpand,
  onSelect,
  expanded,
}) => {
  const states = [];

  let selected = false;
  if (department.isTreeSelected) {
    selected = true;
  } else if (department.selected) {
    selected = true;
  }

  const isExpandable = department.departments.length > 0;
  if (isExpandable) {
    states.push(States.IsExpandable);
  }
  if (selected) {
    states.push(States.Selected);
  }
  if (expanded) {
    states.push(States.Expanded);
  }

  const handleExpand = () => isExpandable && onExpand && onExpand();
  const handleSelect = (mod) => onSelect && onSelect(department, mod);

  const subSelectCount = department.subSelectCount;
  return (
    <DepartmentItemView
      department={department}
      states={states}
      subSelectCount={subSelectCount}
      onSelect={handleSelect}
      onExpand={handleExpand}
    />
  );
};

// http://www.prefix.nu/siffror-och-tal.html

const VERBALIZABLE_NUMBERS = {
  sv: {
    1: 'en', // OPTIMIZE: Need to handle case for ett here?
    2: 'två',
    3: 'tre',
    4: 'fyra',
    5: 'fem',
    6: 'sex',
    7: 'sju',
    8: 'åtta',
    9: 'nio',
    10: 'tio',
    11: 'elva',
    12: 'tolv',
  },
  en: {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
  },
};

export const verbalizeNumber = (number, lang = 'sv') => {
  return VERBALIZABLE_NUMBERS[lang][number] || String(number);
};

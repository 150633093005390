import React, { useEffect, useMemo, useState } from 'react';
import { DepartmentPickerSearchHit } from './DepartmentPickerSearchHit';

export const DepartmentPickerSearchResult = ({
  departmentTree,
  searchTerm,
  onDepartmentSelect = null,
  onSearchResultDismiss,
  onNavigation,
}) => {
  const [searchHits, setSearchHits] = useState([departmentTree]);

  const searchIndex = useMemo(() => {
    const map = {};
    let departments = [departmentTree];
    while (departments.length > 0) {
      const department = departments.pop();
      departments = departments.concat(department.departments);
      if (department.isRoot) {
        continue;
      }
      map[`${department.name.toLowerCase()} ${department.id}`] = department;
    }

    return map;
  }, [departmentTree]);

  useEffect(() => {
    if (searchTerm.trim().length === 0) {
      setSearchHits([]);
    } else {
      const newSearchHits = Object.entries(searchIndex)
        .filter(([key]) => key.includes(searchTerm))
        .map(([, value]) => value);
      setSearchHits(newSearchHits);
    }
  }, [searchTerm, searchIndex]);

  const handleBackdropClick = () => {
    onSearchResultDismiss && onSearchResultDismiss();
  };

  return (
    <div className={'search-result'}>
      <div className="search-result__list-wrapper">
        <div className="search-result__list">
          {searchHits.length === 0 && (
            <span className="search-result__no-hits">
              Din sökning gav inga resultat
            </span>
          )}
          {searchHits.map((hit) => (
            <DepartmentPickerSearchHit
              key={hit.id}
              department={hit}
              onSelect={(mod) =>
                onDepartmentSelect && onDepartmentSelect(hit, mod)
              }
              onNavigation={onNavigation}
            />
          ))}
        </div>
      </div>
      <div
        className="search-result__backdrop"
        onClick={handleBackdropClick}
      ></div>
    </div>
  );
};

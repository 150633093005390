import {
  AnalysisFilters,
  ApiService,
  DateRangeDepartmentRequest,
  DepartmentRequest,
  abstractIdsToDepartmentId,
} from './api-service';
import { Http, RoleName } from './http';
import { CacheRouteConfig, CachedHttp } from './http/cachedHttp';
import {
  AbsenceType,
  AbstractId,
  BasicPerson,
  DateOnly,
  RepeatedShortTermAbsence,
} from './types';

export interface RepeatedShortTermAbsenceRequest
  extends DateRangeDepartmentRequest {
  filter: AnalysisFilters | null;
}
export type AbsenceHistoryWeek = [
  number,
  number,
  number,
  number,
  number,
  number,
  number
];
export type AbsenceHistoryRequest = DepartmentRequest;
export type AbsenceHistoryResponse = {
  lastWeekAbsenceHistory: AbsenceHistoryWeek;
  lastYearAbsenceHistory: AbsenceHistoryWeek;
};

export type ReportCondition = {
  // TODO
  id: string;
  name: string;
  category: string;
};
export type ReportAbsence = {
  absenceStatus: number;
  absenceType: number; // TODO
  fromDate: string;
  id: string;
  calendarDays: number;
  daysInPeriod: number;
  previousAbsenceId: string;
  realReturnDate: string | null;
  toDate: string | null;
  workRelated: boolean;
  isStatusChange: boolean;
};
export type ReportDepartment = {
  id: string;
  name: string;
  parentDepartment: ReportDepartment;
};
export type ReportPerson = {
  employmentNumber: string;
  firstName: string;
  gender: string;
  id: string;
  lastName: string;
};
export type ReportResponse = {
  absence: ReportAbsence;
  department: ReportDepartment;
  person: ReportPerson;
};

export type AbsenceCategory = 'sickLeave' | 'vab' | 'workRelated';

export type AbsenceListRequest = DepartmentRequest & {
  absenceCategories: AbsenceCategory[];
  offset: number;
};

export type AbsenceListStatsRequest = DepartmentRequest & {
  absenceCategories: AbsenceCategory[];
};

export type Manager = {
  firstName: string;
  lastName: string;
};

export type AbsenceListEntry = {
  absenceId: string;
  absenceStatus: number;
  fromDate: Date;
  toDate: Date | null;
  realReturnDate: Date | null;
  conditionId: string;
  departmentName: string;
  person: BasicPerson | null;
  absenceType: AbsenceType;
  workRelated: boolean;
  needForSupport: boolean;
  chainNumberOfDays: number;
  managers: Manager[];
};

export type AbsenceListStatsResponse = {
  numberOfAbsences: number;
  numberOfPersons: number;
  menRatio: number;
  womenRatio: number;
};

const repeatedShortTermAbsencesRequestRoute = (
  endpoint: string
): CacheRouteConfig<RepeatedShortTermAbsenceRequest> => ({
  endpoint,
  generator: (
    obj: RepeatedShortTermAbsenceRequest,
    currentRole: RoleName | undefined
  ) =>
    JSON.stringify({
      role: currentRole || 'no-role',
      periodStart: obj.periodStart.toJSON(),
      periodEnd: obj.periodEnd.toJSON(),
      dataSet: [...obj.departmentIds].sort(),
      filter: obj.filter,
    }),
});

export class Absence extends ApiService {
  private cachedHttp: CachedHttp;

  constructor(http: Http) {
    super(http);
    this.cachedHttp = new CachedHttp(http, [
      {
        endpoint: '/absence/list-stats',
        generator: (obj, currentRole) => {
          return JSON.stringify({
            role: currentRole || 'no-role',
            dataSet: [...obj.departmentIds].sort(),
            absenceCategories: [...obj.absenceCategories].sort(),
          });
        },
      },
      {
        endpoint: '/absence/list',
        generator: (obj, currentRole) => {
          return JSON.stringify({
            role: currentRole || 'no-role',
            dataSet: [...obj.departmentIds].sort(),
            absenceCategories: [...obj.absenceCategories].sort(),
            offset: obj.offset,
          });
        },
      },
      repeatedShortTermAbsencesRequestRoute(
        '/absence/repeatedShortTermAbsences'
      ),
    ]);
  }

  public repeatedShortTermAbsences = async (
    list: AbstractId[],
    periodStart: DateOnly | Date,
    periodEnd: DateOnly | Date,
    analysisFilter: AnalysisFilters | null
  ): Promise<RepeatedShortTermAbsence[]> => {
    const departmentIds = abstractIdsToDepartmentId(list);
    const start = this.dateToDateOnly(periodStart);
    const end = this.dateToDateOnly(periodEnd);
    const filter =
      analysisFilter === null || analysisFilter.length === 0
        ? null
        : analysisFilter;

    return await this.cachedHttp.post<
      RepeatedShortTermAbsenceRequest,
      RepeatedShortTermAbsence[]
    >('/absence/repeatedShortTermAbsences', {
      departmentIds,
      periodStart: start,
      periodEnd: end,
      filter,
    });
  };

  public absenceHistory = async (
    list: AbstractId[]
  ): Promise<AbsenceHistoryResponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);

    return await this._http.post<AbsenceHistoryRequest, AbsenceHistoryResponse>(
      '/absence/absenceHistory',
      {
        departmentIds,
      }
    );
  };

  public report = async (
    list: AbstractId[],
    periodStart: DateOnly | Date,
    periodEnd: DateOnly | Date
  ): Promise<ReportResponse[]> => {
    const departmentIds = abstractIdsToDepartmentId(list);
    const start = this.dateToDateOnly(periodStart);
    const end = this.dateToDateOnly(periodEnd);

    return await this._http.post<DateRangeDepartmentRequest, ReportResponse[]>(
      '/absence/report',
      {
        departmentIds,
        periodStart: start,
        periodEnd: end,
      }
    );
  };

  public listStats = async (
    list: AbstractId[],
    absenceCategories: AbsenceCategory[]
  ): Promise<AbsenceListStatsResponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);
    return await this.cachedHttp.post<
      AbsenceListStatsRequest,
      AbsenceListStatsResponse
    >('/absence/list-stats', {
      absenceCategories,
      departmentIds,
    });
  };

  public list = async (
    list: AbstractId[],
    absenceCategories: AbsenceCategory[],
    offset: number
  ): Promise<AbsenceListEntry[]> => {
    const departmentIds = abstractIdsToDepartmentId(list);

    return await this.cachedHttp.post<AbsenceListRequest, AbsenceListEntry[]>(
      '/absence/list',
      {
        absenceCategories,
        departmentIds,
        offset,
      }
    );
  };
}

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import config from '../../config';
import {
  RehabPlanListColumns,
  useRehabPlanListRequest,
  useRehabPlanListStatsRequest,
} from '../../hooks/useRehabPlanListRequest';
import { useLocalStoredState } from '../../utils/hooks/local-stored-state';
import { InfiniteTable, RowIndex } from '../InfiniteTable';
import Loading from '../Loading';
import {
  asArray,
  reactChildrenText,
} from '../analysis/utils/absenceEventsFunctions';
import MaskedPersonLink from '../authorization/MaskedPersonLink';
import { ActionBlock } from '../ui/block/ActionBlock';
import { Block, BlockLayout } from '../ui/block/Block';
import { BlockSection, BlockSections } from '../ui/block/BlockSection';
import {
  IconSizes,
  ToolbarActionForm,
  ToolbarActionIcons,
  ToolbarCheckbox,
  ToolbarPopoutActionView,
  ToolbarRadio,
  ToolbarRadioGroup,
} from '../ui/button/ToolbarAction';
import { QueryError, QueryErrorMessage } from '../ui/misc/QueryError';
import { tableHeaderLocalized } from '../ui/table/utils';
import { RehabPlanDocumentLink } from './RehabPlanDocumentLink';
import { RehabPlanFileDocumentLink } from './RehabPlanFileDocumentLink';

const jsonToString = (obj) => JSON.stringify(obj);
const stringToByteArray = (str) => {
  const arr = new Uint16Array(str.length);
  for (let i = 0; i < arr.length; i++) {
    arr[i] = str.charCodeAt(i);
  }
  return arr;
};

const getRehabStatus = (entry) => {
  if (entry.archivedAt === null) {
    return {
      statusKey: 'ongoing',
      date: entry.rehabPlanCreatedAt,
    };
  } else {
    return {
      statusKey: 'concluded',
      date: entry.archivedAt,
    };
  }
};

const arrayToBase64 = (arr) => window.btoa(arr);
const _chain = (fns) => (initData) => fns.reduce((a, x) => x(a), initData);
const base64Json = _chain([jsonToString, stringToByteArray, arrayToBase64]);

const ToolbarExcelExportAction = ({ abstractIds, filterType }) => {
  const { t } = useTranslation();
  return (
    <ToolbarActionForm
      url={`${config.backend.endpoint}/rehabPlan/list/export`}
      icon={ToolbarActionIcons.ArrowDown}
      iconSize={IconSizes.Normal}
      title={t('StatisticsAnalysis.CategoriesAndConditions.exportButtonTitle')}
      values={[
        { name: 'departmentIds', value: abstractIds },
        {
          name: 'rehabListFilterType',
          value: filterType,
        },
        {
          name: 'i18n',
          value: base64Json({
            headers: {
              employee: t('RehabPlanList.columns.employee'),
              rehabStatus: t('RehabPlanList.columns.rehabStatus'),
              plannedMeasures: t('RehabPlanList.columns.plannedMeasures'),
              department: t('RehabPlanList.columns.department'),
              latestNote: t('RehabPlanList.columns.latestNote'),
            },
            excel: {
              filename: t('RehabPlanList.export.filename', {
                date: new Date(),
              }),
              sheetName: t('RehabPlanList.export.sheetName'),
            },
          }),
        },
      ]}
    />
  );
};

const ToolbarSettingsAction = ({ showHours, toggleHours }) => {
  const { t } = useTranslation();

  return (
    <ToolbarPopoutActionView
      icon={ToolbarActionIcons.Gear}
      title={t('common.list.showSettings')}
    >
      <div className="checkbox-toggle-list">
        <div className="checkbox-toggle checkbox-toggle-list__item">
          <ToolbarCheckbox
            value={showHours}
            text={t('common.chart.clock.show')}
            onChange={toggleHours}
          />
        </div>
      </div>
    </ToolbarPopoutActionView>
  );
};

const ToolbarFiltersAction = ({ filterType, filterChange }) => {
  const { t } = useTranslation();

  return (
    <ToolbarPopoutActionView
      icon={ToolbarActionIcons.Funnel}
      title={t('common.list.showFilters')}
    >
      <div className="checkbox-toggle-list">
        <ToolbarRadioGroup
          currentValue={filterType}
          onChange={(newValue) => filterChange(newValue)}
        >
          <div className="checkbox-toggle-list__item">
            <ToolbarRadio
              optionValue="concluded"
              text={t('RehabPlanList.filters.concluded')}
            />
          </div>
          <div className="checkbox-toggle-list__item">
            <ToolbarRadio
              optionValue="ongoing"
              text={t('RehabPlanList.filters.ongoing')}
            />
          </div>
          <div className="checkbox-toggle-list__item">
            <ToolbarRadio
              optionValue="plannedMeasures"
              text={t('RehabPlanList.filters.plannedMeasures')}
            />
          </div>
        </ToolbarRadioGroup>
      </div>
    </ToolbarPopoutActionView>
  );
};

/**
 * @param {import('../../api/rehabplan').RehabPlanListEntry[]} list
 * @param {(key: string) => string} t
 * @param {boolean} showHours
 * @returns
 */
const buildListRows = (list, t, showHours) => {
  const rows = [];
  const withDate = (date) => ({
    date,
    context: showHours ? 'datetime' : undefined,
  });

  for (const rehabPlanListEntry of list) {
    const rehabStatus = getRehabStatus(rehabPlanListEntry);

    let plannedMeasureElement = null;
    if (rehabPlanListEntry.plannedMeasuresDocument !== null) {
      const plannedMeasure = rehabPlanListEntry.plannedMeasuresDocument;

      plannedMeasureElement = (
        <RehabPlanDocumentLink
          personId={rehabPlanListEntry.person.person_id}
          documentId={plannedMeasure.id}
        >
          {t(`RehabPlanList.plannedMeasures.${plannedMeasure.type}`, {
            date: plannedMeasure.dateForFollowUp,
          })}
        </RehabPlanDocumentLink>
      );
    }

    rows.push({
      [RowIndex]: rehabPlanListEntry.id,
      [RehabPlanListColumns.employee]: (
        <MaskedPersonLink person={rehabPlanListEntry.person} />
      ),
      [RehabPlanListColumns.rehabStatus]: t(
        `RehabPlanList.rehabStatus.${rehabStatus.statusKey}`,
        withDate(rehabStatus.date)
      ),
      [RehabPlanListColumns.plannedMeasures]: plannedMeasureElement,
      [RehabPlanListColumns.department]: `${rehabPlanListEntry.departmentName}`,
      [RehabPlanListColumns.latestNote]:
        rehabPlanListEntry.latestDocument.type === 'file' ? (
          <RehabPlanFileDocumentLink
            personId={rehabPlanListEntry.person.person_id}
            documentId={rehabPlanListEntry.latestDocument.id}
            fileName={rehabPlanListEntry.fileDocument.fileName}
          >
            {t(
              `RehabPlanList.latestNote.${rehabPlanListEntry.latestDocument.type}`,
              withDate(rehabPlanListEntry.latestDocument.lastUpdated)
            )}
          </RehabPlanFileDocumentLink>
        ) : (
          <RehabPlanDocumentLink
            personId={rehabPlanListEntry.person.person_id}
            documentId={rehabPlanListEntry.latestDocument.id}
          >
            {t(
              `RehabPlanList.latestNote.${rehabPlanListEntry.latestDocument.type}`,
              withDate(rehabPlanListEntry.latestDocument.lastUpdated)
            )}
          </RehabPlanDocumentLink>
        ),
    });
  }

  return rows;
};

const buildListHeaders = () => {
  return asArray(
    tableHeaderLocalized(RehabPlanListColumns.employee, {
      rowClasses: 'linked-value name-value',
      asString: reactChildrenText,
      columnType: 'wide',
    }),
    tableHeaderLocalized(RehabPlanListColumns.rehabStatus, {
      asString: reactChildrenText,
      columnType: 'wide',
    }),
    tableHeaderLocalized(RehabPlanListColumns.plannedMeasures, {
      rowClasses: 'linked-value',
      asString: reactChildrenText,
      columnType: 'wide',
    }),
    tableHeaderLocalized(RehabPlanListColumns.department, {
      asString: reactChildrenText,
      columnType: 'wide',
    }),
    tableHeaderLocalized(RehabPlanListColumns.latestNote, {
      rowClasses: 'linked-value',
      asString: reactChildrenText,
      columnType: 'wide',
    })
  );
};

const RehabPlanListBlock = ({
  abstractIds,
  currentSort,
  filterType,
  numberOfRehabPlans,
  filterChange,
  accesses,
  onSortChange,
}) => {
  const { t } = useTranslation();

  const [showHours, setShowHours] = useLocalStoredState(
    'rehabPlan-list-show-hours',
    false
  );

  /** @type {import('../../api/rehabplan').RehabPlanListEntry[]} */
  const {
    rehabPlanList,
    isError,
    isLoadingInitial,
    isLoadingMore,
    truncated,
    removeTruncation,
    fetchMore,
  } = useRehabPlanListRequest(
    abstractIds,
    filterType,
    currentSort,
    numberOfRehabPlans
  );

  const toggleHours = () => {
    setShowHours(!showHours);
  };

  const blockTitle = (
    <Trans
      i18nKey="RehabPlanList.header"
      count={numberOfRehabPlans}
      context={filterType}
    >
      <span className="typography--primary">
        {{ count: numberOfRehabPlans }}
      </span>{' '}
      pågående rehabplaner
    </Trans>
  );

  const loadingMoreErrorMessage =
    isError && rehabPlanList.length > 0 ? t('RehabPlanList.error.more') : null;

  return (
    <>
      <ActionBlock
        layout={BlockLayout.Tight}
        blockTitle={blockTitle}
        actions={[
          <ToolbarFiltersAction
            filterType={filterType}
            filterChange={filterChange}
            key="filter-rehabPlan-list"
          />,
          <ToolbarSettingsAction
            showHours={showHours}
            toggleHours={toggleHours}
            key="settings-rehabPlan-list"
          />,
          <ToolbarExcelExportAction
            key="export-rehabPlan-list"
            abstractIds={abstractIds}
            filterType={filterType}
            accesses={accesses}
          />,
        ]}
      />
      <Block layout={BlockLayout.Tight}>
        <BlockSections>
          {numberOfRehabPlans > 0 && (
            <BlockSection>
              {isError ? (
                <QueryErrorMessage message={t('RehabPlanList.error.initial')} />
              ) : isLoadingInitial ? (
                <div className="placeholder-rehabPlan-list placeholder-rehabPlan-list--list">
                  <Loading>
                    <p>{t('RehabPlanList.loading.initial')}</p>
                  </Loading>
                </div>
              ) : (
                <InfiniteTable
                  headers={buildListHeaders()}
                  rows={buildListRows(rehabPlanList, t, showHours)}
                  numberOfRows={numberOfRehabPlans}
                  loadingMore={isLoadingMore}
                  loadingError={loadingMoreErrorMessage}
                  truncated={truncated}
                  currentSort={currentSort}
                  onSortChange={onSortChange}
                  onRemoveTruncation={() => removeTruncation()}
                  onLoadMoreData={() => fetchMore()}
                />
              )}
            </BlockSection>
          )}
        </BlockSections>
      </Block>
    </>
  );
};

const mapStateToProps = ({ dataSet, role }) => ({ dataSet, role });

export const RehabPlanList = connect(mapStateToProps)(({ dataSet, role }) => {
  const { t } = useTranslation();

  const [currentSort, setCurrentSort] = useState({
    key: RehabPlanListColumns.rehabStatus,
    ascending: false,
  });

  const [rehabPlanFilterType, setRehabPlanFilterType] = useLocalStoredState(
    'rehabPlan-filter-type',
    'ongoing'
  );

  const handleSortChange = (newSort) => {
    setCurrentSort(newSort);
  };

  const handleFilterChange = (changedFilterName) => {
    setRehabPlanFilterType(changedFilterName);
  };

  const {
    loading,
    isError,
    data: rehabPlanListStatsResult,
  } = useRehabPlanListStatsRequest(dataSet, rehabPlanFilterType);

  if (isError) {
    return <QueryError message={t('RehabPlanList.error.stats')} />;
  }

  if (loading) {
    const loadingMessage = t('RehabPlanList.loading.stats');
    return (
      <Block layout={BlockLayout.Tight}>
        <BlockSections>
          <BlockSection>
            <div className="placeholder-rehabPlan-list">
              <Loading slim={true}>
                <p>{loadingMessage}</p>
              </Loading>
            </div>
          </BlockSection>
        </BlockSections>
      </Block>
    );
  }

  const { numberOfRehabPlans } = rehabPlanListStatsResult;

  return (
    <RehabPlanListBlock
      abstractIds={dataSet}
      currentSort={currentSort}
      filterType={rehabPlanFilterType}
      numberOfRehabPlans={numberOfRehabPlans}
      filterChange={handleFilterChange}
      role={role}
      onSortChange={handleSortChange}
    />
  );
});

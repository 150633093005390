export const FEATURE_FORETAGSHALSAN = 'foretagshalsan';
export const FEATURE_REHABPLANERAREN = 'rehabplaneraren';
export const UNKNOWN_CONDITION_NAME = 'Okänd anledning';
export const UNKNOWN_CONDITION_CATEGORY_NAME = 'Okänd kategori';
export const SHORT_TERM_ABSENCE_UPPER_LIMIT = 14;
export const LOCAL_STORAGE_TOKEN_KEY = 'localAuthToken';
export const LOCAL_STORAGE_LAST_VISITED_DEPARTMENT_PATH_KEY =
  'LAST_VISITED_DEPARTMENT_PATH';
export const LOCAL_STORAGE_SELECTED_REPORT_COLUMNS_KEY =
  'LOCAL_STORAGE_SELECTED_REPORT_COLUMNS_KEY';
export const LOCAL_STORAGE_SELECTED_ROLE_KEY = 'ROLE';
export const LOCAL_STORAGE_SELECTED_LANG_KEY = 'LANG';
export const LOCAL_STORAGE_SELECTED_DATA_SET = 'selected-data-set';
export const ANALYSISFILTER_MAX_AGE = 71;
export const ANALYSISFILTER_MIN_AGE = 0;

export default {
  backend: {
    endpoint: process.env.STATTOOL_BACKEND_ENDPOINT,
  },
  file: {
    endpoint: process.env.STATTOOL_FILE_ENDPOINT,
    accept:
      'application/pdf,image/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxSize: 20971520,
  },
  localStorage: {
    tokenKey: LOCAL_STORAGE_TOKEN_KEY,
    lastVisitedDepartmentPathKey:
      LOCAL_STORAGE_LAST_VISITED_DEPARTMENT_PATH_KEY,
    selectedReportColumns: LOCAL_STORAGE_SELECTED_REPORT_COLUMNS_KEY,
    expiration: 60 * 60 * 1000,
    role: LOCAL_STORAGE_SELECTED_ROLE_KEY,
  },
  externalURIs: {
    previaHome: process.env.STATTOOL_HOME_URL,
    previaMinaSidor: process.env.STATTOOL_MY_PAGE_URL,
    userGuide: process.env.STATTOOL_USER_GUIDE_URL,
  },
  dateFormat: 'YYYY-MM-DD HH:mm:ss',
  unknownConditionName: UNKNOWN_CONDITION_NAME,
  shortTermAbsenceUpperLimit: SHORT_TERM_ABSENCE_UPPER_LIMIT,
  features: {
    foretagshalsan: FEATURE_FORETAGSHALSAN,
    rehabplaneraren: FEATURE_REHABPLANERAREN,
  },
  featureApi: {
    template: ['token-countdown'],
  },
  conditions: {
    categories: {
      reasonNotProvided: 'Orsak ej förmedlad',
    },
  },
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { api } from '@/api';

import {
  setConditionsPerConditionCategory,
  setLanguage,
  setRole,
  setUser,
} from '../../actions';
import config, {
  LOCAL_STORAGE_SELECTED_LANG_KEY,
  LOCAL_STORAGE_SELECTED_ROLE_KEY,
} from '../../config';
import { getKey } from '../../utils';
import i18next from '../../utils/i18n';
import Loading from '../Loading';

/**
 *
 * @param {import('client-api/dist/api/types').Condition[]} conditionList
 */
const buildConditionCategoryMap = (conditionList) => {
  const result = {};

  for (const entity of conditionList) {
    let conditions = result[entity.categoryName] || null;
    if (conditions == null) {
      conditions = [];
      result[entity.categoryName] = conditions;
    }

    conditions.push(entity.id);
  }

  return result;
};

const fixEmptyConditionCategory = (conditionCategoryMap) => {
  const hasKey = (object, key) =>
    Object.prototype.hasOwnProperty.call(object, key);

  const addPropertyIfMissing = (object, key, defaultValue = null) => {
    if (!hasKey(object, key)) {
      conditionCategoryMap[key] = defaultValue;
    }
  };

  // Issue https://dev.azure.com/FalckGlobalIT/Previa/_workitems/edit/205838
  // The conditions "Vill ej ange orsak" and "Övertaget ärende" should have
  // category "Orsak ej förmedlad". We remap all conditions without a
  // category as quick fix.

  const emptyCategoryKey = '';

  if (hasKey(conditionCategoryMap, emptyCategoryKey)) {
    const reasonNotProvidedCategoryName =
      config.conditions.categories.reasonNotProvided;

    addPropertyIfMissing(
      conditionCategoryMap,
      reasonNotProvidedCategoryName,
      []
    );

    conditionCategoryMap[reasonNotProvidedCategoryName].push(
      ...conditionCategoryMap[emptyCategoryKey]
    );

    delete conditionCategoryMap[emptyCategoryKey];
  }
};

export default function withInitData(BaseComponent) {
  const withInitDataSql = (props) => {
    const [initiated, setInitiated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (!initiated) {
        // Do this only once!
        setInitiated(true);

        setLoading(true);
        api.user
          .init()
          .then((data) => {
            props.setUser({
              person_name: data.user.personName,
              org_nr: data.user.organisationNumber,
              company: data.user.companyName,
              user_hash: data.user.userHash,
              roles: data.user.roles,
            });

            const conditionsCategoryMap = buildConditionCategoryMap(
              data.conditions
            );
            fixEmptyConditionCategory(conditionsCategoryMap);
            props.setConditionsPerConditionCategory(conditionsCategoryMap);

            const roleFromSessionStorage = getKey(
              LOCAL_STORAGE_SELECTED_ROLE_KEY
            );
            const roleToSet =
              roleFromSessionStorage &&
                data.user.roles.indexOf(roleFromSessionStorage) >= 0
                ? roleFromSessionStorage
                : data.user.roles[0];
            props.setRole(roleToSet);

            // get language and set from localstorage
            const langFromBrowser = getKey(LOCAL_STORAGE_SELECTED_LANG_KEY);
            if (
              langFromBrowser &&
              i18next.languages.indexOf(langFromBrowser) >= 0
            ) {
              props.setLanguage(langFromBrowser);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, []);

    if (loading) {
      return <Loading />;
    }

    return <BaseComponent {...props} />;
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      setUser: (data) => dispatch(setUser(data)),
      setConditionsPerConditionCategory: (data) =>
        dispatch(setConditionsPerConditionCategory(data)),
      setRole: (data) => dispatch(setRole(data)),
      setLanguage: (data) => dispatch(setLanguage(data)),
    };
  };

  return connect(null, mapDispatchToProps)(withInitDataSql);
}

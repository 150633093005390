import { abstractIdsToDepartmentId, ApiService, DepartmentRequest } from "./api-service";
import { PersonRequest } from "./person";
import { AbstractId } from "./types";

export type DepartmentNotification = {
  notificationId: string,
  notificationType: string,
  notificationTerm: number,
  notificationCount: number,
  personId: string,
  firstName: string | null,
  lastName: string | null,
};

export type DepartmentNotificationResponse = {
  workRelatedNotifications: DepartmentNotification[] | null,
  longTerm15Notifications: DepartmentNotification[] | null,
  longTerm91Notifications: DepartmentNotification[] | null,
  longTerm181Notifications: DepartmentNotification[] | null,
  repeatedShortTermNotifications: DepartmentNotification[] | null,
}

export type AbsenceReference = {
  id: string,
  fromDate: Date | null,
};

export type PersonNotification = {
  notificationId: string,
  type: string,
  created_timestamp: Date,
  term_length: number,
  absences: AbsenceReference[],
};

export type NotificationRequest = {
  id: string,
}

export class Notifications extends ApiService {
  department = async (list: AbstractId[]) => {
    const request = {
      departmentIds: abstractIdsToDepartmentId(list),
    };
    return await this._http.post<DepartmentRequest, DepartmentNotificationResponse>(
      '/notification/department',
      request);
  }

  person = async (personId: string) => {
    const request = { personId };
    return await this._http.post<PersonRequest, PersonNotification[]>(
      '/notification/person',
      request);
  }

  dismiss = async (notificationId: string) => {
    const request = { id: notificationId };
    return await this._http.post<NotificationRequest, unknown>(
      '/notification/dismiss',
      request);
  }
}

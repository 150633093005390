import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DepartmentPickerTop } from './DepartmentPickerTop';
import { DepartmentPickerMain } from './DepartmentPickerMain';
import { DepartmentPickerFooter } from './DepartmentPickerFooter';
import { DepartmentPickerDrawer } from './DepartmentPickerDrawer';
import { DepartmentPickerHelpSection } from './DepartmentPickerHelpSection';
import { DepartmentPickerCurrentSelection } from './DepartmentPickerCurrentSelection';
import { DepartmentPickerColumnMenuActions } from './DepartmentPickerColumnMenu';
import { Department } from '../../models/Department';
import { changeDataSet, closeDepartmentPicker } from '../../actions';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { close } from '../Icons';
import { compose } from 'redux';

const convertHierarchyToTree = (departmentHierarchy) => {
  const map = {};

  for (const department of departmentHierarchy) {
    const id = department.parent_id;
    if (!map[id]) {
      map[id] = [];
    }
    map[id].push(department);
  }

  return new Department(map[null][0], map);
};

const mapStateToProps = ({
  departmentHierarchy,
  departmentPicker,
  dataSet,
}) => ({ departmentHierarchy, departmentPicker, dataSet });
const mapDispatchToProps = (dispatch) => ({
  hideDepartmentPicker: (data) => dispatch(closeDepartmentPicker(data)),
  setDataSet: (data) => dispatch(changeDataSet(data)),
});

export const DepartmentPicker = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(
  ({
    departmentHierarchy,
    departmentPicker,
    setDataSet,
    hideDepartmentPicker,
    dataSet,
  }) => {
    if (!departmentHierarchy) {
      return null;
    }
    const { t } = useTranslation();

    const departmentTreeModel = useMemo(() => {
      return convertHierarchyToTree(departmentHierarchy);
    }, [departmentHierarchy]);

    const [departmentTree, setDepartmentTree] = useState(departmentTreeModel);
    const [selectedCount, setSelectedCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [helpSectionOpen, setHelpSectionOpen] = useState(false);
    const [currentSelectionOpen, setCurrentSelectionOpen] = useState(false);
    const [currentSelectionKey, setCurrentSelectionKey] = useState('');

    useEffect(() => {
      departmentTreeModel.deselectTree();
      departmentTreeModel.selectTreeIf((id) => dataSet.includes(id));
      setDepartmentTree(departmentTree);
      setSelectedCount(departmentTree.selectedDepartmentIds.length);
    }, [dataSet]);

    const handleDepartmentSelect = (department, mod) => {
      department.toggleSelected(mod);

      setDepartmentTree(departmentTree);
      setSelectedCount(departmentTree.selectedDepartmentIds.length);
    };

    const handleMenuAction = (action, department) => {
      switch (action) {
        case DepartmentPickerColumnMenuActions.SELECT_TREE:
          department.selectSubtree();
          break;
        case DepartmentPickerColumnMenuActions.SELECT:
          department.children.select(true);
          break;
        case DepartmentPickerColumnMenuActions.SELECT_SUB_TREE:
          department.children.selectSubtree(true);
          break;
        case DepartmentPickerColumnMenuActions.DESELECT_TREE:
          department.deselectSubtree();
          break;
        case DepartmentPickerColumnMenuActions.DESELECT:
          department.children.deselect();
          break;
        case DepartmentPickerColumnMenuActions.DESELECT_SUB_TREE:
          department.children.deselectSubtree();
          break;

        default:
          break;
      }
      setDepartmentTree(departmentTree);
      setSelectedCount(departmentTree.selectedDepartmentIds.length);
    };

    useEffect(() => {
      if (searchTerm.length > 0) {
        setShowSearchResult(true);
      } else {
        setShowSearchResult(false);
      }
    }, [searchTerm]);

    const handleSearchResultDismiss = () => {
      setShowSearchResult(false);
    };

    const handleKeyPresses = (event) => {
      if (event.key === 'Escape') {
        setShowSearchResult(false);
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleKeyPresses);

      return () => {
        document.removeEventListener('keydown', handleKeyPresses);
      };
    }, []);

    const handleShowHelpSection = () => {
      setHelpSectionOpen(true);
    };

    const handleShowCurrentSelection = () => {
      setCurrentSelectionKey('cache-' + Date.now());
      setCurrentSelectionOpen(true);
    };

    const handleEditCurrentSelection = (ids) => {
      departmentTree.refreshTree(ids);
      setCurrentSelectionOpen(false);
      setDepartmentTree(departmentTree);
      setSelectedCount(departmentTree.selectedDepartmentIds.length);
    };

    const handleChoiceSelected = () => {
      hideDepartmentPicker();
      setDataSet(departmentTree.selectedDepartmentIds);
    };

    const handleDeselectAll = () => {
      departmentTree.deselectSubtree();
      setDepartmentTree(departmentTree);
      setSelectedCount(departmentTree.selectedDepartmentIds.length);
    };

    const handleBackdropClick = () => {
      hideDepartmentPicker();
    };

    const handleCloseButtonClick = () => {
      hideDepartmentPicker();
    };

    const modalClasses = [
      'department-picker-modal',
      'department-picker-legacy',
    ];
    if (departmentPicker.open) {
      modalClasses.push('department-picker-modal--open');
    }

    useEffect(() => {
      if (departmentPicker.open) {
        const scrollBarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        document.body.classList.add('modal-open');
        document.body.style.paddingRight = `${scrollBarWidth}px`;
      } else {
        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = null;
      }
    }, [departmentPicker.open]);

    return !departmentPicker.open ? null : (
      <div className={modalClasses.join(' ')}>
        <div
          className="department-picker-modal__backdrop"
          onClick={handleBackdropClick}
        ></div>
        <div className="legacy-view-selector">
          <div className="department-picker-legacy__view-selector">
            <div className="legacy-view-selector__content legacy-view-picker">
              <button
                className={'legacy-view-picker__close'}
                onClick={handleCloseButtonClick}
              >
                {close}
              </button>
            </div>
          </div>
          <div className="department-picker-modal__picker new-department-picker">
            <DepartmentPickerTop setSearchTerm={setSearchTerm} />
            <DepartmentPickerMain
              departmentTree={departmentTree}
              searchTerm={searchTerm}
              showSearchResult={showSearchResult}
              onDepartmentSelect={handleDepartmentSelect}
              onMenuAction={handleMenuAction}
              onSearchResultDismiss={handleSearchResultDismiss}
            />
            <DepartmentPickerFooter
              selectedCount={selectedCount}
              onShowHelpSection={handleShowHelpSection}
              onShowCurrentSelection={handleShowCurrentSelection}
              onChoiceSelected={handleChoiceSelected}
              onDeselectAll={handleDeselectAll}
            />
            <DepartmentPickerDrawer
              title={t('DepartmentPicker.help.header')}
              open={helpSectionOpen}
              onClose={() => setHelpSectionOpen(false)}
            >
              <DepartmentPickerHelpSection />
            </DepartmentPickerDrawer>

            <DepartmentPickerCurrentSelection
              departmentTree={departmentTree}
              open={currentSelectionOpen}
              onClose={() => setCurrentSelectionOpen(false)}
              onEdit={handleEditCurrentSelection}
              cacheBust={currentSelectionKey}
            />
          </div>
        </div>
      </div>
    );
  }
);

import {
  AnalysisFilters,
  ApiService,
  DateRangeDepartmentRequest,
  DateRangeRequest,
  abstractIdsToDepartmentId,
} from './api-service';
import { Http, RoleName } from './http';
import {
  CacheRouteConfig,
  CachedHttp,
  dateRangeRequestRoute,
} from './http/cachedHttp';
import { AbstractId, DateOnly } from './types';

export type Conditions = {
  [key: number]: number;
};

export type DepartmentAnalysisResponse = {
  absences_per_employee: number;
  absent_days_per_absences: number;
  absent_days_per_employment: number;
  conditions: Conditions;
  condition_days: Conditions;
  employee_attendance_percentage: number;
  long_term_absences_percentage: number;
  short_term_absences_percentage: number;
  total_sickness_absences: number;
  total_sickness_absent_days: number;
  employee_count: number;
  total_employment_days: number;
  person_with_work_related_absence_count: number;
  total_absent_days: number;
  total_absences: number;
  total_care_of_child_absent_days: number;
  total_care_of_child_absences: number;
  sick_leave_percentage_per_month: number[];
  short_term_absence_percentage_per_month: number[];
  long_term_absence_percentage_per_month: number[];
  vab_percentage_per_month: number[];
  sick_leave_days_per_month: number[];
  short_term_absence_days_per_month: number[];
  long_term_absence_days_per_month: number[];
  vab_days_per_month: number[];
  sick_leave_count_per_month: number[];
  short_term_absence_count_per_month: number[];
  long_term_absence_count_per_month: number[];
  vab_count_per_month: number[];
};

export type GlobalAnalysisResponse = {
  globalStatsId: string;
  employeeAttendancePercentage: number;
  absentDaysPerEmployment: number;
  absentDaysPerAbsences: number;
  absencesPerEmployee: number;
  shortTermAbsencesPercentage: number;
  longTermAbsencesPercentag: number;
};

export interface DepartmentAnalysisRequest extends DateRangeDepartmentRequest {
  filter: AnalysisFilters | null;
}

const departmentAnalysisRequestRoute = (
  endpoint: string
): CacheRouteConfig<DepartmentAnalysisRequest> => ({
  endpoint,
  generator: (
    obj: DepartmentAnalysisRequest,
    currentRole: RoleName | undefined
  ) =>
    JSON.stringify({
      role: currentRole || 'no-role',
      periodStart: obj.periodStart.toJSON(),
      periodEnd: obj.periodEnd.toJSON(),
      dataSet: [...obj.departmentIds].sort(),
      filter: obj.filter,
    }),
});

export class Analysis extends ApiService {
  private cachedHttp: CachedHttp;

  constructor(http: Http) {
    super(http);
    this.cachedHttp = new CachedHttp(http, [
      departmentAnalysisRequestRoute('/stats/departments'),
      dateRangeRequestRoute('/stats/global'),
    ]);
  }

  departments = async (
    list: AbstractId[],
    periodStart: DateOnly | Date,
    periodEnd: DateOnly | Date,
    analysisFilter: AnalysisFilters | null
  ) => {
    const departmentIds = abstractIdsToDepartmentId(list);
    const start = this.dateToDateOnly(periodStart);
    const end = this.dateToDateOnly(periodEnd);
    const filter =
      analysisFilter === null || analysisFilter.length === 0
        ? null
        : analysisFilter;

    return await this.cachedHttp.post<
      DepartmentAnalysisRequest,
      DepartmentAnalysisResponse
    >('/stats/departments', {
      departmentIds,
      periodStart: start,
      periodEnd: end,
      filter,
    });
  };

  global = async (periodStart: DateOnly | Date, periodEnd: DateOnly | Date) => {
    const start = this.dateToDateOnly(periodStart);
    const end = this.dateToDateOnly(periodEnd);

    return await this.cachedHttp.post<
      DateRangeRequest,
      GlobalAnalysisResponse | null
    >(`/stats/global`, {
      periodStart: start,
      periodEnd: end,
    });
  };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setLanguage } from '../actions';

const LanguagePicker = ({ setLanguage }) => {
  const { t, i18n } = useTranslation();
  const toggleLanguage = () => setLanguage(i18n.languages[1]);

  return (
    <button className="language-picker" onClick={toggleLanguage}>
      {t('LanguagePicker.switch')}
    </button>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (data) => dispatch(setLanguage(data)),
});

export default connect(null, mapDispatchToProps)(LanguagePicker);

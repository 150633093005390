import React from 'react';
import classNames from 'classnames';

import { bemClasses } from '../utils/bem-classes';
import { BlockHeader } from './BlockHeader';

export const BlockLayout = {
  Tight: Symbol.for('BlockLayout.Tight'),
  Normal: Symbol.for('BlockLayout.Normal'),
};

/**
 * @typedef BlockParams
 * @property {React.ReactNode} children
 */

/**
 * @param {*} param0
 * @returns
 */
export const Block = ({
  blockTitle = null,
  actions = [],
  children = null,
  layout = BlockLayout.Normal,
}) => {
  const bem = bemClasses('block');

  return (
    <section
      className={classNames(bem.block(), {
        [bem.modifier('with-actions')]: actions.length > 0,
        [bem.modifier('tight')]: layout === BlockLayout.Tight,
      })}
    >
      {blockTitle && (
        <BlockHeader
          className={bem.element('header')}
          title={blockTitle}
          actions={actions}
        ></BlockHeader>
      )}
      <div className={bem.element('body')}>{children}</div>
    </section>
  );
};

import XLSX from 'xlsx';

const createColsObject = (width) => ({ wch: width });
const getCurrentLength = (obj) => obj.wch;
const updateColsObject = (obj, width) => (obj.wch = width);
const computeValueLength = (value) => (value ? value.toString().length : 0);

function fitToColumn(arrayOfArray) {
  const result = [];
  for (const array of arrayOfArray) {
    while (result.length < array.length) {
      result.push(createColsObject(0));
    }
    for (let i = 0; i < array.length; i++) {
      const valueLength = computeValueLength(array[i]);
      const currentLength = getCurrentLength(result[i]);
      if (valueLength > currentLength) {
        updateColsObject(result[i], valueLength);
      }
    }
  }
  return result;
}

/**
 * @param {string} name
 * @param {string} worksheetName
 * @param {any[] | null} meta
 * @param {any[]} headings
 * @param {any[][]} values
 * @param {(cellObject: any, cellAddress: any, worksheet: import('xlsx').WorkSheet) => void} [formatter]
 */
export const createAndDownloadWorkbook = (
  name,
  worksheetName,
  meta,
  headings,
  values,
  formatter
) => {
  const workbook = XLSX.utils.book_new();
  let worksheet;
  if (meta === null) {
    worksheet = XLSX.utils.aoa_to_sheet([headings]);
  } else {
    worksheet = XLSX.utils.aoa_to_sheet([...meta]);
    XLSX.utils.sheet_add_aoa(worksheet, [headings], { origin: -1 });
  }

  workbook.Props = { CreatedDate: new Date() };

  XLSX.utils.sheet_add_aoa(worksheet, values, { origin: -1 });
  worksheet['!cols'] = fitToColumn([
    ...(meta === null ? [] : meta),
    headings,
    ...values,
  ]);
  const range = XLSX.utils.decode_range(worksheet['!ref']);

  if (formatter) {
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        if (!worksheet[cellRef]) {
          continue;
        }
        const cellObject = worksheet[cellRef];
        formatter(cellObject, cellAddress, worksheet);
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);

  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export type FeatureName = string;
export type Config = { [key: string]: boolean };
export type PreviaFeature = { name: FeatureName; status: boolean };

export type RepositryItem = { [key: string]: boolean };
let repository: RepositryItem = {};
let inited = false;

const readConfig = (rawConfig: string): RepositryItem => {
  // TODO: validate
  return JSON.parse(rawConfig);
};

const loadConfig = (config: RepositryItem) => {
  repository = config;
  stateChanged();
};

const filterInitialRepository = (
  repository: RepositryItem,
  template: FeatureName[]
) => {
  const repositoryKeys = Object.keys(repository);
  for (const key of repositoryKeys) {
    if (!template.includes(key)) {
      delete repository[key];
    }
  }

  for (const templateKey of template) {
    if (!repositoryKeys.includes(templateKey)) {
      repository[templateKey] = false;
    }
  }
};

export const init = (
  template: FeatureName[],
  config: string | RepositryItem | null
) => {
  // throwIfInited();
  inited = true;
  config = typeof config === 'string' ? readConfig(config) : config;
  config = config ? config : {};
  filterInitialRepository(config, template);
  loadConfig(config);
};

export const toggle = (featureName: FeatureName): PreviaFeature[] => {
  // throwIfNotInited();

  const result: PreviaFeature[] = [];
  for (const key of Object.keys(repository)) {
    if (key.startsWith(featureName)) {
      repository[key] = !repository[key];
      result.push({ name: key, status: repository[key] });
    }
  }
  stateChanged();
  return result;
};

export const enabled = (featureName: FeatureName) => {
  // throwIfNotInited();

  if (repository.hasOwnProperty(featureName)) {
    return repository[featureName];
  }

  // TODO superkey matching
  return false;
};

export const list = (): PreviaFeature[] => {
  // throwIfNotInited();

  return Object.entries(repository).map(([key, value]) => ({
    name: key,
    status: value,
  }));
};

// Events
const stateChanged = () => {
  for (const listener of listeners) {
    listener({ ...repository });
  }
};

type StateChangeListener = (config: Config) => void;
const listeners: StateChangeListener[] = [];
export const onStateChange = (listener: StateChangeListener) => {
  listeners.push(listener);
};

// TODO deregister listener

import { FEATURE_FORETAGSHALSAN, FEATURE_REHABPLANERAREN } from '../config';

/**
 * @typedef Feature
 * @property {string} featureName
 * @property {number} status
 */

/**
 * @enum {string}
 */
export const ORG_FEATURES = Object.freeze({
  ForetagsHalsan: FEATURE_FORETAGSHALSAN,
  RehabPlaneraren: FEATURE_REHABPLANERAREN,
});

/**
 * @param {[Feature]} features
 * @param {ORG_FEATURES} feature
 * @returns {boolean}
 */
export const hasFeature = (features, feature) => {
  return features.some((f) => f.featureName === feature);
};

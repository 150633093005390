export const colorGrey = '#E2E2E2';
export const colorPreviaPeach = '#FDE0C5';
export const colorPreviaOrange = '#FD9539';
export const colorPreviaYellow = '#FDD439';
export const colorPreviaRed = '#FB3A37';
export const colorPreviaGreen = '#22AC93';
export const colorPreviaLightGreen = '#C1DBD7';
export const colorBlue = '#0047ff';
export const colorPreviaBlack = '#464646';
export const colorGreyDark = '#415565';
export const colorGreyLight = '#F9F9F9';

export const colorDarkRed = '#66182a';
export const colorRed = '#ff1e2d';
export const colorPaleRed = '#bca5ab';
export const colorCoolGrey = '#d1d4d3';
export const colorDarkGrey = '#415565';
export const colorWhite = '#f7f7f7';
export const colorWhitePure = '#fff';

export const colorInterfaceBg94 = '#F0F0F0';
export const colorInterfaceText = '#4D4D4D';

export const colorText = colorInterfaceText;

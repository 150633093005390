import React from 'react';
import { Link } from 'react-router-dom';

import { falckLogo } from './Icons';

const SiteFooter = () => {
  return (
    <footer className="site-footer">
      <div className="site-footer__content grid__container">
        <h1 className="site-footer__logo">
          <Link to="/">{falckLogo}</Link>
        </h1>

        <ul className="site-footer__navigation">
          {/* Add plain <li> tag to add link in footer */}
        </ul>
      </div>
    </footer>
  );
};
export default SiteFooter;

import classNames from 'classnames';
import React from 'react';

import { bemClasses } from '../utils/bem-classes';

export const ButtonV2 = ({
  children,
  className,
  type = 'primary',
  prefixElement = null,
  suffixElement = null,
  ...props
}) => {
  const bem = bemClasses('buttonv2');

  // TODO: validate type (when TS)

  return (
    <button
      {...props}
      title={children}
      className={classNames(className, bem.block(), bem.modifier(type))}
    >
      <span className={bem.element('prefix')}>{prefixElement}</span>
      <span className={bem.element('content')}>{children}</span>
      <span className={bem.element('suffix')}>{suffixElement}</span>
    </button>
  );
};

export const LoadingButton = ({
  children,
  isLoading = false,
  className,
  type = 'primary',
  prefixElement = null,
  suffixElement = null,
  ...props
}) => {
  const bem = bemClasses('buttonv2');

  return (
    <ButtonV2
      className={classNames(className, {
        [bem.modifier('loading')]: isLoading,
      })}
      type={type}
      prefixElement={prefixElement}
      suffixElement={suffixElement}
      {...props}
    >
      {children}
    </ButtonV2>
  );
};

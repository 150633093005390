import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close, dotMenu } from '../Icons';
import { DepartmentItem } from './DepartmentItem';
import { DepartmentPickerColumnMenu } from './DepartmentPickerColumnMenu';

const departmentSorter = (i18n) => (department1, department2) => {
  const name1 = department1.name || '';
  const name2 = department2.name || '';

  let compare = name1.localeCompare(name2, i18n.language || 'sv');
  if (compare === 0) {
    compare = (department1.id || '').localeCompare(department2.id || '');
  }

  return compare;
};

export const DepartmentPickerColumn = ({
  department,
  onDepartmentExpand,
  onDepartmentSelect,
  onMenuAction,
  selected,
  menuObserver,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleMenuAction = (action) => {
    setMenuOpen(false);
    onMenuAction && onMenuAction(action, department);
  };

  const handleToggleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      menuObserver.onMenuOpen();
      setMenuOpen(true);
    }
  };

  useEffect(() => {
    const callback = () => {
      setMenuOpen(false);
    };
    menuObserver.add(callback);

    return () => {
      menuObserver.remove(callback);
    };
  }, []);

  return (
    <div
      className={
        'nav-column nav-content__column' +
        (menuOpen ? ' nav-column--menu-open' : '')
      }
    >
      <div className="nav-column__head">
        <div className="nav-column__head-content">
          <span className="nav-column__header">{department.name}</span>
          <button
            className={
              'nav-column__menu-button nav-column-menu-button' +
              (menuOpen ? ' nav-column-menu-button--open' : '')
            }
            onClick={handleToggleMenu}
          >
            {menuOpen ? close : dotMenu}
          </button>
        </div>
      </div>
      <div
        className="nav-column__menu-backdrop"
        onClick={() => setMenuOpen(false)}
      ></div>
      <DepartmentPickerColumnMenu
        className="nav-column__menu"
        onAction={handleMenuAction}
      ></DepartmentPickerColumnMenu>
      <div className="nav-column__items">
        {department.departments.sort(departmentSorter(i18n)).map((child) => (
          <DepartmentItem
            key={child.id}
            department={child}
            expanded={selected === child.id}
            onExpand={() => onDepartmentExpand && onDepartmentExpand(child)}
            onSelect={(department, mod) =>
              onDepartmentSelect && onDepartmentSelect(department, mod)
            }
          />
        ))}
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import classNames from 'classnames';
import ReactAutocomplete from 'react-autocomplete';

import { search as searchIcon } from './Icons';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

class SearchEmployees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    this.formatEmployees(this.props.employees);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.employees) {
      this.formatEmployees(newProps.employees);
    }
  }

  formatEmployees(employees) {
    this.formattedEmployees = employees
      .map((employeeEntry) => ({
        key: employeeEntry.employment.employment_id,
        id: employeeEntry.person.person_id,
        name: `${
          employeeEntry.person.first_name &&
          employeeEntry.person.first_name.toLowerCase()
        } ${
          employeeEntry.person.last_name &&
          employeeEntry.person.last_name.toLowerCase()
        }`,
        department: {
          id: employeeEntry.department.abstract_id,
          name: employeeEntry.department.name,
        },
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  onClickIcon = () => {
    this.refs.input.focus();
  };

  input = (props) => (
    <div className="search-employees__input-wrapper">
      <input {...props} className="search-employees__input" />
      <div className="search-employees__icon" onClick={this.onClickIcon}>
        {searchIcon}
      </div>
    </div>
  );

  render() {
    const { className, t } = this.props;

    return (
      <div className={classNames('search-employees', className)}>
        <ReactAutocomplete
          ref="input"
          items={this.formattedEmployees}
          inputProps={{ placeholder: t('SearchEmployees.placeholder') }}
          wrapperStyle={{}}
          renderMenu={(items, value, style) => {
            if (value && items.length) {
              return (
                <div className="search-employees__list" children={items} />
              );
            } else if (value && !items.length) {
              return (
                <div className="search-employees__list search-employees__list--no-results">
                  <Trans i18nKey="SearchEmployees.noResult" values={{ value }}>
                    Inget sökresultat för{' '}
                    <span className="typography--font-weight-medium">
                      'value'
                    </span>
                  </Trans>
                </div>
              );
            } else {
              return <div />;
            }
          }}
          shouldItemRender={(item, value) =>
            item.name.indexOf(value.toLowerCase()) > -1
          }
          getItemValue={(item) => item.name}
          renderItem={(item, highlighted) => (
            <div
              className={`search-employees__list-item ${
                highlighted ? 'search-employees__highlighted' : ''
              }`}
              key={item.key}
            >
              {item.name}
              {
                <span className="search-employees__department">
                  {item.department.name}
                </span>
              }
            </div>
          )}
          value={this.state.value}
          onChange={(e) => this.setState({ value: e.target.value })}
          onSelect={(value, item) => {
            this.refs.input.blur();
            this.props.history.push(`/hr/person/${item.id}`);
            this.setState({ value: '' });
          }}
          renderInput={this.input}
        />
      </div>
    );
  }
}

export default compose(withTranslation(), withRouter)(SearchEmployees);

import React, { useMemo } from 'react';

import { DepartmentPickerCheckbox } from './DepartmentPickerCheckbox';

export const States = {
  IsExpandable: Symbol.for('IsExpandable'),
  Selected: Symbol.for('Selected'),
  Expanded: Symbol.for('Expanded'),
};

const StateClasses = {
  [States.IsExpandable]: 'nav-item--expandable',
  [States.Selected]: 'nav-item--selected',
  [States.Expanded]: 'nav-item--expanded',
};

const mapStateToClasses = (state) => StateClasses[state];

export const DepartmentItemView = ({
  department,
  states,
  subSelectCount,
  onSelect = null,
  onExpand = null,
}) => {
  const classes = useMemo(() => {
    return ['nav-item', 'nav-column__item', ...states.map(mapStateToClasses)];
  }, [states]);

  return (
    <div className={classes.join(' ')}>
      <div className="nav-item__content">
        {department.isAuthorized ? (
          <DepartmentPickerCheckbox
            className="nav-item__select"
            onSelect={onSelect}
            department={department}
          />
        ) : (
          <span className="nav-item__select"></span>
        )}
        <span className="nav-item__expand" data-js="name" onClick={onExpand}>
          <span className="nav-item__name">
            <span className="nav-item__name-content">{department.name}</span>
          </span>
          <span
            className={
              'nav-item__selected select-count' +
              (subSelectCount > 0 ? ' select-count--active' : '')
            }
            onClick={onExpand}
          >
            ({subSelectCount})
          </span>
          <span className="nav-item__expand-icon expand-icon">
            <svg
              className="expand-icon__svg"
              viewBox="0 0 4 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.73227e-07 5.50977C5.03962e-07 5.86133 0.410157 6.03711 0.664063 5.78321L3.16406 3.28321C3.32031 3.12696 3.32031 2.87305 3.16406 2.7168L0.664063 0.216802C0.410156 -0.0371047 3.67149e-09 0.138677 3.44061e-08 0.490239L4.73227e-07 5.50977Z"
                fill="#464646"
              />
            </svg>
          </span>
        </span>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import React from 'react';
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';

import { chevronDown } from './Icons';

const RolePicker = ({ t, roles, role, onChange }) => {
  return (
    <div className="role-picker">
      <span className="role-picker__label">{t('RolePicker.label')}</span>
      <Wrapper className="dropdown" onSelection={onChange}>
        <Button className="dropdown__placeholder">
          {t(`RolePicker.roles.${role}`)}
          <span className="dropdown__placeholder-arrow">{chevronDown}</span>
        </Button>
        <Menu tag="div" className="dropdown__menu dropdown__menu--small">
          {roles
            .slice()
            .reverse()
            .map((r) => (
              <MenuItem
                tag="div"
                key={r}
                value={r}
                className={classNames(
                  'dropdown__menu-item',
                  {
                    'dropdown__menu-item--is-selected': r === role,
                  },
                  {
                    'dropdown__menu-item--is-clickable': r !== role,
                  }
                )}
              >
                {t(`RolePicker.roles.${r}`)}
              </MenuItem>
            ))}
        </Menu>
      </Wrapper>
    </div>
  );
};

export default RolePicker;

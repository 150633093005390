import {
  DateRangeDepartmentRequest,
  DateRangeRequest,
  DepartmentRequest,
} from '../api-service';
import { Http, RoleName } from './http';

type CacheEntry = { value: any };
type PayloadCache = {
  keyGenerator: (obj: any, currentRole: RoleName | undefined) => string;
  values: { [key: string]: CacheEntry };
};
type RouteCache = { [key: string]: PayloadCache };

export type CacheRouteConfig<Request> = {
  endpoint: string;
  generator: (obj: Request, currentRole: RoleName | undefined) => string;
};

export class CachedHttp {
  private http: Http;
  private caches: RouteCache;

  constructor(http: Http, routes: CacheRouteConfig<any>[]) {
    this.http = http;
    this.caches = {};
    for (const route of routes) {
      this.caches[route.endpoint] = {
        keyGenerator: route.generator,
        values: {},
      };
    }
  }

  public post = async <T, Q>(
    endpoint: string,
    data: T,
    withRole: boolean = true
  ) => {
    const cache = this.caches[endpoint];
    const cacheKey = cache.keyGenerator(data, this.http.currentRole());
    let cachedValue = cache.values[cacheKey] || null;

    if (cachedValue === null) {
      const newResponse = await this.http.post<T, Q>(endpoint, data, withRole);
      cachedValue = {
        value: newResponse,
      };
      cache.values[cacheKey] = cachedValue;
    }

    return cachedValue.value;
  };
}

export const departmentRequestRoute = (
  endpoint: string
): CacheRouteConfig<DepartmentRequest> => ({
  endpoint,
  generator: (obj: DepartmentRequest, currentRole: RoleName | undefined) =>
    JSON.stringify({
      role: currentRole || 'no-role',
      dataSet: [...obj.departmentIds].sort(),
    }),
});

export const dateRangeDepartmentRequestRoute = (
  endpoint: string
): CacheRouteConfig<DateRangeDepartmentRequest> => ({
  endpoint,
  generator: (
    obj: DateRangeDepartmentRequest,
    currentRole: RoleName | undefined
  ) =>
    JSON.stringify({
      role: currentRole || 'no-role',
      periodStart: obj.periodStart.toJSON(),
      periodEnd: obj.periodEnd.toJSON(),
      dataSet: [...obj.departmentIds].sort(),
    }),
});

export const dateRangeRequestRoute = (
  endpoint: string
): CacheRouteConfig<DateRangeRequest> => ({
  endpoint,
  generator: (obj: DateRangeRequest, currentRole: RoleName | undefined) =>
    JSON.stringify({
      role: currentRole || 'no-role',
      periodStart: obj.periodStart.toJSON(),
      periodEnd: obj.periodEnd.toJSON(),
    }),
});

import { ApiService } from "./api-service";

export type LogLevel = 'debug' | 'info' | 'warning' | 'error';

export type FrontendLogEvent = {
  level: LogLevel,
  message: string,
  data?: { [key: string]: string } | null,
};

export class Logger extends ApiService {
  log = async (level: LogLevel, message: string, data?: { [key: string]: string } | null) => {
    return await this._http.post<FrontendLogEvent, string>(
      '/logger',
      {
        level,
        message,
        data,
      });
  }
}
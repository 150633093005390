import React from 'react';

import { RolesAuthorizedFor } from '@/common/roles-authorization';

import PersonLink from '../person/PersonLink';
import Masked from './Masked';

const MaskedPersonLink = ({ person }) => {
  return (
    <Masked access={RolesAuthorizedFor.Feature.PersonLink}>
      <PersonLink person={person} />
    </Masked>
  );
};

export default MaskedPersonLink;

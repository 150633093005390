import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonV2 } from '../ui/button/ButtonV2';
import { LinkButton } from '../ui/button/LinkButton';

export const DrawerOrientation = {
  Left: Symbol.for('DrawerOrientation.Left'),
  Right: Symbol.for('DrawerOrientation.Right'),
};

const OrientationModifierClass = {
  [DrawerOrientation.Left]: '',
  [DrawerOrientation.Right]: 'drawer--right',
};

export const ActionBarActionType = {
  Plain: Symbol.for('ActionBarActionType.Plain'),
  Primary: Symbol.for('ActionBarActionType.Primary'),
};

export const actionBarAction = (
  title,
  callback = () => { },
  type = ActionBarActionType.Plain
) => ({
  title,
  callback,
  type,
});

export const DrawerFooterAction = ({
  title,
  onClick,
  type = ActionBarActionType.Plain,
}) => {
  let button = null;

  if (type === ActionBarActionType.Plain) {
    button = (
      <LinkButton type="tertiary" onClick={onClick}>
        {title}
      </LinkButton>
    );
  } else {
    button = <ButtonV2 onClick={onClick}>{title}</ButtonV2>;
  }

  return <div className="action-bar__action">{button}</div>;
};

export const DrawerFooterActionDivider = () => {
  return <span className="action-bar__divider"></span>;
};

export const DrawerFooter = (props) => {
  return (
    <div className="drawer__footer">
      <div className="action-bar">{props.children}</div>
    </div>
  );
};

export const DepartmentPickerDrawer = (props) => {
  const {
    open,
    title,
    orientation = DrawerOrientation.Left,
    footer = null,
    onClose,
  } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  const blockClasses = ['drawer'];
  blockClasses.push(OrientationModifierClass[orientation]);

  return (
    <CSSTransition classNames="drawer--animation" in={open} timeout={300}>
      <div className={blockClasses.join(' ')}>
        <div className="drawer__container">
          <div className="drawer__header  drawer-header">
            {title && <span className="drawer-header__title">{title}</span>}
            <button
              className="drawer-header__close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="drawer__content">{props.children}</div>
          {footer}
        </div>
        <div className="drawer__backdrop" onClick={handleClose}></div>
      </div>
    </CSSTransition>
  );
};

import React from 'react';

const Modal = ({ children, onClick }) => (
  <div className="modal-wrapper" onClick={onClick}>
    <div className="modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal__inner">{children}</div>
    </div>
  </div>
);

export default Modal;

export const withModal = (BaseComponent) => {
  class withModal extends React.PureComponent {
    state = {
      modalOpen: false,
    };

    showModal = () => {
      this.setState({ modalOpen: true });
    };

    hideModal = () => {
      this.setState({ modalOpen: false });
    };

    render() {
      return (
        <BaseComponent
          showModal={this.showModal}
          hideModal={this.hideModal}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  return withModal;
};

import { abstractIdsToDepartmentId, ApiService, DepartmentRequest } from "./api-service"
import { Http } from "./http"
import { CachedHttp, departmentRequestRoute } from "./http/cachedHttp"
import { AbstractId, DepartmentId, Role } from "./types"

export type NavigationRequest = {
  departmentIds: DepartmentId[]
}

export type NavigationResponse = {
  activeEmployeesAtCompanyCount: number
  activeEmployeesCount: number
  companyNames: string[]
  employees: any[]
  numberOfCompanies: number
}

export enum FeatureStatus {
  Disabled = 0,
  PartiallyEnabled = 1,
  Enabled = 2,
}

export type OrganisationFeatureSupport = {
  featureName: string,
  status: FeatureStatus,
};

export type TodayResponse = {
  features: OrganisationFeatureSupport[],
};

export class Navigation extends ApiService {
  private cachedHttp: CachedHttp;

  constructor(http: Http) {
    super(http)
    this.cachedHttp = new CachedHttp(http, [
      departmentRequestRoute('/navigation'),
      departmentRequestRoute('/navigation/today'),
    ]);
  }

  execute = async (list: AbstractId[]): Promise<NavigationResponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);

    return await this.cachedHttp.post<NavigationRequest, NavigationResponse>('/navigation', {
      departmentIds,
    });
  }

  today = async (list: AbstractId[]): Promise<TodayResponse> => {
    const departmentIds = abstractIdsToDepartmentId(list);

    return await this.cachedHttp.post<DepartmentRequest, TodayResponse>('/navigation/today', {
      departmentIds,
    });
  }
}
import { Http } from './http/http';

export class ApiMetaData {
  private _http: Http;

  constructor(http: Http) {
    this._http = http;
  }

  get lastRequestTime() {
    return this._http.lastRequestTime;
  }
}

import { StatToolApi } from '../../api'
import { LogLevel } from '../../api/logger';

export const monitorUnhandledErrors = (api: StatToolApi, window: Window) => {
  const logger = new Logger(api);

  window.addEventListener('unhandledrejection', (event) => {
    const data: { [key: string]: string } = {};
    if (event.reason instanceof Error) {
      data['type'] = event.reason.constructor.name;
      data['message'] = event.reason.message;
      data['stack'] = event.reason.stack || 'N/A';
    } else {
      data['reason'] = `${event.reason}`;
    }

    logger.log('error', 'unhandled promise', data);
  });

  window.addEventListener('error', (event) => {
    const data = {
      errorMessage: event.message,
    };

    logger.log('error', 'unhandled promise', data);
  });
}

export class Logger {
  private _api: StatToolApi;
  private _errorCount: number;

  constructor(api: StatToolApi) {
    this._api = api;
    this._errorCount = 0;
  }

  public log = async (level: LogLevel, message: string, data?: { [key: string]: string } | null) => {
    if (this._errorCount >= 3) {
      return; // Lets not spam backend if it is not responsing
    }

    try {
      await this._api.logger.log(level, message, data);
    } catch (err) {
      console.error('Problem talking to backend', err);
      this._errorCount++;
    }
  }
}

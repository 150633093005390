import { features } from ".";
import { PreviaFeature } from "./repository";

const id = 'previa-features-ui';

const refreshFeatureList = (container: HTMLElement, document: Document) => {
  const featureList: PreviaFeature[] = features.list();

  const currentFeatures = container.querySelectorAll('[data-feature]');
  for (const featureElement of Array.from(currentFeatures)) {
    featureElement.remove();
  }

  for (const feature of featureList) {
    const node = document.createElement('label');
    const check = document.createElement('input');
    const text = document.createElement('span');
    node.appendChild(check);
    node.appendChild(text);

    node.setAttribute('data-feature', '');
    node.classList.add('previa-feature-toggle');

    if (feature.status) {
      check.setAttribute('checked', '');
    }
    check.setAttribute('type', 'checkbox');
    text.textContent = feature.name
    text.setAttribute('title', feature.name);

    check.addEventListener('input', (event) => {
      event.preventDefault();
      features.toggle(feature.name);
    });

    container.appendChild(node);
  }
}

const createUI = (document: Document) => {
  const container = document.createElement("div");
  container.classList.add('previa-features-ui');

  const toggle = document.createElement("button");
  toggle.textContent = '-';
  const title = document.createElement('h2');
  title.textContent = 'Feature toggles';
  container.appendChild(toggle);
  container.appendChild(title);

  toggle.addEventListener('click', () => {
    container.classList.toggle('previa-features-ui--closed');
    toggle.textContent = container.classList.contains('previa-features-ui--closed') ? '+' : '-';
  });

  refreshFeatureList(container, document);

  document.body.appendChild(container);
  return container
}

export const install = (window: Window) => {
  let node = window.document.querySelector(`#${id}`);

  if (node === null) {
    node = createUI(window.document);
  }

  features.onStateChange(() => {
    refreshFeatureList(node as HTMLElement, window.document);
  });
}

import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

import { api } from '@/api';

import { LOCAL_STORAGE_SELECTED_DATA_SET } from './config';
import reducers from './reducers';
import { createUserScopedKey, setSessionKey } from './utils';

const saveStoreDataSetToLocalStorage = (store) => {
  let previousDataSet = [];
  const hasDataSetChanged = (newDataSet) => {
    if (previousDataSet.length !== newDataSet.length) {
      return true;
    } else {
      const arr1 = previousDataSet.slice().sort();
      const arr2 = newDataSet.slice().sort();
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    }
  };

  const saveDataSet = (dataSet) => {
    const key = createUserScopedKey(LOCAL_STORAGE_SELECTED_DATA_SET);
    setSessionKey(key, (dataSet || []).join(','));
  };

  store.subscribe(() => {
    const { dataSet } = store.getState();
    if (dataSet.length > 0 && hasDataSetChanged(dataSet)) {
      saveDataSet(dataSet);
    }
    previousDataSet = dataSet;
  });
};

const syncRoleWithNewBackend = (store) => {
  let currentRole = null;

  store.subscribe(() => {
    const { role } = store.getState();
    if (role && currentRole !== role) {
      api.setRole(role);
      currentRole = role;
    }
  });
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(ReduxThunk))
);
saveStoreDataSetToLocalStorage(store);
syncRoleWithNewBackend(store);

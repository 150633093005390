/**
 * @typedef DepartmentBase
 * @property {string} abstract_id
 * @property {string} name
 * @property {number} employee_count
 * @property {string} org_nr
 */

/**
 * @typedef {DepartmentBase & { is_concern: boolean, is_subsidiary: boolean }} Department
 */

/**
 * @typedef {DepartmentBase & { subsidiaries: [DepartmentBase] }} Concern
 */

/**
 * @typedef {DepartmentBase & { concern: Concern? }} CompanyHierarchy
 */

/**
 * @typedef CompanyInfo
 * @property {string} companyName
 * @property {number} companyEmployeeCount
 */

/**
 * Extract the relevant company information from the company
 * hierarchy based on the given department
 * @param {Department} department
 * @param {CompanyHierarchy} companyInfo
 * @returns {CompanyInfo}
 */
export const getCompanyInfo = (department, companyInfo) => {
  const { is_concern, org_nr, root_org_nr } = department;
  const { concern } = companyInfo;
  const isPartOfSubsidiary = org_nr !== root_org_nr;

  const result = {
    companyName: '',
    companyEmployeeCount: 0,
  };

  if (is_concern) {
    if (concern) {
      result.companyName = concern.name;
      result.companyEmployeeCount = concern.employee_count;
    } else {
      console.warn(
        'Viewing concern but did not find its data in the response',
        companyInfo
      );
    }
  } else if (isPartOfSubsidiary) {
    const { subsidiaries = [] } = concern;
    const { org_nr } = department;

    const subsidiary = subsidiaries.find((s) => s.org_nr === org_nr);
    if (subsidiary) {
      result.companyEmployeeCount = subsidiary.employee_count;
      result.companyName = subsidiary.name;
    } else {
      console.warn(
        'Viewing subsidiary but did not find its data in the response',
        companyInfo
      );
    }
  } else {
    result.companyEmployeeCount = companyInfo.employee_count;
    result.companyName = companyInfo.name;
  }

  return result;
};

export const prettyList = (data = [], t) => {
  const firstPart = data.slice(0, data.length - 1);
  const finalPart = data.slice(data.length - 1);
  if (firstPart.length > 0) {
    return `${firstPart.join(', ')} ${t('common.and')} ${finalPart}`;
  } else {
    return finalPart;
  }
};

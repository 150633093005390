import { install as installCLI } from './console-cli';
import { install as installUI } from './ui';
import { enabled, FeatureName, init, list, onStateChange, toggle } from './repository'

export interface PreviaFeaturesConfig {
  template: FeatureName[]
}

export const features = {
  enabled,
  toggle,
  list,

  init,

  installCLI: installCLI,
  installUI: installUI,

  onStateChange,
};

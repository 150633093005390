import { ApiService } from './api-service';
import { JWTToken } from './token';
import { Condition, Role } from './types';

export declare type UserDetails = {
  companyName: string;
  personName: string;
  organisationNumber: string;
  roles: Role[];
  userHash: string;
};

export declare type InitResponse = {
  conditions: Condition[];
  user: UserDetails;
};

export type TokenResponse = {
  token: string;
};

export class User extends ApiService {
  init = async () => {
    return await this._http.get<InitResponse>('/user/init', false);
  };

  tokenRefresh = async () => {
    const tokenResponse = await this._http.get<TokenResponse>(
      '/token/refresh',
      false
    );
    const token = new JWTToken(tokenResponse.token);
    this._http.setToken(token);
  };
}

import { api } from '@/api';

import { LOCAL_STORAGE_SELECTED_DATA_SET } from './config';
import { createUserScopedKey, getSessionKey } from './utils';

export const SET_USER = 'SET_USER';
export const SET_CONDITIONS_PER_CONDITION_CATEGORY =
  'SET_CONDITIONS_PER_CONDITION_CATEGORY';
export const CHANGE_DATE = 'CHANGE_DATE';
export const CHANGE_DEPARTMENT = 'CHANGE_DEPARTMENT';
export const CHANGE_DATASET = 'CHANGE_DATASET';
export const CHANGE_PERIOD = 'CHANGE_PERIOD';
export const SET_ROLE = 'SET_ROLE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_DEPARTMENT_HIERARCHY = 'SET_DEPARTMENT_HIERARCHY';
export const OPEN_DEPARTMENT_PICKER = 'OPEN_DEPARTMENT_PICKER';
export const CLOSE_DEPARTMENT_PICKER = 'CLOSE_DEPARTMENT_PICKER';
export const OPEN_TOKEN_MODAL = 'OPEN_TOKEN_MODAL';
export const CLOSE_TOKEN_MODAL = 'CLOSE_TOKEN_MODAL';

export function openTokenModal() {
  return {
    type: OPEN_TOKEN_MODAL,
  };
}

export function closeTokenModal() {
  return {
    type: CLOSE_TOKEN_MODAL,
  };
}

export function setUser(data) {
  return {
    type: SET_USER,
    payload: data,
  };
}

export function setConditionsPerConditionCategory(data) {
  return {
    type: SET_CONDITIONS_PER_CONDITION_CATEGORY,
    payload: data,
  };
}

export function changeDataSet(dataSet) {
  return {
    type: CHANGE_DATASET,
    payload: dataSet,
  };
}

export function setRole(role) {
  return async (dispatch) => {
    dispatch({ type: SET_ROLE, payload: role });
    dispatch(updateDepartmentHierachy());
  };
}

export function updateDepartmentHierachy() {
  return async (dispatch) => {
    dispatch({ type: SET_DEPARTMENT_HIERARCHY, payload: null });
    dispatch({ type: CHANGE_DATASET, payload: [] });

    try {
      let response;
      response = await api.department.hierarchy();

      dispatch({ type: SET_DEPARTMENT_HIERARCHY, payload: response });
      const fetchStoredDataSet = () => {
        const key = createUserScopedKey(LOCAL_STORAGE_SELECTED_DATA_SET);
        const storedValue = getSessionKey(key);
        let stored = [];
        if (storedValue !== null) {
          try {
            stored = storedValue.split(',');
          } catch (error) {
            api.logger.log(
              'warning',
              `Failed to get stored data set. Message: ${error.message}`
            );
          }
        }

        const companyIdFixer = (item) => {
          return item.replace(/^[cd]/, '');
        };

        return stored.map(companyIdFixer);
      };
      const storedDataSet = fetchStoredDataSet();
      const authorizedAbstractIds = response
        .filter((department) => department.authorized)
        .filter((department) => department.parent_id !== null)
        .map((department) => department.abstract_id);
      if (storedDataSet.length === 0) {
        dispatch({
          type: CHANGE_DATASET,
          payload: authorizedAbstractIds,
        });
      } else {
        const authorizedStoredDataSet = storedDataSet.filter((abstractId) =>
          authorizedAbstractIds.includes(abstractId)
        );
        if (authorizedStoredDataSet.length === 0) {
          dispatch({ type: CHANGE_DATASET, payload: authorizedAbstractIds });
        } else {
          dispatch({ type: CHANGE_DATASET, payload: authorizedStoredDataSet });
        }
      }
    } catch (err) {
      console.error(
        'Error when fetching department hierarchy from new backend',
        err
      );
    }
  };
}

export function changePeriod(period) {
  return { type: CHANGE_PERIOD, payload: period };
}

export function setLanguage(language) {
  return { type: SET_LANGUAGE, payload: language };
}

export function openDepartmentPicker() {
  return { type: OPEN_DEPARTMENT_PICKER };
}

export function closeDepartmentPicker() {
  return { type: CLOSE_DEPARTMENT_PICKER };
}

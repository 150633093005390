import React from 'react';
import classNames from 'classnames';
import { bemClasses } from '../utils/bem-classes';

import { BlockHeader } from './BlockHeader';
import { BlockSection, BlockSections } from './BlockSection';
import { BlockLayout } from './Block';

export const ActionBlock = ({
  blockTitle,
  subBlockTitle = null,
  actions = [],
  layout = BlockLayout.Normal,
}) => {
  const bem = bemClasses('block');

  return (
    <section
      className={classNames(bem.block(), bem.modifier('actions-only'), {
        [bem.modifier('tight')]: layout === BlockLayout.Tight,
      })}
    >
      <BlockHeader className="block__header" actions={actions}></BlockHeader>
      <BlockSections>
        <BlockSection title={blockTitle} subTitle={subBlockTitle} />
      </BlockSections>
    </section>
  );
};

import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import { chevronUp } from './Icons';
import { InlineLoading } from './Loading';
import { QueryErrorMessage } from './ui/misc/QueryError';
import { updateTableSort } from './ui/table/utils';
import { bemClasses } from './ui/utils/bem-classes';

export const RowIndex = Symbol('RowIndex');
export const ValueType = {
  String: Symbol('ValueType.String'),
  Number: Symbol('ValueType.Number'),
};

// eslint-disable-next-line prettier/prettier
const noop = () => { };

export const tableHeader = (key, title, options = {}) => ({
  ...options,
  key,
  title,
});
export const tableHeaderLocalized = (localizationKey, options = {}) => {
  return (t) =>
    tableHeader(
      localizationKey,
      localizationKey === null ? '' : t(localizationKey),
      options
    );
};
export class TableSectionHeaderRow {
  constructor(title) {
    this.title = title;
  }
}

const dataRows = (rows) =>
  rows.filter((row) => !(row instanceof TableSectionHeaderRow));

/**
 * @typedef TableHeaderCellProps
 * @property {import('./ui/table').TableSort | null} currentSort
 * @property {(newSort: import('./ui/table').TableSort) => void | null} onSortChange
 */

const bemHeaderCell = bemClasses('standard-table-cell');

/**
 * @param {TableHeaderCellProps} param0
 */
const TableHeaderCell = ({
  currentSort = null,
  onSortChange = null,
  header,
}) => {
  const sortingEnabled = currentSort !== null;
  let textElement = header.title;
  let sortClasses = null;
  let sortAria = {};

  if (sortingEnabled) {
    sortClasses = {
      [bemHeaderCell.modifier('sorting')]: true,
      [bemHeaderCell.modifier('current-sort-order')]:
        header.key === currentSort.key,
      [bemHeaderCell.modifier('current-sort-descending')]:
        header.key === currentSort.key && !currentSort.ascending,
    };

    if (header.key === currentSort.key) {
      sortAria = {
        'aria-sort': currentSort.ascending ? 'ascending' : 'descending',
      };
    }

    const clickHandler = () => {
      if (onSortChange !== null) {
        onSortChange(updateTableSort(currentSort, header.key));
      }
    };

    textElement = (
      <button
        className="standard-table-cell__sort-button"
        onClick={clickHandler}
      >
        {header.title}
      </button>
    );
  }

  const classses = classNames(
    bemHeaderCell.block(),
    bemHeaderCell.modifier(header.columnType),
    sortClasses
  );

  return (
    <th className={classses} {...sortAria}>
      <div className="standard-table__sortable-heading-label">
        {textElement}
      </div>
    </th>
  );
};

const TableRow = ({ row, headers }) => {
  if (row instanceof TableSectionHeaderRow) {
    return (
      <tr>
        <th
          colSpan={headers.length}
          className={'standard-table__inline-header'}
        >
          {row.title}
        </th>
      </tr>
    );
  }

  return (
    <tr>
      {headers.map((header, index) => (
        <td
          className={classNames(
            header.rowClasses,
            'standard-table-cell',
            `standard-table-cell--${header.columnType}`
          )}
          key={`${index}_${row[header.key]}`}
        >
          {row[header.key]}
        </td>
      ))}
    </tr>
  );
};

let intersectionObserver = null;
let intersectionObserverCallback = null;

const createLoadMoreIntersectionObserver = () => {
  intersectionObserver = new IntersectionObserver(
    (entries) => {
      if (intersectionObserverCallback !== null) {
        intersectionObserverCallback(entries);
      }
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }
  );
};
createLoadMoreIntersectionObserver();

/**
 * @typedef InfiniteTableProps
 * @property {import('./ui/table').TableSort | null} currentSort
 * @property {(newSort: import('./ui/table').TableSort) => void | null} onSortChange
 */

/**
 * @param {InfiniteTableProps} param0
 * @returns
 */
export const InfiniteTable = ({
  headers,
  rows,
  numberOfRows,
  truncated = false,
  loadingMore = false,
  loadingError = null,
  bottomRightContent = null,
  currentSort = null,
  onSortChange = null,
  onRemoveTruncation = noop,
  onLoadMoreData = noop,
}) => {
  const { t } = useTranslation();

  const [bottomVisible, setBottomVisible] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const loadMoreElementRef = useRef(null);
  const tableBottomRef = useRef(null);
  const tableRef = useRef(null);
  const tableRef2 = useRef(null); // TODO
  const scrollTopRef = useRef(null);

  const loadedDataRows = dataRows(rows).length;
  const canLoadMore = numberOfRows > loadedDataRows;

  const parsedHeaders = useMemo(() => {
    return headers.map((header) =>
      typeof header === 'function' ? header(t) : header
    );
  }, [headers]);

  intersectionObserverCallback = (entries) => {
    for (const entry of entries) {
      if (entry.target === loadMoreElementRef.current) {
        if (entry.isIntersecting) {
          onLoadMoreData();
          setBottomVisible(true);
        } else {
          setBottomVisible(false);
        }
      } else if (entry.target === tableRef.current) {
        if (!entry.isIntersecting && entry.boundingClientRect.y < 0) {
          setShowScrollToTop(true);
        } else {
          setShowScrollToTop(false);
        }
      } else if (entry.target === tableBottomRef.current) {
        setBottomVisible(
          entry.isIntersecting || entry.boundingClientRect.y < 0
        );
      }
    }
  };

  // #region Event handlers
  const handleScrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onShowMore = () => {
    onRemoveTruncation();
  };
  // #endregion

  // #region Intersection connect / disconnect
  useEffect(() => {
    if (tableRef.current) {
      intersectionObserver.observe(tableRef.current);
    }

    return () => {
      if (tableRef.current) {
        intersectionObserver.unobserve(tableRef.current);
      }
    };
  }, [tableRef]);

  useEffect(() => {
    if (loadMoreElementRef.current) {
      intersectionObserver.observe(loadMoreElementRef.current);
    }

    return () => {
      if (loadMoreElementRef.current) {
        intersectionObserver.unobserve(loadMoreElementRef.current);
      }
    };
  }, [loadMoreElementRef.current]);

  useEffect(() => {
    if (tableBottomRef.current) {
      intersectionObserver.observe(tableBottomRef.current);
    }

    return () => {
      if (tableBottomRef.current) {
        intersectionObserver.unobserve(tableBottomRef.current);
      }
    };
  }, [tableBottomRef]);
  // #endregion

  // #region Position scroll top button
  useEffect(() => {
    if (showScrollToTop) {
      const computeNewButtonLeft = () => {
        const rect = tableRef2.current.getBoundingClientRect();
        const { width: buttonWidth } =
          scrollTopRef.current.getBoundingClientRect();
        let newButtonLeft = rect.left + rect.width;
        if (newButtonLeft + buttonWidth + 60 > window.innerWidth) {
          newButtonLeft = window.innerWidth - buttonWidth - 60;
        }
        return newButtonLeft;
      };

      scrollTopRef.current.style.left = `${computeNewButtonLeft()}px`;

      const handleScrollTopResize = () => {
        scrollTopRef.current.style.left = `${computeNewButtonLeft()}px`;
      };
      window.addEventListener('resize', handleScrollTopResize);

      return () => {
        window.removeEventListener('resize', handleScrollTopResize);
      };
    }
  }, [showScrollToTop, scrollTopRef]);
  // #endregion

  return (
    <div className="infinite-table-wrapper">
      <div
        className="standard-table-wrapper statistics-table-wrapper"
        ref={tableRef2}
      >
        <span ref={tableRef} style={{ position: 'absolute' }}></span>
        <div className="statistics-table-wrapper__scroll-area">
          <table className="standard-table standard-table--infinite">
            <thead>
              <tr>
                {parsedHeaders.map((header, index) => (
                  <TableHeaderCell
                    header={header}
                    key={`${index}_${header.title}`}
                    currentSort={currentSort}
                    onSortChange={onSortChange}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <TableRow
                  key={row[RowIndex] || index}
                  row={row}
                  headers={parsedHeaders}
                />
              ))}
            </tbody>
          </table>
        </div>
        <button
          className={
            'infinite-table-scroll-top ' +
            (bottomVisible ? 'infinite-table-scroll-top--at-bottom' : '')
          }
          title={t('common.list.scrollToTop')}
          hidden={!showScrollToTop}
          ref={scrollTopRef}
          onClick={handleScrollToTop}
        >
          {chevronUp}
        </button>
      </div>
      {!truncated && !loadingMore && canLoadMore && loadingError === null && (
        <span ref={loadMoreElementRef}>HIDDEN ELEMENT</span>
      )}
      <span ref={tableBottomRef}></span>
      <div className="infinite-table-footer">
        {!truncated && loadingMore && (
          <InlineLoading message={t('common.list.loadingMore')} />
        )}
        {loadingError !== null && <QueryErrorMessage message={loadingError} />}
        {truncated && (
          <Button
            className="infinite-table-footer__show-more-button"
            type="submit"
            theme="secondary"
            size="small"
            arrow="down"
            onClick={onShowMore}
          >
            {t('common.list.showMore', {
              remaining: numberOfRows - loadedDataRows,
            })}
          </Button>
        )}
        {bottomRightContent && (
          <div className="infinite-table-footer__right-content">
            {bottomRightContent}
          </div>
        )}
      </div>
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import config from '../config';
import { falckLogo } from './Icons';
import LanguagePicker from './LanguagePicker';

const PersonInfo = ({ user }) => {
  if (user.personName) {
    return (
      <>
        {user.personName}
        &nbsp;&ndash;&nbsp;
        {user.company}
      </>
    );
  } else {
    return <>{user.company || ''}</>;
  }
};

const SiteHeader = ({ user }) => {
  const { t } = useTranslation();

  return (
    <header className="site-header grid__container">
      <h1 className="site-header__logo">
        <Link to="/">{falckLogo}</Link>
      </h1>

      <div className="site-header__content">
        {user && (
          <div className="site-header__user-identity ellipsis">
            <PersonInfo user={user} />
          </div>
        )}

        <ul className="site-header__navigation">
          <li>
            <a
              href={config.externalURIs.userGuide}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('SiteHeader.userGuide')}
            </a>
          </li>
          <li>
            <Link to="/logout">{t('SiteHeader.logout')}</Link>
          </li>
          <li>
            <a href={config.externalURIs.previaHome}>{t('SiteHeader.home')}</a>
          </li>
          <li>
            <LanguagePicker />
          </li>
        </ul>
      </div>
    </header>
  );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(SiteHeader);

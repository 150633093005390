import React from 'react';

export type RadioGroupContextValue = {
  currentValue: String;
  groupName: String;
  onChange: (value: string) => void;
};

export const RadioGroupContext = React.createContext<RadioGroupContextValue>({
  groupName: '',
  currentValue: '',
  onChange: () => {},
});

import { createUserScopedKey, removeKey, removeSessionKey } from '../utils';
import config, {
  LOCAL_STORAGE_SELECTED_DATA_SET,
  LOCAL_STORAGE_SELECTED_LANG_KEY,
  LOCAL_STORAGE_SELECTED_ROLE_KEY,
} from './../config';

const { tokenKey } = config.localStorage;

export const logout = () => {
  removeKey(tokenKey);
  removeKey(LOCAL_STORAGE_SELECTED_ROLE_KEY);
  removeKey(LOCAL_STORAGE_SELECTED_LANG_KEY);

  const dataSetKey = createUserScopedKey(LOCAL_STORAGE_SELECTED_DATA_SET);
  removeSessionKey(dataSetKey);

  window.location.href = config.externalURIs.previaMinaSidor;
};

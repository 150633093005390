import { useEffect, useMemo, useReducer, useState } from 'react';

import { api } from '@/api';

export const RehabPlanListColumns = {
  employee: 'RehabPlanList.columns.employee',
  rehabStatus: 'RehabPlanList.columns.rehabStatus',
  plannedMeasures: 'RehabPlanList.columns.plannedMeasures',
  department: 'RehabPlanList.columns.department',
  latestNote: 'RehabPlanList.columns.latestNote',
};

const sortKeyMapping = {
  [RehabPlanListColumns.department]: 'department',
  [RehabPlanListColumns.employee]: 'employee',
  [RehabPlanListColumns.latestNote]: 'latestNote',
  [RehabPlanListColumns.plannedMeasures]: 'plannedMeasures',
  [RehabPlanListColumns.rehabStatus]: 'rehabPlanStatus',
};

/**
 * @typedef RehabPlanListStatsRequestdata
 * @property {boolean} loading
 * @property {boolean} isError
 * @property {import('../api/rehabplan').RehabPlanListStatsResponse} data
 */

/**
 * @param {import('../api/types').AbstractId[]} dataSet
 * @param {import('../api/rehabplan').RehabPlanFilterType} rehabPlanListFilterType
 * @returns {RehabPlanListStatsRequestdata}
 */
export const useRehabPlanListStatsRequest = (
  dataSet,
  rehabPlanListFilterType
) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    let unmounted = false;

    setLoading(true);
    setIsError(false);
    api.rehabPlan
      .listStats(dataSet, rehabPlanListFilterType)
      .then((data) => {
        if (!unmounted) {
          setData(data);
        }
      })
      .catch(() => {
        if (!unmounted) {
          setIsError(true);
        }
      })
      .finally(() => {
        if (!unmounted) {
          setLoading(false);
        }
      });

    return () => {
      unmounted = true;
    };
  }, [dataSet, rehabPlanListFilterType]);

  return {
    loading,
    isError,
    data,
  };
};

const TRUNCATE_LIMIT = 10;
/**
 * @param {import('../api/types').AbstractId[]} dataSet
 * @param {import('../api/rehabplan').RehabPlanFilterType} rehabPlanListFilterType
 * @param {import('../ui/table').TableSort} sort
 * @param {number} numberOfRehabPlans
 * @returns {RehabPlanListStatsRequestdata}
 */
export const useRehabPlanListRequest = (
  dataSet,
  rehabPlanListFilterType,
  sort,
  numberOfRehabPlans
) => {
  const [data, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'append':
          return {
            ...state,
            isLoadingInitial: false,
            isLoadingMore: false,
            rehabPlanList: [...state.rehabPlanList, ...action.payload],
          };
        case 'error':
          return {
            ...state,
            isError: true,
            isLoadingInitial: false,
            isLoadingMore: false,
            rehabPlanList: [],
          };
        case 'reset':
          return {
            rehabPlanList: [],
            isError: false,
            isLoadingInitial: true,
            isLoadingMore: false,
            truncated: TRUNCATE_LIMIT < numberOfRehabPlans,
          };
        case 'load-more':
          return {
            ...state,
            isLoadingMore: true,
            truncated: false,
          };
        default:
        // Making the linter happy
      }
    },
    {
      rehabPlanList: [],
      isLoadingInitial: true,
      isLoadingMore: false,
      isError: false,
      truncated: true,
    }
  );

  const convertedSort = useMemo(() => {
    const column = sortKeyMapping[sort.key] || null;

    return {
      column,
      ascending: sort.ascending,
    };
  }, [sort]);

  const fetchData = (offset) => {
    api.rehabPlan
      .list(dataSet, rehabPlanListFilterType, convertedSort, offset)
      .then((data) => {
        dispatch({ type: 'append', payload: data });
      })
      .catch(() => {
        dispatch({ type: 'error' });
      });
  };

  useEffect(() => {
    dispatch({ type: 'reset' });
    fetchData(0);
  }, [dataSet, convertedSort]);

  const fetchMore = () => {
    dispatch({ type: 'load-more' });
    fetchData(data.rehabPlanList.length);
  };

  const removeTruncation = () => {
    fetchMore();
  };

  return {
    rehabPlanList: data.rehabPlanList,
    isError: data.isError,
    isLoadingInitial: data.isLoadingInitial,
    isLoadingMore: data.isLoadingMore,
    truncated: data.truncated,

    // Functions
    removeTruncation,
    fetchMore,
  };
};

import React from 'react';
import classNames from 'classnames';
import { Tooltip as ReactTippy } from 'react-tippy';

const Tooltip = ({
  children,
  distance = 25,
  tooltipContent,
  tooltipTitle,
  tooltipType,
  tooltipClassNames,
  ...props
}) => (
  <ReactTippy
    distance={distance}
    arrow
    html={
      <div
        className={classNames(
          'tooltip',
          { [`tooltip--${tooltipType}`]: tooltipType },
          tooltipClassNames
        )}
      >
        {tooltipTitle && <div className="tooltip__title">{tooltipTitle}</div>}

        {tooltipContent}
      </div>
    }
    {...props}
  >
    {children}
  </ReactTippy>
);

export default Tooltip;

import React from 'react';
import classNames from 'classnames';

import { bemClasses } from '../utils/bem-classes';

/**
 * @typedef BlockSectionsParams
 * @property {React.ReactNode} children
 */

/**
 * @param {BlockSectionsParams} param0
 */
export const BlockSections = ({ children }) => {
  return <div className="block-section-list">{children}</div>;
};

/**
 * @typedef BlockSectionParams
 * @property {React.ReactNode} [children]
 * @property {string?} [title]
 * @property {string?} [subTitle]
 */

/**
 *
 * @param {BlockSectionParams} param0
 */
export const BlockSection = ({
  title = null,
  subTitle = null,
  children = null,
}) => {
  const bem = bemClasses('block-section');
  const headerless = !title && !subTitle;

  return (
    <div
      className={classNames(
        bem.block(),
        headerless ? bem.modifier('headerless') : null
      )}
    >
      {title && <h3 className={bem.element('title')}>{title}</h3>}
      {subTitle && <h4 className={bem.element('sub-title')}>{subTitle}</h4>}
      {children && <div className={bem.element('body')}>{children}</div>}
    </div>
  );
};

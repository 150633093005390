import React from 'react';

import { SadFace } from '../../Icons';
import { Block, BlockLayout } from '../block/Block';
import { BlockSection, BlockSections } from '../block/BlockSection';

export const QueryErrorMessage = ({ message }) => {
  return (
    <div className="data-loading-error">
      <span className="data-loading-error__icon">
        <SadFace size={30} />
      </span>
      <span className="data-loading-error__text">{message}</span>
    </div>
  );
};

export const QueryError = ({ message }) => {
  return (
    <Block layout={BlockLayout.Tight}>
      <BlockSections>
        <BlockSection>
          <QueryErrorMessage message={message} />
        </BlockSection>
      </BlockSections>
    </Block>
  );
};

import classNames from 'classnames';
import React, { Component } from 'react';

const SHOW_LOADING_SPINNER_FOR_MIN_MS = 500;
const SHOW_POST_LOADING_CONFIRMATION_DURATION_MS = 850;

class Button extends Component {
  constructor(props) {
    super(props);

    this.hideLoadingSpinnerTimeout = undefined;
    this.hidePostLoadingConfirmationTimeout = undefined;
    this.showLoadingSpinnerStartTimestamp = undefined;

    this.state = {
      showLoadingSpinner: false,
      showLoadingSpinnerStartTimestamp: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading, postLoadingConfirmationContent } = this.props;
    const { loading: prevLoading } = prevProps;

    if (!prevLoading && loading) {
      clearTimeout(this.hideLoadingSpinnerTimeout);
      clearTimeout(this.hidePostLoadingConfirmationTimeout);

      this.showLoadingSpinnerStartTimestamp = +new Date();

      this.setState({
        showLoadingSpinner: true,
        showPostLoadingConfirmation: false,
      });
    }

    if (prevLoading && !loading) {
      const nowTimestamp = +new Date();
      const loadingSpinnerVisibleForMs =
        nowTimestamp - this.showLoadingSpinnerStartTimestamp;

      this.hideLoadingSpinnerTimeout = setTimeout(() => {
        this.setState(
          {
            showLoadingSpinner: false,
            showPostLoadingConfirmation: !!postLoadingConfirmationContent,
          },
          () => {
            if (postLoadingConfirmationContent) {
              this.hidePostLoadingConfirmationTimeout = setTimeout(
                () =>
                  this.setState({
                    showPostLoadingConfirmation: false,
                  }),
                SHOW_POST_LOADING_CONFIRMATION_DURATION_MS
              );
            }
          }
        );
      }, Math.max(0, SHOW_LOADING_SPINNER_FOR_MIN_MS - loadingSpinnerVisibleForMs));
    }
  }

  render() {
    const {
      children,
      className,
      theme = 'primary',
      type = 'button',
      alignment = 'center',
      borderColor,
      arrow,
      size,
      onClick,
      postLoadingConfirmationContent,
      // eslint-disable-next-line no-unused-vars
      loading, // Extracted so it is not included in restProps
      ...restProps
    } = this.props;

    const { showLoadingSpinner, showPostLoadingConfirmation } = this.state;
    const buttonIsInteractable =
      !showLoadingSpinner && !showPostLoadingConfirmation;

    return (
      <button
        className={classNames(
          'button',
          `button--${theme}`,
          `button--alignment-${alignment}`,
          { [`button--size-${size}`]: size },
          { 'button--is-loading': showLoadingSpinner },
          {
            'button--show-post-loading-confirmation':
              showPostLoadingConfirmation,
          },
          { 'button--with-arrow': arrow },
          { [`button--arrow-${arrow}`]: arrow },
          { [`button--borderColor-${borderColor}`]: borderColor },
          className
        )}
        type={type}
        onClick={(event) => {
          if (buttonIsInteractable) {
            onClick && onClick(event);
          } else if (!buttonIsInteractable && type === 'submit') {
            event.preventDefault();
          }
        }}
        {...restProps}
      >
        <div className="button__content">{children}</div>

        {postLoadingConfirmationContent && (
          <div className="button__post-loading-confirmation-content">
            {postLoadingConfirmationContent}
          </div>
        )}

        <div className="button__loading-spinner" />
      </button>
    );
  }
}

export default Button;

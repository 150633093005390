import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionBarActionType,
  DepartmentPickerDrawer,
  DrawerFooter,
  DrawerFooterAction,
  DrawerFooterActionDivider,
  DrawerOrientation,
} from './DepartmentPickerDrawer';
import {
  DepartmentPickerThreeStateCheckbox,
  CheckboxStates,
} from './DepartmentPickerThreeStateCheckbox';

const constructNode = (department) => ({
  id: department.id,
  name: department.name,
  selected: department.isSelected,
  authorized: department.isAuthorized,
  departments: [],
});

const findSelectedNodes = (department) => {
  if (department.isLeaf) {
    if (department.isSelected) {
      return constructNode(department);
    } else {
      return null;
    }
  } else {
    const tempNode = constructNode(department);
    const selectedChildren = department.departments
      .map((d) => findSelectedNodes(d, tempNode))
      .filter((d) => d !== null);

    if (selectedChildren.length > 0 || tempNode.selected) {
      tempNode.departments = selectedChildren;
      return tempNode;
    } else {
      return null;
    }
  }
};

const computeMinimalSelectedTree = (departmentTree) => {
  if (!departmentTree) {
    return null;
  }
  const result = findSelectedNodes(departmentTree);

  return result;
};

const departmentSorter = (i18n) => (department1, department2) => {
  const name1 = department1.name || '';
  const name2 = department2.name || '';

  let compare = name1.localeCompare(name2, i18n.language || 'sv');
  if (compare === 0) {
    compare = (department1.id || '').localeCompare(department2.id || '');
  }

  return compare;
};

const TreeLevel = ({ department, onClick }) => {
  const listChildrenIfPresent = (list) => {
    if (list.length === 0) {
      return null;
    } else {
      return (
        <div className="selection-tree-level__children">
          {list.sort(departmentSorter).map((childDepartment) => (
            <TreeLevel
              key={childDepartment.id}
              department={childDepartment}
              onClick={onClick}
            />
          ))}
        </div>
      );
    }
  };

  const handleClick = () => {
    department.selected = !department.selected;
    onClick();
  };

  return (
    <div className="selection-tree-level">
      <div
        className={
          'selection-tree-level__item tree-level-item' +
          (!department.authorized && ' tree-level-item--not-authorized')
        }
      >
        {department.authorized ? (
          <DepartmentPickerThreeStateCheckbox
            className="tree-level-item__check"
            state={
              department.selected
                ? CheckboxStates.PARTIALLY_SELECTED
                : CheckboxStates.NOT_SELECTED
            }
            onSelect={handleClick}
          />
        ) : (
          <span className="tree-level-item__check"></span>
        )}
        <span className="tree-level-item__name">{department.name}</span>
      </div>
      {listChildrenIfPresent(department.departments)}
    </div>
  );
};

const deslectAll = (departments) => {
  for (const department of departments) {
    department.selected = false;
    deslectAll(department.departments);
  }
};

const collectCurrentSelected = (departments) => {
  let result = [];
  for (const department of departments) {
    if (department.selected) {
      result.push(department.id);
    }
    const subids = collectCurrentSelected(department.departments);
    result = result.concat(subids);
  }
  return result;
};

export const DepartmentPickerCurrentSelection = ({
  departmentTree,
  open,
  onClose,
  onEdit,
  cacheBust = '',
}) => {
  const [roots, setRoots] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const tree = computeMinimalSelectedTree(departmentTree);

    if (tree === null) {
      setRoots([]);
    } else {
      setRoots(tree.departments);
    }
  }, [cacheBust]);

  const handleClick = () => {
    // Hack to force re-render
    setRoots(roots.slice());
  };

  const handleDeselectAll = () => {
    deslectAll(roots);
    setRoots(roots.slice());
  };

  const handleCancel = () => {
    onClose();
    setRoots([]);
  };

  const handleEdit = () => {
    const ids = collectCurrentSelected(roots);
    onEdit && onEdit(ids);
  };

  const drawerFooter = () => {
    return (
      <DrawerFooter>
        <DrawerFooterAction
          title={t(
            'DepartmentPicker.selectedDepartments.deselectAllActionText'
          )}
          onClick={handleDeselectAll}
        />
        <DrawerFooterActionDivider />
        <DrawerFooterAction
          title={t('DepartmentPicker.selectedDepartments.cancelActionText')}
          onClick={handleCancel}
        />
        <DrawerFooterAction
          title={t('DepartmentPicker.selectedDepartments.saveActionText')}
          type={ActionBarActionType.Primary}
          onClick={handleEdit}
        />
      </DrawerFooter>
    );
  };

  return (
    <DepartmentPickerDrawer
      open={open}
      title={t('DepartmentPicker.selectedDepartments.header')}
      orientation={DrawerOrientation.Right}
      footer={drawerFooter()}
      onClose={onClose}
    >
      <DepartmentPickerCurrentSelectionView
        roots={roots}
        onClick={handleClick}
      />
    </DepartmentPickerDrawer>
  );
};

export const DepartmentPickerCurrentSelectionView = ({ roots, onClick }) => {
  return (
    <div className={'current-selection'}>
      {roots.sort(departmentSorter).map((root) => (
        <div key={root.id} className="current-selection__section">
          <TreeLevel department={root} onClick={onClick} />
        </div>
      ))}
    </div>
  );
};

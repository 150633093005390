import { ApiService } from "./api-service";
import { DepartmentHierachyEntry } from "./types";

export type DepartmentHierachyResponse = DepartmentHierachyEntry[]

export class Department extends ApiService {
  hierarchy = async () => {
    return await this._http.get<DepartmentHierachyResponse>('/department/hierarchy');
  }
}

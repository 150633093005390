import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import ErrorPage from '../ErrorPage';
import { hasAccessTo } from '@/common/roles-authorization';

const AuthorizedRoute = ({ component: Component, page, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccessTo(page, role) ? (
          <Component {...props} />
        ) : (
          <ErrorPage statusCode={403} />
        )
      }
    />
  );
};

const mapStateToProps = ({ role }) => ({ role });

export default connect(mapStateToProps)(AuthorizedRoute);

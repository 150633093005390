import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

const PersonLink = ({ person, t }) => {
  const isCurrentEmployee = person && person.person_id;
  if (isCurrentEmployee) {
    return (
      <Link to={`/hr/person/${person.person_id}`} className="typography--name">
        {person.first_name.toLowerCase()} {person.last_name.toLowerCase()}
      </Link>
    );
  } else {
    return t('common.previousEmployee');
  }
};

export default compose(withTranslation())(PersonLink);

import { ApiService, DepartmentRequest } from './api-service';
import { Http } from './http';
import { CachedHttp } from './http/cachedHttp';
import {
  BasicPerson,
  BasicRehabPlan,
  DateOnly,
  DepartmentId,
  RehabPlanDocument,
} from './types';

export interface RehabPlansByPersonIdRequest {
  personId: string;
}

export interface RehabPlanDocumentByDocumentIdRequest {
  documentId: string;
}

export type RehabPlanFilterType = 'ongoing' | 'concluded' | 'plannedMeasure';
export type RehabPlanDocumentType =
  | 'file'
  | 'note'
  | 'planForReturnToWork'
  | 'wellnessCheck';
export type RehabPlanSortColumn =
  | 'department'
  | 'employee'
  | 'latestNote'
  | 'latestUpdate'
  | 'plannedMeasures'
  | 'rehabPlanStatus';

export interface RehabPlanDocumentUpdateRequest {
  documentId: string;
  content: string;
}

export interface CreateRehabPlanWithDocumentRequest {
  documentType: string;
  personId: string;
}

export type CreateRehabPlanWithDocumentFileRequest = {
  [rehabPlanDocumentFile: string]: File;
};

export interface DeleteRehabPlanRequest {
  rehabPlanId: string;
}

export interface CreateRehabPlanDocumentRequest {
  rehabPlanId: string;
  type: string;
}

export type CreateRehabPlanDocumentFileRequest = {
  [rehabPlanDocumentFile: string]: File;
};

export interface DeleteRehabPlanDocumentRequest {
  documentId: string;
}

export interface ToggleRehabPlanArchiveRequest {
  rehabPlanId: string;
}

export type RehabPlanResponse = [BasicRehabPlan];

export type RehabPlanDocumentResponse = [RehabPlanDocument];

export type RehabPlansListRequestSorting = {
  ascending: boolean;
  column: RehabPlanSortColumn;
};

interface RehabPlansListRequest extends DepartmentRequest {
  rehabFilterType: RehabPlanFilterType;
  sorting: RehabPlansListRequestSorting;
  offset?: number;
}

export type LatestRehabDocument = {
  id: string;
  type: RehabPlanDocumentType;
  lastUpdated: Date;
};

export type PlannedMeasuresDocument = {
  id: string;
  type: RehabPlanDocumentType;
  dateForFollowUp: DateOnly;
};

export type FileDocument = {
  id: string;
  type: RehabPlanDocumentType;
  fileName: string;
};

export type RehabPlanListEntry = {
  id: string;
  rehabPlanCreatedAt: Date;
  person: BasicPerson | null;
  departmentName: string;
  latestDocument: LatestRehabDocument;
  plannedMeasuresDocument: PlannedMeasuresDocument;
  fileDocument: FileDocument;
};

export interface RehabPlanListStatsRequest extends DepartmentRequest {
  rehabFilterType: RehabPlanFilterType;
}

export type RehabPlanListStatsResponse = {
  numberOfRehabPlans: number;
};

export class RehabPLan extends ApiService {
  private cachedHttp: CachedHttp;

  constructor(http: Http) {
    super(http);
    this.cachedHttp = new CachedHttp(http, [
      {
        endpoint: '/rehabplan/list-stats',
        generator: (obj, currentRole) => {
          return JSON.stringify({
            role: currentRole || 'no-role',
            dataSet: [...obj.departmentIds].sort(),
            rehabFilterType: obj.rehabFilterType,
          });
        },
      },
      {
        endpoint: '/rehabplan/list',
        generator: (obj, currentRole) => {
          return JSON.stringify({
            role: currentRole || 'no-role',
            dataSet: [...obj.departmentIds].sort(),
            rehabFilterType: obj.rehabFilterType,
            offset: obj.offset,
            sorting: obj.sorting,
          });
        },
      },
    ]);
  }

  public listStats = async (
    departmentIds: DepartmentId[],
    rehabFilterType: RehabPlanFilterType
  ): Promise<RehabPlanListStatsResponse> => {
    return await this._http.post<
      RehabPlanListStatsRequest,
      RehabPlanListStatsResponse
    >('/rehabplan/list-stats', {
      rehabFilterType,
      departmentIds,
    });
  };

  public list = async (
    departmentIds: DepartmentId[],
    rehabFilterType: RehabPlanFilterType,
    sorting: RehabPlansListRequestSorting,
    offset: number
  ): Promise<RehabPlanListEntry[]> => {
    return await this._http.post<RehabPlansListRequest, RehabPlanListEntry[]>(
      '/rehabplan/list',
      {
        rehabFilterType,
        departmentIds,
        sorting,
        offset,
      }
    );
  };
}

import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { compose } from 'redux';

import { RolesAuthorizedFor } from '@/common/roles-authorization';

import AsyncCounseling from './components/AsyncCounseling';
import AsyncHrAnalysis from './components/AsyncHrAnalysis';
import AsyncPerson from './components/AsyncPerson';
import AsyncToday from './components/AsyncToday';
import { DepartmentPicker } from './components/DepartmentPicker2/DepartmentPicker';
import ErrorPage from './components/ErrorPage';
import Logout from './components/Logout';
import SiteFooter from './components/SiteFooter';
import SiteHeader from './components/SiteHeader';
import TokenCountdown from './components/TokenCountdown';
import TokenModal from './components/TokenModal';
import AsyncReport from './components/async/AsyncReport';
import AuthorizedRoute from './components/authorization/AuthorizedRoute';
import withInitData from './components/hoc/withInitData';
import { withHrLayout } from './components/hoc/withLayout';
import AsyncRehabPlanDocumentForm from './components/rehab-plan/AsyncRehabPlanDocumentForm';
import { RehabPlansLayout } from './components/rehab-plan/RehabPlansLayout';
import { DomContext } from './contexts/dom-context';
import {
  OrganisationFeaturesContext,
  useOrganisationFeatures,
} from './contexts/organisation-features-context';

/** @type {import('./contexts/dom-context').DomContextValue} */
const domContextValue = {
  window: window,
};

export const App = ({ dataSet }) => {
  const organisationFeaturesData = useOrganisationFeatures(dataSet);

  return (
    <Router>
      <SiteHeader />

      <main className="main">
        <TokenCountdown />
        <TokenModal />
        <DomContext.Provider value={domContextValue}>
          <OrganisationFeaturesContext.Provider
            value={organisationFeaturesData}
          >
            <Switch>
              <Redirect exact path="/" to="/hr" />

              <Route exact path="/hr" component={withHrLayout(AsyncToday)} />
              <Route
                exact
                path="/hr/analysis"
                component={withHrLayout(AsyncHrAnalysis)}
              />
              <Route
                exact
                path="/hr/report"
                component={withHrLayout(AsyncReport)}
              />
              <Route
                exact
                path="/rehab-plans"
                component={withHrLayout(RehabPlansLayout)}
              />
              <Redirect exact path="/hr/:abstractId" to="/hr" />
              <Redirect
                exact
                path="/hr/:abstractId/analysis"
                to="/hr/analysis"
              />
              <Redirect exact path="/hr/:abstractId/report" to="/hr/report" />

              <Redirect exact path="/department/:abstractId" to="/hr" />
              <Redirect
                exact
                path="/department/:abstractId/analysis"
                to="/hr/analysis"
              />
              <Redirect
                exact
                path="/department/:abstractId/report"
                to="/hr/report"
              />
              <Redirect
                exact
                path="/department/:abstractId/person/:personId"
                to="/hr/person/:personId"
              />

              <AuthorizedRoute
                exact
                path="/hr/person/:personId"
                component={withHrLayout(AsyncPerson)}
                page={RolesAuthorizedFor.Area.Person.$Page}
              />

              <Route
                exact
                path={['/hr/person/:personId/document/:rehabPlanDocumentId']}
                component={AsyncRehabPlanDocumentForm}
              />

              <Route path="/samtalsstod" component={AsyncCounseling} />
              <Route path="/logout" component={Logout} />
              <Route path="/error/:statusCode?" component={ErrorPage} />
              <Route component={() => <ErrorPage statusCode={404} />} />
            </Switch>
          </OrganisationFeaturesContext.Provider>
        </DomContext.Provider>

        <DepartmentPicker />
      </main>

      <SiteFooter />
    </Router>
  );
};

const mapStateToProps = ({ dataSet }) => ({
  dataSet,
});

export default compose(withInitData, connect(mapStateToProps))(App);

import React from 'react';
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation';

const HrNavigation = () => {
  const { t } = useTranslation();

  return (
    <Navigation heading={t('common.navigation.generalStatisticsHeader')} />
  );
};

export default HrNavigation;

import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import config from '../config';
import ErrorMessage from './ErrorMessage';

const ServerError = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h1 className="headline--large">{t('ErrorPage.server.headline')}</h1>
      <p className="typography--medium">{t('ErrorPage.server.first')}</p>
    </Fragment>
  );
};

const ForbiddenError = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h1 className="headline--large">{t('ErrorPage.forbidden.headline')}</h1>
      <p className="typography--medium">
        <Trans i18nKey="ErrorPage.forbidden.first">
          <a href={config.externalURIs.previaMinaSidor}>Klicka här</a> för logga
          in via Mina sidor
        </Trans>
      </p>
    </Fragment>
  );
};
const NotFoundError = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h1 className="headline--large">{t('ErrorPage.notFound.headline')}</h1>
      <p className="typography--medium">
        <Trans i18nKey="ErrorPage.notFound.first">
          Gå tillbaka till <Link to="/">startsidan</Link> eller kontakta{' '}
          <a href="mailto:sjukochfrisk@falcksverige.se">
            sjukochfrisk@falcksverige.se
          </a>{' '}
          för mer information.
        </Trans>
      </p>
    </Fragment>
  );
};

const UnavailableError = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h1 className="headline--large">{t('ErrorPage.unavailable.headline')}</h1>
      <p className="typography--medium">
        <Trans i18nKey="ErrorPage.unavailable.first">
          För närvarande är tjänsten inte tillgänglig, prova igen om en liten
          stund.
          <br />
          Regelbundna underhåll sker varje morgon mellan kl.{' '}
          <span className="typography--font-weight-medium">06.00–06.20</span>.
        </Trans>
      </p>

      <p className="typography--medium">
        <Trans i18nKey="ErrorPage.unavailable.second">
          Kontakta{' '}
          <a href="mailto:sjukochfrisk@falcksverige.se">
            sjukochfrisk@falcksverige.se
          </a>{' '}
          för mer information.
        </Trans>
      </p>
    </Fragment>
  );
};

const ErrorPage = ({ statusCode: statusCodeProp, match = null }) => {
  let statusCodeParam;

  if (match) {
    statusCodeParam = match.params.statusCode;
  }

  const statusCode = statusCodeProp || statusCodeParam;
  const statusCodeInt = parseInt(statusCode, 10);

  return (
    <div className="error-page grid__section">
      <ErrorMessage statusCode={statusCode}>
        {statusCodeInt >= 500 && statusCodeInt !== 503 && <ServerError />}
        {statusCodeInt === 503 && <UnavailableError />}
        {statusCodeInt >= 400 && statusCodeInt <= 403 && <ForbiddenError />}
        {statusCodeInt === 404 && <NotFoundError />}
        {isNaN(statusCodeInt) && <ServerError />}
      </ErrorMessage>
    </div>
  );
};

export default ErrorPage;

import config from '../config';
import { getKey } from './index';

const { tokenKey } = config.localStorage;

export const post = (url, body) => {
  const authTokenFromLocalStorage = getKey(tokenKey);

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authTokenFromLocalStorage}`,
    },
    body: JSON.stringify(body),
  });
};
